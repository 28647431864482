<template>
  <div class="col-md-5 col-12">
    <div class="content">
      <div v-if="error">
        <p class="error-message" v-if="error">{{ errorMessage }}</p>
      </div>
      <b-form-group label="Car Number *" class="mb-0"></b-form-group>
      <div class="d-flex mb-2">
        <div class="bg-blue mr-1">
          <div class="about-circle"></div>
          <div class="text-center">
            <select
              class="about-select"
              v-model="countryId"
              v-on:change="allCities(countryId)"
              @change="flush"
            >
              <option
                v-bind:value="country.id"
                v-for="country in countries"
                v-bind:key="country.id"
                >{{ country.code }}
              </option>
            </select>
          </div>
        </div>
        <div style="width: 120px" class="mr-1 city-code-responsive">
          <!-- <v-select class="code-select city-code-select" v-model="cityId">
            <option value="0">City Code</option>
            <option v-bind:value="city.id" v-for="city in cities" v-bind:key="city.id">{{city.code}}</option>
          </v-select> -->
          <vSelect
            v-model="cityId"
            :options="options"
            placeholder="City Code"
            v-on:click="cities"
          />
        </div>
        <div class="number-responsive">
          <input
            type="text"
            class="code-select text-center"
            placeholder="Number"
            v-model="plate_number"
            style="border-radius:0 5px 5px 0"
            minlength="5"
            maxlength="7"
            required
          />
        </div>
      </div>
      <div>
        <b-form-group label="Password *">
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                ><img
                  :src="'./images/password-icon.png'"
                  class="w-100 password-img"
                  alt=""
                />
              </b-input-group-text>
            </template>
            <input
              v-if="showPassword"
              type="text"
              v-model="ppassword"
              placeholder="********"
              minLength="8"
              class="text-left edit-profile-input calcWidth"
              style="font-size:22px;"
              required
            />
            <input
              type="password"
              v-else
              v-model="ppassword"
              placeholder="********"
              minLength="8"
              class="text-left edit-profile-input calcWidth"
              style="font-size:22px;"
              required
            />
            <a
              class="btn d-flex align-items-center outline"
              href="javascript:void(0)"
              @click="toggleShow"
              ><span class="icon is-small is-right">
                <i
                  style="font-size: 20px;"
                  class="fa"
                  :class="{
                    'fa-eye-slash': showPassword,
                    'fa-eye': !showPassword,
                  }"
                ></i>
              </span>
            </a>
          </b-input-group>
        </b-form-group>
      </div>

      <div class="mt-2 text-right">
        <router-link
          :to="{ name: 'Verification', params: { type: 'forgetPassword' } }"
          >Forgot Password?</router-link
        >
      </div>
      <button class="mt-3 signIn" v-on:click.prevent="signIn">
        Sign In <i style="color: white" v-bind:class="changeClass()"></i>
      </button>
      <hr class="mt-3 mb-3" />
      <div class="text-center">
        <div style="white-space:nowrap;">
          New Here Create Account
          <router-link
            :to="{ name: 'Verification', params: { type: 'register' } }"
            style="color:#C8A860 !important;font-weight:bold;"
            >Sign up</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { allCities } from "../util/function";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  data() {
    return {
      countryId: "",
      cityId: "",
      ppassword: "",
      plate_number: "",
      userData: "",
      isSubmitted: false,
      errorMessage: "",
      error: false,
      postButtonLoader: false,
      options: [],
      showPassword: false,
    };
  },
  components: {
    vSelect,
  },
  computed: {
    cities() {
      if (this.countryId != "0" && this.countryId != "") {
        return this.$store.getters.countryCities.map((x) => {
          let temp = {
            value: x.id,
            label: x.code,
          };
          return this.options.push(temp);
        });
      } else {
        return false;
      }
    },
    countries() {
      return this.$store.state.allCountries;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  beforeMount() {
    this.$store.dispatch("fetchSettings");
  },
  mounted() {},
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    flush() {
      this.options.splice(0);
      this.cityId = undefined;
    },
    changeClass() {
      if (this.postButtonLoader == true) {
        return "fa fa-spinner fa-spin";
      }
    },
    allCities,
    async signIn() {
      this.postButtonLoader = true;
      this.validate();
      if (this.error === false) {
        var result = await axios
          .post(this.$paths + "login", {
            plate_number: this.plate_number,
            password: this.ppassword,
            country_codes_id: this.countryId,
            city_codes_id: this.cityId,
          })
          .then((response) => (this.userData = response.data));
        if (result.code == 200) {
          this.$store.commit("updateLoginUser", result.data);
          this.$store.commit("updatetoken", result.data.token);
          this.$store.commit("updateAuthentication", true);
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("token", JSON.stringify(result.data.token));
          localStorage.setItem(
            "loginUserData",
            JSON.stringify(this.userData.data)
          );
          Object.assign(this.$data, this.$options.data());
          this.$router.push("home");
        } else if (result.code != 200) {
          this.postButtonLoader = false;
          (this.error = true),
            (this.isSubmitted = true),
            (this.errorMessage = result.message);
        }
      }
    },
    validate() {
      this.errorMessage = "";
      if (this.countryId === "") {
        this.errorMessage = "Please select country code";
        this.error = true;
        return false;
      } else {
        this.error = false;
      }
      if (this.cityId === "0") {
        console.log("f");
        this.errorMessage = "Please select city code";
        this.error = true;
        return false;
      } else {
        this.error = false;
      }
      if (this.plate_number === "") {
        this.errorMessage = "Please enter at least 5 characters in car number";

        this.error = true;
        return false;
      } else {
        this.error = false;
      }
      if (this.ppassword === "") {
        this.errorMessage = "Please enter more than 7 characters password";
        this.error = true;
        return false;
      } else {
        this.error = false;
      }
    },
  },
  name: "Login",
};
</script>

<style scoped>
.calcWidth {
  width: calc(100% - 100px);
  padding-left: 10px;
}
.outline:focus {
  box-shadow: none;
}
</style>
