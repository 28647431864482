<template>
  <div id="PostDetails">
    <div class="right-content-section">
      <img
        :src="'../images/loader.gif'"
        class="mr-2"
        v-if="isLoading == true"
        style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
      />
      <b-modal ref="comment-modal" hide-footer centered title="Verification">
        <div class="d-block text-center">
          <h5>{{ $t("LBL_DELETE_COMMENT") }}</h5>
        </div>
        <div class="d-flex align-items-center justify-content-center pt-3">
          <b-button
            class="mr-1"
            variant="outline-danger"
            @click="deletefunction()"
            >{{ $t("LBL_YES") }}</b-button
          >
          <b-button variant="outline-warning" @click="hideModal">{{
            $t("LBL_NO")
          }}</b-button>
        </div>
      </b-modal>
      <!-- People like post modal -->
      <b-modal
        id="invite-friends"
        centered
        :title="$t('LBL_PEOPLE_WHO_REACTED')"
      >
        <div
          class="search-user-detail d-flex justify-content-between"
          v-for="inviteUser in likedUsers"
          v-bind:key="inviteUser.id"
        >
          <div class="d-flex">
            <div class="user-search-profile">
              <span v-if="inviteUser.profile_image != ''">
                <img :src="inviteUser.profile_image" alt="" />
              </span>
              <span v-else>
                <img src="/images/dummy_user_img.png" alt="" />
              </span>
            </div>
            <div class="w-170">
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ inviteUser.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>{{ inviteUser.plate_number }}</div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ inviteUser.first_name }} {{ inviteUser.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <router-link to="/profile"
        ><img :src="'./images/cancel.svg'" alt="" style="width: 30px;" />
      </router-link>
      <div class="post-detail">
        <div class="listing">
          <div class="user-detail d-flex justify-content-between">
            <div class="d-flex">
              <div
                class="listing-profile"
                v-if="postData.user ? postData.user.profile_image != '' : ''"
              >
                <img :src="postData.user.profile_image" alt="User Image" />
              </div>
              <div class="listing-profile" v-else>
                <img src="/images/dummy_user_img.png" alt="User Image" />
              </div>
              <div class="ml-2">
                <div class="d-flex align-items-center number-plate">
                  <div class="d-flex outer-div">
                    <div class="circle"></div>
                    <div>
                      {{ postData.user ? postData.user.country_code : "" }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center w-65">
                    <div>
                      {{ postData.user ? postData.user.city_code : ""
                      }}{{ postData.user ? postData.user.plate_number : "" }}
                    </div>
                  </div>
                </div>
                <div class="user-name pt-1">
                  {{ postData.user ? postData.user.first_name : "" }}
                  {{ postData.user ? postData.user.last_name : "" }}
                </div>
                <div class="post-date">
                  {{
                    moment
                      .utc(postData.created_at)
                      .local()
                      .format("d/MM/YYYY h:mm a")
                  }}
                </div>
              </div>
            </div>
            <!-- <div class="d-flex align-items-start">
                            <button class="share-btn">Share</button>
                        </div> -->
          </div>

          <div class="description" v-if="postData.post_type != 'text'">
            <vue-show-more-text
              :text="postData.text"
              :lines="4"
              additional-container-css="padding: 0;"
            />
          </div>

          <!-- //Image  -->
          <div v-if="postData.post_type == 'image'">
            <img
              :src="postData.medium"
              v-bind:id="'myImg' + postData.id"
              alt="Post Image"
              class="w-100"
            />
          </div>

          <!-- //For Video -->
          <div v-if="postData.post_type == 'video'">
            <video
              v-bind:id="'myVideo' + postData.id"
              controls
              width="100%"
              height="264"
              preload="auto"
              :src="postData.medium"
              type="video/mp4"
            ></video>
          </div>

          <!-- //End For Video -->

          <!-- //For Status -->
          <div
            v-bind:id="'myStatus' + postData.id"
            v-if="postData.post_type == 'text'"
          >
            <div
              class="status minHeight"
              :style="getColor(postData.background_color)"
            >
              <vue-show-more-text
                :text="postData.text"
                :lines="4"
                additional-container-css="padding: 0;"
                additional-content-css="font-size:16px;"
                additional-content-expanded-css="font-size:16px;"
                additional-anchor-css="font-size: 16px;"
              />
            </div>
          </div>

          <!-- //For Location -->
          <div
            v-bind:id="'myLocation' + postData.id"
            v-if="postData.post_type == 'location'"
          >
            <div class="">
              <googlemaps-map
                :center="{
                  lat: parseFloat(postData.lat),
                  lng: parseFloat(postData.lng),
                }"
                :zoom="13"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                }"
              >
                <googlemaps-marker
                  :position="{
                    lat: parseFloat(postData.lat),
                    lng: parseFloat(postData.lng),
                  }"
                />
              </googlemaps-map>
              <div class="address">{{ postData.comp_address }}</div>
            </div>
          </div>
          <!-- <div @click="showPopup" v-if="post.post_type=='image'">
                        <img
                        :src="'./images/map.png'"
                        id="myLocation"
                        alt=""
                        class="w-100"
                        />
                    </div> -->
          <div class="likes d-flex align-items-center justify-content-between">
            <div class="comments">
              {{ postData.comments ? postData.comments.length : "" }}
              {{ $t("comments") }}
            </div>
            <div
              class="comments pl-2"
              v-b-modal.invite-friends
              @click="postUserList(postData)"
            >
              {{ postLikedLength.length ? postLikedLength.length : "" }} Likes
            </div>
          </div>
          <hr />
          <div class="d-flex justify-content-between px-1">
            <div class="user-name down">
              <img src="/images/comment.png" alt="" class="mr-2" />{{
                $t("LBL_COMMENT_TXT")
              }}
            </div>
            <div v-if="userID.id != postData.user.id">
              <b-button
                @click="getReportReaasons"
                class="user-name down report-btn"
                ><i class="fa fa-flag pr-2"></i>Report</b-button
              >
            </div>
            <div
              class="user-name down"
              :class="{ like: !showClicked(postData) }"
              v-bind:style="selectedCursor"
              @click="likeToggle(postData)"
            >
              <img src="/images/like.png" alt="" class="mr-2" />{{ $t("like") }}
            </div>
          </div>
          <hr class="mt-1" />
          <div
            v-if="checkEmptyArray(postData.comments ? postData.comments : '')"
          >
            <div
              class="d-flex comments align-items-start"
              v-for="(comment, index) in reverseItems(postData.comments)"
              :key="comment.id"
            >
              <input
                type="text"
                v-bind:id="'modalcommentButton' + index"
                style="display:none"
              />
              <div>
                <span
                  v-if="comment.user ? comment.user.profile_image != '' : ''"
                >
                  <img
                    :src="comment.user ? comment.user.profile_image : ''"
                    class="mr-2 img-circle"
                  />
                </span>
                <span v-else>
                  <img
                    src="/images/dummy_user_img.png"
                    class="mr-2 img-circle"
                    alt=""
                  />
                </span>
              </div>
              <div class="other-comments">
                <div class="user-name .comment-user">
                  {{ comment.user ? comment.user.first_name : "" }}
                  {{ comment.user ? comment.user.last_name : "" }}
                </div>
                <div
                  aria-disabled="disabled"
                  v-bind:id="'textdiv' + index"
                  class="textdiv"
                >
                  {{ comment.comment }}
                </div>
                <div>
                  <textarea
                    name=""
                    class="input-for-comment-append"
                    v-on:keyup.enter="
                      updateComment(reverseItems(postData.comments), index)
                    "
                    v-bind:id="'input' + index"
                    style="display:none; width:250px;"
                  ></textarea>
                </div>
                <div v-bind:id="'buttondiv' + index" class="buttondiv">
                  <span v-if="userID.id == comment.user.id">
                    <i
                      class="fa fa-pencil comment-edit"
                      @click="appendUpdateComment(comment, index)"
                    ></i>
                  </span>
                  <span
                    v-if="
                      userID.id == comment.user.id ||
                        userID.id == postData.user.id
                    "
                  >
                    <i
                      class="fa fa-trash comment-delete"
                      @click="deleteVerificationComment(comment)"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex comments align-items-start">
            <div>
              <span
                v-if="postData.user ? postData.user.profile_image != '' : ''"
              >
                <img :src="postData.user.profile_image" class="mr-2" />
              </span>
              <span v-else>
                <img src="/images/dummy_user_img.png" alt="User Image" />
              </span>
            </div>
            <input
              class="status-search w-100"
              type="text"
              v-bind:id="'listcomment' + postData.id"
              @keyup="showCommentButton(postData)"
              :placeholder="$t('LBL_WRITE_COMMENTS_TXT')"
            />
            <i
              class="fa fa-paper-plane comment-send"
              v-bind:id="'listcommentButton' + postData.id"
              @click="postComment(postData)"
              style="display:none"
            ></i>
          </div>
        </div>
        <b-modal id="report-modal" centered title="Report">
          <div class="problem pb-2">Please Select Reason for Reporting?</div>
          <div
            class="problem-content"
            v-for="reason in reportReasons"
            v-bind:key="reason.id"
            v-on:click="submitReason(reason.id)"
          >
            {{ reason.reason }}
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import vueShowMoreText from "vue-show-more-text";
export default {
  data() {
    return {
      isLoading: false,
      postId: "",
      delcomment: null,
      likedUsers: [],
      postData: {},
      reportReasons: [],
      selectedCursor: "pointer-events:auto",
      postLikedLength: [],
      userID: "",
    };
  },

  mounted() {
    this.postId = this.$route.params.id;
    this.getPostData();
  },
  components: {
    vueShowMoreText,
  },
  methods: {
    getDumyImage() {
      return "/images/user-dummy-pic.png";
    },
    errorAlert(message) {
      Swal.fire({
        title: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    successAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    getColor(color) {
      if (color) {
        return "background-color:" + color;
      }

      return "background: #C8A860";
    },

    async getPostData() {
      this.isLoading = true;
      const PostData = await axios.get(
        this.$paths + "posts/shows/" + this.postId,
        {}
      );
      if (PostData.data.code == 200) {
        this.userID = PostData.data.data.user;
        this.postData = PostData.data.data;
        this.isLoading = false;
        for (let element of this.postData.liked_users) {
          this.postLikedLength.push(element.id);
        }
      } else {
        this.isLoading = false;
        this.errorAlert(PostData.data.message);
      }
    },

    checkEmptyArray(comments) {
      if (comments.length > 0) {
        return true;
      }
      return false;
    },

    reverseItems(comments) {
      return comments
        .slice(comments.length - comments.length, comments.length)
        .reverse();
    },

    async getReportReaasons() {
      try {
        this.isLoading = true;
        var result = await axios.get(this.$paths + "getReasons");
        if (result.data.code == 200) {
          this.isLoading = false;
          this.reportReasons = result.data.data;
          this.$bvModal.show("report-modal");
        } else {
          this.isLoading = false;
          this.reportReasons = [];
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },

    async submitReason(id) {
      const data = {
        post_id: this.postId,
        reason_id: id,
      };

      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      await axios
        .post(this.$paths + "postReport", data, {
          headers,
        })
        .then((response) => {
          this.$bvModal.hide("report-modal");
          this.successAlert(response.data.message);
        });
    },
    async postUserList(post) {
      const data = {
        post_id: post.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "postLikedUsers", data, {
          headers,
        })
        .then((response) => {
          this.likedUsers = response.data.data;
        });
    },

    //post detail comments updated
    hideUnhideclass(className, displayState) {
      var elements = document.getElementsByClassName(className);

      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = displayState;
      }
    },
    appendUpdateComment(object, index) {
      this.hideUnhideclass("input-for-comment-append", "none");
      this.hideUnhideclass("textdiv", "flex");
      this.hideUnhideclass("buttondiv", "flex");
      var showInput = document.getElementById("input" + index);
      showInput.style.display = "flex";
      var buttondiv = document.getElementById("buttondiv" + index);
      buttondiv.style.display = "none";
      var div = document.getElementById("textdiv" + index);
      div.style.display = "none";
      showInput.value = object.comment;
    },

    async updateComment(object, index) {
      var showInput = document.getElementById("input" + index);
      var div = document.getElementById("textdiv" + index);
      var buttondiv = document.getElementById("buttondiv" + index);
      const data = {
        comment: showInput.value,
        comment_id: object[index].id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      var result = await axios
        .post(this.$paths + "updateComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        div.style.display = "flex";
        buttondiv.style.display = "flex";
        showInput.style.display = "none";
        // div.replaceWith(result.data.comment)
        object[index].comment = result.data.comment;
      }
    },
    hideModal() {
      this.$refs["comment-modal"].hide();
    },
    deletefunction() {
      this.deleteComment(this.delcomment);
    },
    deleteVerificationComment(object) {
      this.delcomment = object;
      this.$refs["comment-modal"].show();
    },
    //delete comment in post detail
    async deleteComment(object) {
      const data = {
        comment_id: object.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios
        .post(this.$paths + "deleteComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        this.$refs["comment-modal"].hide();
        let list = this.postData.comments;
        for (let j = 0; j < this.postData.comments.length; j++) {
          if (object.id == this.postData.comments[j].id) {
            list.splice(j, 1);
            this.postData.comments = list;
            break;
          }
        }
      }
    },

    async likeToggle(postData) {
      this.isLoading = true;
      this.selectedCursor = "pointer-events:none";

      if (postData.is_liked) {
        this.likedStatus = "Dislike";
      } else {
        this.likedStatus = "Like";
      }
      const data = {
        post_id: postData.id,
        liked_status: this.likedStatus,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios.post(this.$paths + "postLikeDislike", data, {
        headers,
      });
      if (result.data.message == "Post liked successfully") {
        this.selectedCursor = "pointer-events:auto";
        this.postLikedLength.push(this.$store.state.loginUserData.id);
        this.isLoading = false;
        return (this.postData.is_liked = true);
      } else if (result.data.message == "Post Unliked") {
        this.selectedCursor = "pointer-events:auto";
        const index = this.postLikedLength.indexOf(
          this.$store.state.loginUserData.id
        );
        this.postLikedLength.splice(index, 1);
        this.isLoading = false;
        return (this.postData.is_liked = false);
      }
      this.selectedCursor = "pointer-events:auto";
    },

    showClicked(post) {
      if (post.is_liked) {
        return false;
      } else {
        return true;
      }
    },

    showCommentButton(post) {
      var buttonId;
      var commentValue;
      commentValue = document.getElementById("listcomment" + post.id).value;
      buttonId = "listcommentButton" + post.id;
      var chars = commentValue.length;
      var commentButton = document.getElementById(buttonId);
      if (chars > 0) {
        commentButton.style.display = "flex";
      } else {
        commentButton.style.display = "none";
      }
    },

    async postComment(post) {
      this.isLoading = true;
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      var commentValue;
      var buttonId;
      commentValue = document.getElementById("listcomment" + post.id).value;
      buttonId = "listcommentButton" + post.id;
      var commentButton = document.getElementById(buttonId);
      console.log("commentButton => ", commentButton);
      const data = {
        post_id: post.id,
        user_id: userAParseValue.id,
        comment: commentValue,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios.post(this.$paths + "postComment", data, {
        headers,
      });
      if (result.data.code == 200) {
        this.postData.comments.push(result.data.data);
        document.getElementById("listcomment" + post.id).value = "";
        this.isLoading = false;
        this.successAlert("Success");
      }
    },
  },
};
</script>
<style scoped>
.address {
  background-color: #f0f2f5;
  border: 1px solid #ced0d4;
  font-size: 17px;
  font-weight: 600;
  color: #000;
  padding: 20px 10px;
}
</style>
