<template>
  <div class="col-md-7 col-12">
    <img :src="'images/Bitmap.png'" alt="" class="index-logo">
    <img :src="'images/car.png'" alt="" class="secondary-logo">
    <h2 class="subtitle">
      Meet My Car helps you to connect and share with the people in your life.
    </h2>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
