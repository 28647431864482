<template>
  <div id="NewsDetail">
    <div>
      <img
        v-if="loading"
        :src="'./images/loader.gif'"
        class="mr-2"
        style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
      />
    </div>
    <div class="right-content-section pb-5">
      <router-link to="/news"
        ><img :src="'./images/cancel.svg'" alt="" style="width: 30px;" />
      </router-link>
      <div class="events-details">
        <div class="detail-news pb-3">
          {{ $t("LBL_DETAILS_TXT") }}
        </div>

        <div v-if="news && news.images.length > 0">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            indicators
            :interval="3000"
            background="#ababab"
            img-width="1024"
            img-height="480"
          >
            <!-- Slides with image only -->
            <b-carousel-slide
              v-for="image in news.images"
              :key="image.id"
              :img-src="image.name"
            >
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div v-else-if="news && news.coverImage">
          <img :src="news.coverImage" alt="" class="w-100" />
        </div>
        <div class="event-timing-detail">
          <div>{{ news.title }}</div>
          <div class="time">{{ news.date }}</div>
          <div>{{ news.longDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["news"],
  name: "news-detail",
  data() {
    return {
      slide: 0,
      sliding: null,
      loading: true,
    };
  },

  mounted() {
    if (this.news) {
      this.loading = false;
    }
  },
};
</script>
