<template>
  <div id="Home">
     <Navbar />
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from '../components/Layouts/Navbar'

export default {
  name: 'Home',
  components: {
    Navbar,
  }
}
</script>