<template>
    <div>
        <div>
        <img v-if="loading" :src="'./images/loader.gif'" class="mr-2"
        style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);" />
        </div>
        <div class="right-content-section">
            <div class="bgWhite">
                <div class="privacy">
                <h4>{{title}}</h4>
                <p class="privacy-text" v-html="privacy">
                    {{privacy}}
                </p>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default{
    data(){
    return {
     privacy: '',
     title: '',
     loading: true,
     }; 
    },
    async mounted() {
    this.getprivacy();
    },
    methods: {
        async getprivacy() {
         
        const headers = {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        };
        try {
          axios
            .get(this.$paths + "privacyPolicy", {
              headers,
            }).then((response) => {
              if (response.data.code == 200) {
                this.title = response.data.data.title;
                this.privacy = response.data.data.content;
                this.loading = false;
              } else if (response.data.code != 200) {
                this.$router.push({
                  name: 'login'
                });
              }
            })
        } catch (err) {
          if (!err.response) {
            alert("Please Check You Internet Connection");
          }
        }
      }
    },
};
</script>

<style>
.bgWhite {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
}
</style>