<template>
  <div id="news">
    <div class="right-content-section pb-5">
      <div class="title-background" v-if="!loading">
        <div class="align-items-center justify-content-between mb-2">
          <h2 class="title">{{ $t("LBL_NEWS_TXT") }}</h2>
        </div>
      </div>
      <div>
        <img
          v-if="loading"
          :src="'./images/loader.gif'"
          class="mr-2"
          style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
        />
      </div>
      <div v-for="news in allNews" :key="news.id">
        <router-link :to="{ name: 'NewsDetail', params: { news: news } }">
          <div class="listing">
            <div>
              <span v-if="news.coverImage == ''">
                <img :src="'./images/event.png'" alt="" class="w-100" />
              </span>
              <span v-else>
                <img :src="news.coverImage" alt="" class="w-100 newsImg" />
              </span>
            </div>
            <div class="event-timing-detail">
              <div>{{ news.title }}</div>
              <div class="time">{{ news.date }}</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "news",
  data() {
    return {
      allNews: [],
      search: "",
      loading: true,
    };
  },

  mounted() {
    this.getAllNews();
  },

  methods: {
    async getAllNews() {
      const allNewsData = await axios.get(this.$paths + "getnewslist", {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (allNewsData.data.code == 200) {
        this.allNews = allNewsData.data.data;
        this.loading = false;
      }
    },

    async characterCount() {
      console.log("Search");
    },
  },
};
</script>
