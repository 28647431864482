<template>
  <div class="main-content mb-4">
    <div class="right-content-section">
      <div class="content" v-if="!loading">
        <div class="d-flex justify-content-start">
          <span v-if="this.userProfile != ''">
            <img :src="this.userProfile" class="search-img mr-1" alt="" />
          </span>
          <span v-else>
            <img
              :src="'./images/dummy_user_img.png'"
              class="search-img mr-1"
              alt=""
            />
          </span>
          <input
            class="w-100 home-search"
            type="search"
            :placeholder="$t('LBL_WHATS_IN_YOUR_MIND')"
            aria-label="search"
            disabled
          />
        </div>
        <div>
          <notifications position="top right" />
          <b-modal ref="video-error" title="Alert">
            <p class="my-4">{{ $t("LBL_VIDEO_CANT_UPLOAD") }}</p>
          </b-modal>
        </div>
        <hr />
        <div class="bottom-content">
          <div class="d-flex justify-content-center">
            <b-button
              v-b-modal.post-modal
              class="upload-btn"
              v-on:click="addpostButton"
              @click="addPost()"
              >{{ $t("LBL_CREATE_TXT") }}</b-button
            >
            <b-modal id="post-modal" centered :title="this.postTitle">
              <div class="d-flex">
                <div class="create-post-img">
                  <span v-if="this.userProfile != ''">
                    <img
                      :src="this.userProfile"
                      class="search-img mr-1"
                      alt=""
                    />
                  </span>
                  <span v-else>
                    <img
                      src="/images/dummy_user_img.png"
                      class="search-img mr-1"
                      alt=""
                    />
                  </span>
                </div>
                <div class="ml-2">
                  <div class="d-flex align-items-center number-plate">
                    <div class="d-flex outer-div">
                      <div class="circle"></div>
                      <div>{{ this.userAParseValue.country_code }}</div>
                    </div>
                    <div class="d-flex justify-content-center w-65">
                      <div>
                        {{ this.userAParseValue.city_code
                        }}{{ this.userAParseValue.plate_number }}
                      </div>
                    </div>
                  </div>
                  <div class="user-name pt-1">
                    {{ this.userAParseValue.first_name }}
                    {{ this.userAParseValue.last_name }}
                  </div>
                </div>
              </div>
              <div class="create-post mt-2 mb-2">
                <Mentionable
                  :keys="['@', '#']"
                  :items="items"
                  offset="6"
                  insert-space
                  @open="onOpen"
                  placement="bottom"
                >
                  <textarea
                    id="textBox"
                    :placeholder="$t('LBL_WHATS_IN_YOUR_MIND')"
                    v-model="text"
                    :style="getColor(background_color)"
                    row="10"
                    column="10"
                  >
                  </textarea>
                  <template #item-@="{ item }">
                    <div class="user">
                      {{ item.value }}
                      <span class="dim"> ({{ item.firstName }}) </span>
                    </div>
                  </template>
                  <template #item-#="{ item }">
                    <div class="issue">
                      <span class="number">#{{ item.label }}</span>
                    </div>
                  </template>
                </Mentionable>
              </div>

              <div id="imagePreview" class="mb-3">
                <div v-if="isEditPost == false">
                  <img v-if="imageUrl" :src="imageUrl" />
                  <button
                    v-if="imageRemoveBtn"
                    class="cross-btn"
                    @click="removeImg"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <div v-else>
                  <img v-if="imageUrl" :src="imageUrl" />
                  <button
                    v-if="imageRemoveBtn"
                    class="cross-btn"
                    @click="removeImg"
                    v-on:click="postimageDel"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
              <div id="videoPreview" class="mb-3">
                <div v-if="isEditPost == false">
                  <video
                    v-if="videoUrl"
                    :src="videoUrl"
                    id="videoUrl"
                    type="mp4"
                    loop
                    controls
                  ></video>
                  <button
                    v-if="videoRemoveBtn"
                    class="cross-btn"
                    @click="removeVideo"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <div v-else>
                  <video
                    v-if="videoUrl"
                    :src="videoUrl"
                    type="mp4"
                    loop
                    controls
                  ></video>
                  <button
                    v-if="videoRemoveBtn"
                    class="cross-btn"
                    @click="removeVideo"
                    v-on:click="postimageDel"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
              <!-- location google map -->
              <div v-if="isEditPost == false">
                <div id="locationPreview" class="mb-3" v-if="locationdiv">
                  <p id="location">{{ locationdiv }}</p>
                </div>
                <div id="googlemap preview" v-if="locationdiv">
                  <googlemaps-map
                    :center="{
                      lat: parseFloat(center.lat),
                      lng: parseFloat(center.lng),
                    }"
                    :zoom="13"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUi: false,
                    }"
                  >
                    <googlemaps-marker :position="center" />
                  </googlemaps-map>
                </div>
              </div>
              <div v-else>
                <div id="locationPreview" class="mb-3" v-if="locationdiv">
                  <p id="location">{{ locationdiv }}</p>
                </div>
                <div id="googlemap preview" v-if="locationdiv">
                  <googlemaps-map
                    :center="{
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    }"
                    :zoom="13"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUi: false,
                    }"
                  >
                    <googlemaps-marker
                      :position="{
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                      }"
                    />
                  </googlemaps-map>
                </div>
              </div>

              <div
                class="d-flex align-items-center justify-content-end mb-3"
                v-if="this.image == null"
              >
                <ColorPicker
                  :width="100"
                  :height="100"
                  v-model="color"
                  :disabled="false"
                  startColor="#c8a860"
                ></ColorPicker>
              </div>
              <div class="d-flex add-post">
                <div>
                  <i
                    class="fa fa-map-marker mr-1"
                    @click="getLocation()"
                    @change="locationchange"
                  ></i>
                  <label class="mb-0 mr-1"
                    ><i class="fa fa-picture-o"></i>
                    <input
                      type="file"
                      @change="onFileChange"
                      class="d-none"
                      accept="image/*"
                    />
                  </label>
                  <label class="mb-0 mr-1"
                    ><i class="fa fa-video-camera"></i>
                    <input
                      type="file"
                      id="vid"
                      @change="onVideoChange"
                      v-on:change="checkFileDuration()"
                      class="d-none"
                      accept="video/*"
                    />
                  </label>
                </div>
                <div class="d-flex">
                  <img
                    id="changeColorBG"
                    class="mr-1"
                    :src="'./images/yellow.png'"
                    alt=""
                    v-on:click="changeColor('#FDAB40')"
                  />
                  <img
                    id="changeColorBG"
                    class="mr-1"
                    :src="'./images/green.png'"
                    alt=""
                    v-on:click="changeColor('#88A80D')"
                  />
                  <img
                    id="changeColorBG"
                    class="mr-1"
                    :src="'./images/red.png'"
                    alt=""
                    v-on:click="changeColor('#F73265')"
                  />
                  <img
                    id="changeColorBG"
                    class="mr-1"
                    :src="'./images/grey.png'"
                    alt=""
                    v-on:click="changeColor('#6D6E72')"
                  />
                  <img
                    id="changeColorBG"
                    class="mr-1"
                    :src="'./images/silver.png'"
                    alt=""
                    v-on:click="changeColor('#D8D8D8')"
                  />
                </div>
              </div>
              <button
                @click="createPost()"
                type="button"
                class="post-btn mt-3"
                v-if="isEditPost == false"
              >
                Post <i style="color: white" v-bind:class="changeClass()"></i>
              </button>
              <button
                type="button"
                @click="editpost()"
                class="post-btn mt-3"
                v-else
              >
                Update Post<i
                  style="color: white"
                  v-bind:class="changeClass()"
                ></i>
              </button>
            </b-modal>
          </div>
        </div>
      </div>
      <!-- delete comment verification modal -->
      <div>
        <b-modal ref="comment-modal" hide-footer centered title="Verification">
          <div class="d-block text-center">
            <h5>{{ $t("LBL_DELETE_COMMENT") }}</h5>
          </div>
          <div class="d-flex align-items-center justify-content-center pt-3">
            <b-button
              class="mr-1"
              variant="outline-danger"
              @click="deletefunction()"
              >{{ $t("LBL_YES") }}</b-button
            >
            <b-button variant="outline-warning" @click="hideModal">{{
              $t("LBL_NO")
            }}</b-button>
          </div>
        </b-modal>
      </div>
      <div>
        <img
          v-if="loading"
          :src="'./images/loader.gif'"
          class="mr-2"
          style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
        />
      </div>
      <!-- <div class="loader" v-if="loading"></div> -->
      <!-- People like post modal -->
      <b-modal
        id="invite-friends"
        centered
        :title="$t('LBL_PEOPLE_WHO_REACTED')"
      >
        <div
          class="search-user-detail d-flex justify-content-between"
          v-for="inviteUser in likedUsers"
          v-bind:key="inviteUser.id"
        >
          <div class="d-flex">
            <div class="user-search-profile">
              <span v-if="inviteUser.profile_image != ''">
                <img :src="inviteUser.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
            <div class="w-170">
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ inviteUser.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>{{ inviteUser.plate_number }}</div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ inviteUser.first_name }} {{ inviteUser.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- Start Post Listing -->
      <div v-for="(post, index) in posts" :key="post.id" class="listing">
        <div class="user-detail d-flex justify-content-between">
          <div class="d-flex">
            <div class="listing-profile">
              <span v-if="post.user.profile_image != ''">
                <img :src="post.user.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
            <div class="ml-2">
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ post.user.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>
                    {{ post.user.city_code }}{{ post.user.plate_number }}
                  </div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ post.user.first_name }} {{ post.user.last_name }}
              </div>
              <div class="post-date">
                {{
                  moment
                    .utc(post.created_at)
                    .local()
                    .format("d-MM-YYYY / h:mm A")
                }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <!-- <button class="share-btn" @click="sharepost(post)" >Share</button> -->
            <div
              class="event-listing-dots"
              v-if="userAParseValue.id == post.user.id"
            >
              <!-- <img :src="'./images/dots.png'" alt="" /> -->
              <b-dropdown text="..." right no-caret class="edit-dropdown">
                <b-dropdown-item @click="EditPost(post)">{{
                  $t("LBL_EDIT")
                }}</b-dropdown-item>
                <b-dropdown-item @click="DeletePost(post)">{{
                  $t("LBL_DELETE")
                }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="description" v-if="post.post_type != 'text'">
          <vue-show-more-text
            :text="post.text"
            :lines="4"
            additional-container-css="padding: 0;"
          />
        </div>

        <!-- //Image  -->
        <div
          @click="showModal(post, 'image', index)"
          v-if="post.post_type == 'image'"
        >
          <img
            :src="post.medium"
            v-bind:id="'myImg' + post.id"
            alt=""
            class="w-100"
          />
        </div>

        <!-- //For Video -->
        <div
          @click="showModal(post, 'myVideo', index)"
          v-if="post.post_type == 'video'"
        >
          <video
            v-bind:id="'myVideo' + post.id"
            controls
            width="100%"
            height="264"
            preload="auto"
            :src="post.medium"
            type="video/mp4"
          ></video>
        </div>

        <!-- //End For Video -->

        <!-- //For Status -->
        <div
          @click="showModal(post, 'myStatus', index)"
          v-bind:id="'myStatus' + post.id"
          v-if="post.post_type == 'text'"
        >
          <div
            class="status minHeight"
            :style="getColor(post.background_color)"
          >
            <vue-show-more-text
              :text="post.text"
              :lines="4"
              less-text="Show more"
              additional-container-css="padding: 0;"
              additional-content-expanded-css="font-size:16px;"
              additional-anchor-css="font-size: 16px;"
            />
          </div>
        </div>

        <!-- //For Location -->
        <div
          @click="showModal(post, 'myLocation', index)"
          v-bind:id="'myLocation' + post.id"
          v-if="post.post_type == 'location'"
        >
          <!-- <div class="status" :style=" 'background-color:'+post.background_color"> -->
          <div class="">
            <googlemaps-map
              :center="{ lat: parseFloat(post.lat), lng: parseFloat(post.lng) }"
              :zoom="13"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
              }"
            >
              <googlemaps-marker
                :position="{
                  lat: parseFloat(post.lat),
                  lng: parseFloat(post.lng),
                }"
              />
            </googlemaps-map>
            <div class="address">{{ post.comp_address }}</div>
          </div>
        </div>
        <div class="likes d-flex align-items-center justify-content-between">
          <div class="comments" @click="showModal(post, '', index)">
            {{ post.comments.length }} {{ $t("LBL_COMMENTS_TXT") }}
          </div>
          <div
            class="comments pl-2"
            v-b-modal.invite-friends
            @click="postUserList(post)"
          >
            {{ post.liked_users.length }} {{ $t("LBL_LIKE_TXT") }}
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between p-8">
          <div class="user-name down">
            <img :src="'./images/comment.png'" alt="" class="mr-2" />{{
              $t("LBL_COMMENT_TXT")
            }}
          </div>
          <div v-if="userAParseValue.id != post.user.id">
            <b-button
              v-b-modal.report-modal
              class="user-name down report-btn"
              @click="sendData(post.id)"
              ><i class="fa fa-flag pr-2"></i>{{ $t("report") }}</b-button
            >
          </div>
          <div
            class="user-name down "
            v-bind:style="selectedCursor"
            :class="{ like: !showClicked(post) }"
            @click="likeToggle(post, index)"
          >
            <img :src="'./images/like.png'" alt="" class="mr-2" />{{
              $t("LBL_LIKE_TXT")
            }}
          </div>
        </div>
        <hr />

        <!-- post listind comments in home -->
        <div v-if="checkEmptyArray(post.comments)">
          <div
            class="d-flex comments align-items-start"
            v-for="(comment, index) in reverseItems(post.comments)"
            :key="comment.id"
          >
            <input
              type="text"
              v-bind:id="'modalcommentButton' + index"
              style="display:none"
            />
            <div>
              <span v-if="comment.user.profile_image != ''">
                <img
                  :src="comment.user.profile_image"
                  class="mr-2 img-circle"
                />
              </span>
              <span v-else>
                <img
                  :src="'./images/dummy_user_img.png'"
                  class="mr-2 img-circle"
                  alt=""
                />
              </span>
            </div>
            <div class="other-comments">
              <div class="user-name .comment-user">
                {{ comment.user.first_name }} {{ comment.user.last_name }}
              </div>
              <div
                aria-disabled="disabled"
                v-bind:id="'textdiv' + index"
                class="textdiv"
              >
                {{ comment.comment }}
              </div>
              <div>
                <textarea
                  name=""
                  class="input-for-comment-append"
                  v-on:keyup.enter="
                    updateComment(reverseItems(post.comments), index)
                  "
                  v-bind:id="'input' + index"
                  style="display:none; width:250px;"
                ></textarea>
              </div>
              <div v-bind:id="'buttondiv' + index" class="buttondiv">
                <span v-if="userAParseValue.id == comment.user.id">
                  <i
                    class="fa fa-pencil comment-edit"
                    @click="appendUpdateComment(comment, index)"
                  ></i>
                </span>
                <span
                  v-if="
                    userAParseValue.id == comment.user.id ||
                      userAParseValue.id == post.user.id
                  "
                >
                  <i
                    class="fa fa-trash comment-delete"
                    @click="deleteVerificationComment(comment)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex comments align-items-start">
          <div>
            <span v-if="userProfile != ''">
              <img :src="userProfile" class="mr-2" />
            </span>
            <span v-else>
              <img
                :src="'./images/dummy_user_img.png'"
                class="mr-2 img-circle"
                alt=""
              />
            </span>
          </div>
          <textarea
            class="status-search w-100"
            type="text"
            v-bind:id="'listcomment' + post.id"
            @keyup="showCommentButton(post, 'list')"
            :placeholder="$t('LBL_WRITE_COMMENTS_TXT')"
          ></textarea>
          <i
            class="fa fa-paper-plane comment-send"
            v-bind:id="'listcommentButton' + post.id"
            @click="postComment(post, index, 'list')"
            style="display:none"
          ></i>
        </div>
      </div>

      <b-modal id="report-modal" centered title="Report">
        <div class="problem pb-2">{{ $t("reason") }}</div>
        <!-- <div class="problem-content">
          <select>
            <option v-bind:value="reason.id" v-for="reason in reportReasons" v-bind:key="reason.id" v-on:click="submitReason(reason.id)">{{ reason.reason}}</option>
          </select>
        </div> -->
        <div
          class="check"
          v-for="reason in reportReasons"
          v-bind:key="reason.id"
          v-on:click="submitReason(reason.id)"
        >
          <img :src="'./images/check-icon.svg'" alt="" class="mr-3" />{{
            reason.reason
          }}
        </div>
        <!-- <div class="problem-content" v-for="reason in reportReasons" v-bind:key="reason.id"
          v-on:click="submitReason(reason.id)">{{ reason.reason}}</div> -->
      </b-modal>

      <!-- End PostListing -->

      <!-- Modal Start Here  -->

      <div
        id="imageModal"
        class="post-detail-modal"
        v-if="Object.keys(modalData).length != 0"
      >
        <span class="close" id="closeModal" @click="closeModal">&times;</span>
        <!-- //image -->
        <div class="left-side" v-if="modalData.post_type == 'image'">
          <img class="modal-content" id="img01" :src="modalData.medium" />
        </div>
        <!-- //Location -->
        <!-- <div class="left-side">
              <img class="modal-content" id="location01" />
            </div> -->
        <!-- //Video -->
        <div class="left-side" v-if="modalData.post_type == 'video'">
          <video
            style="border: 1px solid;"
            class="modal-content"
            id="vid01"
            :src="modalData.medium"
            controls
          ></video>
          <!-- <video class="modal-content" id="vid01"  controls></video> -->
        </div>
        <!-- //status //text -->

        <div
          class="left-side status-preview scroll-sidebar"
          v-if="modalData.post_type == 'text'"
        >
          <div class="modal-content" id="status01">
            <div
              class="status minHeight"
              :style="getColor(modalData.background_color)"
            >
              <!-- <vue-show-more-text
            :text="modalData.text"
            :lines="4"
            additional-container-css="padding: 0;"
            additional-content-css="font-size:16px;"
            additional-content-expanded-css="font-size:16px;"
            additional-anchor-css="font-size: 16px;"
            @click="change"
            /> -->
              {{ modalData.text }}
            </div>
          </div>
        </div>
        <div
          class="left-side status-preview scroll-sidebar"
          v-if="modalData.post_type == 'location'"
        >
          <div class="modal-content" id="status01">
            <div class="">
              <googlemaps-map
                :center="{
                  lat: parseFloat(modalData.lat),
                  lng: parseFloat(modalData.lng),
                }"
                :zoom="13"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                }"
              >
                <googlemaps-marker
                  :position="{
                    lat: parseFloat(modalData.lat),
                    lng: parseFloat(modalData.lng),
                  }"
                />
              </googlemaps-map>
              <div class="address">{{ modalData.comp_address }}</div>
            </div>
          </div>
        </div>

        <div class="right-side scroll-sidebar">
          <div class="post-user-detail d-flex justify-content-between">
            <div class="d-flex">
              <div class="post-detail-profile">
                <span v-if="modalData.user.profile_image != ''">
                  <img :src="modalData.user.profile_image" alt="" />
                </span>
                <span v-else>
                  <img
                    :src="'./images/dummy_user_img.png'"
                    class="mr-1 img-circle"
                    alt=""
                  />
                </span>
              </div>

              <div class="ml-1">
                <div class="d-flex align-items-center number-plate">
                  <div class="d-flex outer-div">
                    <div class="circle"></div>
                    <div>{{ modalData.user.country_code }}</div>
                  </div>
                  <div class="d-flex justify-content-center w-65">
                    {{ modalData.user.city_code
                    }}{{ modalData.user.plate_number }}
                  </div>
                </div>
                <div class="user-name">
                  {{ modalData.user.first_name }}
                  {{ modalData.user.last_name }}
                </div>
                <div class="post-date">
                  {{
                    moment
                      .utc(modalData.created_at)
                      .local()
                      .format("d-MM-YYYY / h:mm A")
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex align-items-start">
              <!-- <button class="share-btn">Share</button> -->
            </div>
          </div>
          <div
            class="post-description"
            v-if="modalData.medium != '' || modalData.post_type == 'location'"
          >
            <vue-show-more-text
              :text="modalData.text"
              :lines="4"
              additional-container-css="padding: 0;"
            />
          </div>
          <div
            class="post-detail-likes d-flex align-items-center justify-content-between"
          >
            <div class="detail-comments" v-if="modalData.comments">
              <span>{{ modalData.comments.length }} </span
              >{{ $t("LBL_COMMENTS_TXT") }}
            </div>
            <div
              class="detail-comments pl-2"
              v-if="modalData.liked_users"
              v-b-modal.invite-friends
              @click="postUserList(modalData)"
            >
              <span>{{ modalData.liked_users.length }} </span
              >{{ $t("LBL_LIKE_TXT") }}
            </div>
          </div>
          <hr class="mb-1 mt-0" />
          <div class="d-flex justify-content-between">
            <div class="user-name down">
              <img :src="'./images/comment.png'" alt="" class="mr-2" />{{
                $t("LBL_COMMENTS_TXT")
              }}
            </div>
            <div
              class="user-name down "
              v-bind:style="selectedCursor"
              :class="{ like: !showClicked(modalData) }"
              @click="likeToggle(modalData, 'object')"
            >
              <img :src="'./images/like.png'" alt="" class="mr-2" />{{
                $t("LBL_LIKE_TXT")
              }}
            </div>
          </div>
          <hr class="mb-1 mt-0" />

          <div class="d-flex comments align-items-start">
            <div>
              <span v-if="userProfile != ''">
                <img :src="userProfile" class="mr-2" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" class="mr-2" alt="" />
              </span>
            </div>
            <textarea
              class="status-search w-100"
              type="text"
              v-bind:id="'modalcomment' + modalData.id"
              @keyup="showCommentButton(modalData, 'modal')"
              :placeholder="$t('LBL_WRITE_COMMENTS_TXT')"
            ></textarea>
            <i
              class="fa fa-paper-plane comment-send"
              v-bind:id="'modalcommentButton' + modalData.id"
              @click="postComment(modalData, '', 'modal')"
              style="display:none"
            ></i>
          </div>
          <!-- modal comments details start here -->
          <div
            class="d-flex comments align-items-start"
            v-for="(comment, index) in reverseItemsPostDetail(
              modalData.comments
            )"
            :key="comment.id"
          >
            <input
              type="text"
              v-bind:id="'modalcommentButton' + index"
              style="display:none"
            />
            <div>
              <span v-if="comment.user.profile_image != ''">
                <img
                  :src="comment.user.profile_image"
                  class="mr-2 img-circle"
                />
              </span>
              <span v-else>
                <img
                  :src="'./images/dummy_user_img.png'"
                  class="mr-2 img-circle"
                  alt=""
                />
              </span>
            </div>
            <div class="other-comments">
              <div class="user-name .comment-user">
                {{ comment.user.first_name }} {{ comment.user.last_name }}
              </div>
              <div
                aria-disabled="disabled"
                v-bind:id="'textdiv' + index"
                class="textdiv"
              >
                {{ comment.comment }}
              </div>
              <div>
                <textarea
                  name=""
                  class="input-for-comment-append"
                  v-on:keyup.enter="
                    updateComment(
                      reverseItemsPostDetail(modalData.comments),
                      index
                    )
                  "
                  v-bind:id="'input' + index"
                  style="display:none; width:250px;"
                ></textarea>
              </div>
              <div v-bind:id="'buttondiv' + index" class="buttondiv">
                <span v-if="userAParseValue.id == comment.user.id">
                  <i
                    class="fa fa-pencil comment-edit"
                    @click="appendUpdateComment(comment, index)"
                  ></i>
                </span>
                <span
                  v-if="
                    userAParseValue.id == comment.user.id ||
                      userAParseValue.id == modalData.user.id
                  "
                >
                  <i
                    class="fa fa-trash comment-delete"
                    @click="deleteVerificationComment(comment)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End Model  -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import moment from "moment";
import Swal from "sweetalert2";
import $ from "jquery";
import ColorPicker from "vue-color-picker-wheel";
import { MapElement } from "vue-googlemaps";
import { Mentionable } from "vue-mention";
Vue.prototype.moment = moment;
import vueShowMoreText from "vue-show-more-text";

import {
  removeImg,
  changeColor,
  Colorwheel,
  removeVideo,
  onFileChange,
  onVideoChange,
  locationchange,
  showPopup,
  hidePopup,
} from "../util/function";
export default {
  components: {
    Mentionable,
    ColorPicker,
    vueShowMoreText,
  },
  data() {
    return {
      imageUrl: null,
      image: null,
      postType: "",
      video: null,
      loading: true,
      postButtonLoader: false,
      commentButton: false,
      likedStatus: null,
      BASE_URL: null,
      userProfile: null,
      videoUrl: null,
      selectedCursor: "pointer-events:auto",
      imageRemoveBtn: false,
      videoRemoveBtn: false,
      showPopupp: "",
      comment: "",
      delcomment: null,
      audioMaxTime: "05:00",
      uploadMax: 31457280,
      getTime: "",
      text: "",
      postTitle: "",
      editpostID: "",
      background_color: "",
      color: "#ff6600",
      userAParseValue: "",
      sitebody: document.body,
      isActive: false,
      posts: [],
      reportReasons: [],
      likedUsers: [],
      items: [],
      users: [],
      hashtag: [],
      liked: {
        id: false,
      },
      modalData: {},
      selectedPostForReport: "",
      isEditPost: false,
      locationdiv: "",
      postshare: "",
      center: {},
      zoom: "",
      latitude: "",
      longitude: "",
      position: {},
      mixins: [
        // You need to use this mixin
        MapElement,
      ],
    };
  },

  async mounted() {
    this.getPostList();
    this.getReportReaasons();
    this.getUserList();
    this.hashtags();
  },
  methods: {
    change(showAll) {
      console.log(showAll);
    },
    onOpen(key) {
      if (key === "@") {
        this.items = this.users;
      }
      if (key === "#") {
        this.items = this.hashtag;
      }
    },
    addpostButton() {
      this.postTitle = "Create Post";
    },
    getColor(color) {
      if (color) {
        return "background-color:" + color;
      }

      return "background: #C8A860";
    },
    changeClass() {
      if (this.postButtonLoader == true) {
        return "fa fa-spinner fa-spin";
      }
    },
    successAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    errorAlert(message) {
      Swal.fire({
        title: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    checkFileDuration() {
      let vm = this;
      var file = document.querySelector("#vid").files[0];
      var reader = new FileReader();
      var fileSize = file.size;

      if (fileSize > this.uploadMax) {
        alert("file too large");
        $("#vid").val("");
      } else {
        $("#pross").show();
        reader.onload = function(e) {
          function secondsToTimeFunc(in_seconds) {
            var time = "";
            in_seconds = parseFloat(in_seconds.toFixed(2));

            var hours = Math.floor(in_seconds / 3600);
            var minutes = Math.floor((in_seconds - hours * 3600) / 60);
            var seconds = in_seconds - hours * 3600 - minutes * 60;
            //seconds = Math.floor( seconds );
            seconds = seconds.toFixed(0);

            if (hours < 10) {
              hours = "0" + hours;
            }
            if (minutes < 10) {
              minutes = "0" + minutes;
            }
            if (seconds < 10) {
              seconds = "0" + seconds;
            }
            time = minutes + ":" + seconds;

            return time;
          }

          if (
            file.type == "video/mp4" ||
            file.type == "video/webm" ||
            file.type == "video/x-matroska" ||
            file.type == "video/quicktime"
          ) {
            var videoElement = document.createElement("video");
            videoElement.src = e.target.result;

            var timer = setInterval(function() {
              var videoMaxTime = "01:00";
              if (videoElement.readyState === 4) {
                let myData = secondsToTimeFunc(videoElement.duration);
                this.getTime = myData;
                if (this.getTime > videoMaxTime) {
                  vm.$refs["video-error"].show();
                  vm.videoUrl = null;
                  vm.video = null;
                  vm.videoRemoveBtn = false;
                }
                $("#pross").hide();
                clearInterval(timer);
              }
            }, 500);
          } else if (
            file.type == "audio/mpeg" ||
            file.type == "audio/wav" ||
            file.type == "audio/ogg"
          ) {
            var audioElement = document.createElement("audio");
            audioElement.src = e.target.result;
            timer = setInterval(function() {
              if (audioElement.readyState === 4) {
                this.getTime = this.secondsToTime(audioElement.duration);
                if (this.getTime > this.audioMaxTime) {
                  alert("1 minutes audio only");
                  $("#vid").val("");
                }
                $("#pross").hide();
                clearInterval(timer);
              }
            }, 500);
          } else {
            timer = setInterval(function() {
              if (file) {
                alert("invaild File");
                vm.videoUrl = null;
                vm.video = null;
                vm.videoRemoveBtn = false;

                clearInterval(timer);
              }
            }, 500);
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        } else {
          alert("nofile");
        }
      }
    },
    async DeletePost(post) {
      const data = post.id;
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      this.userAParseValue = userAParseValue;
      this.userProfile = userAParseValue.profile_image;
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .delete(this.$paths + "posts/" + data, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            location.reload();
            this.successAlert(response.data.message);
            Object.assign(this.$data, this.$options.data());
          } else {
            this.getPostList();
            this.errorAlert(response.data.message);
          }
        });
    },

    //Edit post function
    async EditPost(post) {
      this.postTitle = "Edit Post";
      this.imageUrl = "";
      this.videoUrl = "";
      this.imageRemoveBtn = false;
      this.videoRemoveBtn = false;
      this.postType = post.post_type;
      this.text = post.text;
      this.background_color = post.background_color;
      this.editpostID = post.id;
      if (this.postType == "location") {
        this.locationdiv = post.comp_address;
        this.latitude = post.lat;
        this.longitude = post.lng;
      }
      if (this.postType == "image") {
        this.imageRemoveBtn = true;
        this.imageUrl = post.medium;
        this.background_color = "white";
        this.locationdiv = "";
      }
      if (this.postType == "video") {
        this.videoRemoveBtn = true;
        this.videoUrl = post.medium;
        this.background_color = "white";
        this.locationdiv = "";
      }
      this.isEditPost = true;
      this.$bvModal.show("post-modal");
    },
    async addPost() {
      this.postType = "";
      this.text = "";
      this.background_color = "white";
      this.isEditPost = false;
      this.imageUrl = "";
      this.videoUrl = "";
      this.videoRemoveBtn = false;
      this.imageRemoveBtn = false;
      this.locationdiv = "";
      this.center = "";
      // background_color
      // this.getColor(post.background_color);
      // this.$bvModal.show('post-modal')
    },
    async editpost() {
      const newFormData = new FormData();
      this.postButtonLoader = true;
      // if(this.image==null && this.video==null) {
      //   this.postType='text';
      // }
      if (this.image) {
        this.postType = "image";
        newFormData.append("medium", this.image);
      }
      if (this.video) {
        this.postType = "video";
        newFormData.append("medium", this.video);
      }
      if (this.locationdiv) {
        var la = this.latitude;
        var ln = this.longitude;
        this.postType = "location";
        newFormData.append("comp_address", this.locationdiv);
        newFormData.append("lat", la);
        newFormData.append("lng", ln);
      }
      this.userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      newFormData.append("text", this.text);
      newFormData.append("background_color", this.background_color);
      newFormData.append("user_id", this.userAParseValue.id);
      newFormData.append("post_type", this.postType);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      var result = await axios
        .post(this.$paths + "posts/update/" + this.editpostID, newFormData, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      console.log(result);
      if (result.code == 200) {
        location.reload();
      }
    },
    //post image delete
    async postimageDel() {
      const newFormData = new FormData();
      this.userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      newFormData.append("id", this.editpostID);
      newFormData.append("user_id", this.userAParseValue.id);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      await axios
        .post(this.$paths + "postimageDel", newFormData, {
          headers,
        })
        .then((response) => {
          this.background_color = response.data.data.background_color;
          this.postType = response.data.data.post_type;
        });
      // if (result.code == 200) {
      //   // location.reload();
      // }
    },
    //post like user list
    async postUserList(post) {
      const data = {
        post_id: post.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "postLikedUsers", data, {
          headers,
        })
        .then((response) => {
          this.likedUsers = response.data.data;
        });
    },
    async likeToggle(post, index) {
      this.selectedCursor = "pointer-events:none";
      this.userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      const data = { post_id: post.id, liked_status: this.likedStatus };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      if (post.is_liked) {
        this.likedStatus = "Dislike";
      } else {
        this.likedStatus = "Like";
      }

      var result = await axios
        .post(this.$paths + "postLikeDislike", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.message == "Post liked successfully") {
        this.selectedCursor = "pointer-events:auto";
        post.liked_users.length++;
        this.isLoading = false;
        return (this.posts[index].is_liked = true);
      } else if (result.message == "Post Unliked") {
        this.selectedCursor = "pointer-events:auto";

        post.liked_users.length--;
        this.isLoading = false;
        return (this.posts[index].is_liked = false);
      }
      this.selectedCursor = "pointer-events:auto";
    },
    checkEmptyArray(comments) {
      if (comments.length > 0) {
        return true;
      }
      return false;
    },

    async createPost() {
      this.postButtonLoader = true;
      const formData = new FormData();
      if (this.image == null && this.video == null && this.locationdiv == "") {
        this.postType = "text";
      }
      if (this.image) {
        this.postType = "image";
        formData.append("medium", this.image);
      }
      if (this.video) {
        this.postType = "video";
        formData.append("medium", this.video);
      }
      if (this.locationdiv) {
        var la = this.center.lat;
        var ln = this.center.lng;
        this.postType = "location";
        formData.append("comp_address", this.locationdiv);
        formData.append("lat", la);
        formData.append("lng", ln);
      }

      this.userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      formData.append("text", this.text);
      formData.append("background_color", this.background_color);
      formData.append("user_id", this.userAParseValue.id);
      formData.append("post_type", this.postType);
      console.log(this.postType);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      var result = await axios
        .post(this.$paths + "posts", formData, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        location.reload();
      }
    },

    reverseItems(comments) {
      return comments.slice(comments.length - 2, comments.length).reverse();
    },
    reverseItemsPostDetail(comments) {
      return comments
        .slice(comments.length - comments.length, comments.length)
        .reverse();
    },
    async postComment(post, index, type) {
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      var commentValue;
      var buttonId;
      if (type == "modal") {
        commentValue = document.getElementById("modalcomment" + post.id).value;
        buttonId = "modalcommentButton" + post.id;
      } else {
        commentValue = document.getElementById("listcomment" + post.id).value;
        buttonId = "listcommentButton" + post.id;
      }
      var commentButton = document.getElementById(buttonId);
      const data = {
        post_id: post.id,
        user_id: userAParseValue.id,
        comment: commentValue,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios
        .post(this.$paths + "postComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        commentButton.style.display = "none";
        if (type == "modal") {
          this.modalData.comments.push(result.data);
          document.getElementById("modalcomment" + post.id).value = "";
        } else {
          this.posts[index].comments.push(result.data);
          document.getElementById("listcomment" + post.id).value = "";
        }
      }
    },
    showCommentButton(post, type) {
      var buttonId;
      var commentValue;
      if (type == "modal") {
        commentValue = document.getElementById("modalcomment" + post.id).value;
        buttonId = "modalcommentButton" + post.id;
      } else {
        commentValue = document.getElementById("listcomment" + post.id).value;
        buttonId = "listcommentButton" + post.id;
      }
      var chars = commentValue.length;
      var commentButton = document.getElementById(buttonId);
      if (chars > 0) {
        commentButton.style.display = "flex";
      } else {
        commentButton.style.display = "none";
      }
    },

    showClicked(post) {
      if (post.is_liked) {
        return false;
      } else {
        return true;
      }
    },

    async deleteComment(object) {
      const data = {
        comment_id: object.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios
        .post(this.$paths + "deleteComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        this.$refs["comment-modal"].hide();
        let list = this.modalData.comments;
        for (let j = 0; j < this.modalData.comments.length; j++) {
          if (object.id == this.modalData.comments[j].id) {
            list.splice(j, 1);
            this.modalData.comments = list;
            break;
          }
        }
      }
    },
    hideModal() {
      this.$refs["comment-modal"].hide();
    },
    deletefunction() {
      this.deletePostComment(this.delcomment);
    },
    deleteVerificationComment(object) {
      this.delcomment = object;
      this.$refs["comment-modal"].show();
    },
    //home comment delete
    async deletePostComment(object) {
      const data = {
        comment_id: object.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      var result = await axios
        .post(this.$paths + "deleteComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        this.$refs["comment-modal"].hide();
        for (let i = 0; i < this.posts.length; i++) {
          let idFound = false;
          let list = this.posts[i].comments;
          for (let j = 0; j < this.posts[i].comments.length; j++) {
            if (object.id == this.posts[i].comments[j].id) {
              idFound = true;
              list.splice(j, 1);
              this.posts[i].comments = list;
              break;
            }
          }
          if (idFound == true) {
            break;
          }
        }
      }
    },

    async getPostList() {
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      this.userAParseValue = userAParseValue;
      this.userProfile = userAParseValue.profile_image;

      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      try {
        axios
          .get(this.$paths + "posts", {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              console.log(response.data.data.posts);
              this.userAParseValue = userAParseValue;
              this.posts = response.data.data.posts;
              this.loading = false;
            } else if (response.data.code != 200) {
              this.$router.push({
                name: "login",
              });
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    async getReportReaasons() {
      try {
        var result = await axios.get(this.$store.state.baseUrl + "getReasons");
        if (result.data.code == 200) {
          this.reportReasons = result.data.data;
        } else {
          this.reportReasons = [];
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },

    async submitReason(id) {
      const data = {
        post_id: this.selectedPostForReport,
        reason_id: id,
      };

      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      await axios
        .post(this.$paths + "postReport", data, {
          headers,
        })
        .then((response) => {
          this.$bvModal.hide("report-modal");
          this.successAlert(response.data.message);
        });
    },

    sendData(id) {
      this.selectedPostForReport = id;
    },

    hideUnhideclass(className, displayState) {
      var elements = document.getElementsByClassName(className);

      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = displayState;
      }
    },

    appendUpdateComment(object, index) {
      this.hideUnhideclass("input-for-comment-append", "none");
      this.hideUnhideclass("textdiv", "flex");
      this.hideUnhideclass("buttondiv", "flex");
      var showInput = document.getElementById("input" + index);
      showInput.style.display = "flex";
      var buttondiv = document.getElementById("buttondiv" + index);
      buttondiv.style.display = "none";
      var div = document.getElementById("textdiv" + index);
      div.style.display = "none";
      showInput.value = object.comment;
    },

    async updateComment(object, index) {
      var showInput = document.getElementById("input" + index);
      var div = document.getElementById("textdiv" + index);
      var buttondiv = document.getElementById("buttondiv" + index);
      const data = {
        comment: showInput.value,
        comment_id: object[index].id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      var result = await axios
        .post(this.$paths + "updateComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        div.style.display = "flex";
        buttondiv.style.display = "flex";
        showInput.style.display = "none";
        // div.replaceWith(result.data.comment)
        object[index].comment = result.data.comment;
      }
    },

    getLocation() {
      this.locationdiv = document.getElementById("location");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        this.locationdiv = "Geolocation is not supported by this browser.";
      }
    },

    showPosition(position) {
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;

      JSON.parse(JSON.stringify(position.coords.longitude));
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.displayLocation(lat, lon);
    },

    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.locationdiv = "User denied the request for Geolocation.";
          break;
        case error.POSITION_UNAVAILABLE:
          this.locationdiv = "Location information is unavailable.";
          break;
        case error.TIMEOUT:
          this.locationdiv = "The request to get user location timed out.";
          break;
        case error.UNKNOWN_ERROR:
          this.locationdiv = "An unknown error occurred.";
          break;
      }
    },

    displayLocation(latitude, longitude) {
      var geocoder;
      let vm = this;
      geocoder = new window.google.maps.Geocoder();
      var latlng = new window.google.maps.LatLng(latitude, longitude);

      geocoder.geocode({ latLng: latlng }, function(results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            var add = results[0].formatted_address;

            vm.locationdiv = add;
          } else {
            vm.locationdiv = "address not found";
          }
        } else {
          vm.locationdiv = "Geocoder failed due to: " + status;
        }
      });
    },
    showModal(postObject) {
      this.modalData = postObject;
      var videoModal = document.getElementById("imageModal");
      videoModal.style.display = "flex";
      this.showPopupp = this.sitebody.classList.add("noscroll");
    },

    closeModal() {
      document.getElementById("imageModal").style.display = "none";
      this.showPopupp = this.sitebody.classList.remove("noscroll");
    },
    async sharepost(post) {
      this.$notify("Copied to clipboard");
      const data = {
        id: post.id,
      };

      var result = await axios
        .post(this.$paths + "postsharelink", data, {})
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        navigator.clipboard.writeText(result.data);
      }
    },
    async getUserList() {
      try {
        const userAParseValue = JSON.parse(
          localStorage.getItem("loginUserData")
        );
        this.userAParseValue = userAParseValue;
        var result = await axios
          .get(this.$paths + "getUsers", {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          })
          .then((response) => (this.userData = response.data));
        if (result.code == 200) {
          this.users = result.data.map((x) => {
            return {
              value: x.first_name,
              firstName: x.first_name,
            };
          });
        } else if (result.code != 200) {
          this.$router.push("login");
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },
    async hashtags() {
      try {
        const userAParseValue = JSON.parse(
          localStorage.getItem("loginUserData")
        );
        this.userAParseValue = userAParseValue;
        var result = await axios
          .get(this.$paths + "getHashtags", {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          })
          .then((response) => (this.userData = response.data));
        if (result.code == 200) {
          this.hashtag = result.data.map((x) => {
            return {
              value: x.hashtag,
              label: x.hashtag,
            };
          });
        } else if (result.code != 200) {
          this.$router.push("login");
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },
    removeImg,
    removeVideo,
    onFileChange,
    onVideoChange,
    locationchange,
    showPopup,
    hidePopup,
    changeColor,
    Colorwheel,
  },
  watch: {
    color(val) {
      $("#textBox").css("background-color", val);
      this.background_color = val;
    },
  },
};
</script>

<style scoped>
.p-8 {
  padding: 0 8px;
}
.address {
  background-color: #f0f2f5;
  border: 1px solid #ced0d4;
  font-size: 17px;
  font-weight: 600;
  color: #000;
  padding: 20px 10px;
}
</style>
