import Vue from 'vue'
import axios from 'axios'
import vueaxios from 'vue-axios'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store/index'
import router from "./router/routes"
import firebase from 'firebase'
import moment from 'moment'
import VueObserveVisibility from 'vue-observe-visibility'
import VueGoogleMaps from 'vue-googlemaps'
import Notifications from 'vue-notification'
import {i18n} from './plugins/i18n'
import VTooltip from "v-tooltip";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "font-awesome/css/font-awesome.min.css";
import './assets/css/style.css'
import './assets/css/responsive.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "./assets/css/tooltip.css";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vuetify from "vuetify";
import VueSelectImage from 'vue-select-image'
import EleUploadVideo from "vue-ele-upload-video"
import $ from 'jquery'
import 'vue-googlemaps/dist/vue-googlemaps.css'
import OtpInput from "@bachdgvn/vue-otp-input";
import ColorPicker from "vue-color-picker-simple";
import "vue-color-picker-simple/dist/index.css";
import '../node_modules/vue-anka-cropper/dist/VueAnkaCropper.css';


Vue.use(ColorPicker);


// add stylesheet
require('vue-select-image/dist/vue-select-image.css')

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueSelectImage);
Vue.component(EleUploadVideo.name, EleUploadVideo);
Vue.component("v-otp-input", OtpInput);
Vue.use(vueaxios, axios);
Vue.use(VueSweetalert2);
Vue.use(Notifications)
Vue.use(VueGoogleMaps, {
  load: {
    // Google API key
    apiKey: 'AIzaSyCpK6mZu7ZthECpD1CGlMS19RkNuBAck5A',
    // Enable more Google Maps libraries here
        libraries: ['places'],
    // Use new renderer

  },
})
Vue.use(VueObserveVisibility)
var firebaseConfig = {
  apiKey: "AIzaSyAULB3GSND1J0Lf9AjnLcMo7FTzhny6CaA",
  authDomain: "meet-my-car.firebaseapp.com",
  projectId: "meet-my-car",
  storageBucket: "meet-my-car.appspot.com",
  messagingSenderId: "254083298657",
  appId: "1:254083298657:web:069267ff6283f6d7470120"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.use(VueRouter);
Vue.use(moment);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCpK6mZu7ZthECpD1CGlMS19RkNuBAck5A',
    libraries: "places",
    v: '3.26',
  }
});
Vue.config.productionTip = false

new Vue({
  $,
  axios,
  i18n,
  VTooltip,
  vueaxios,
  router,
  store,
  moment,
  vuetify,
  VueSelectImage,
  EleUploadVideo,
  render: h => h(App),
}).$mount('#app')
