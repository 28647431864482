<template>
  <div id="UserProfile">
    <div class="right-content-section">
      <div class="profile-content">
        <div class="profile-user-name">Tony Starc</div>
        <div class="position-relative">
          <div class="cover-img" @click="showPopup">
            <img :src="'./images/sufia.png'" alt="" id="myCover" />
          </div>
          <div id="coverModal" class="post-detail-modal">
            <span class="close" @click="hidePopup">&times;</span>
            <img class="modal-content" id="cover01" />
          </div>
          <div class="profile-img" @click="showPopup">
            <img :src="'./images/hassan.jpg'" alt="" id="myProfile" />
          </div>
          <div id="profileModal" class="post-detail-modal">
            <span class="close" @click="hidePopup">&times;</span>
            <img class="modal-content" id="profile01" />
          </div>
        </div>
        <div class="p-15">
          <div class="d-flex justify-content-between">
            <button class="pending-btn">
              <i class="fa fa-thumbs-down pr-1"></i>15 dislikes
            </button>

            <button class="follow-btn">
              <i class="fa fa-thumbs-up pr-1" style="color:#fff"></i>54 likes
            </button>
          </div>
          <div class="d-flex followers scroll-followers">
            <div class="mr-2"><img :src="'./images/anaya.jpg'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/Oval.png'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/girl.png'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/boy.png'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/sufia.png'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/anaya.jpg'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/boy.png'" alt="" /></div>
            <div class="mr-2"><img :src="'./images/girl.png'" alt="" /></div>
            <div class="mr-2">
              <img :src="'./images/hassan.jpg'" alt="" />
            </div>
            <div class="mr-2"><img :src="'./images/smith.png'" alt="" /></div>
          </div>
          <div class="d-flex justify-content-between pt-2">
            <div>15 Driver Likes</div>
            <a href="#" style="color:#6D6E72">See all followers</a>
          </div>
        </div>
      </div>
      <div class="profile-content mt-3">
        <div class="p-15">
          <div style="font-size:22px;font-weight:600;">About:</div>
          <div>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde error
            quibusdam sapiente officiis quaerat perferendis, eius expedita,
            iusto iure quis at reprehenderit dolorum dicta excepturi officia
            eveniet iste a quae.Lorem, ipsum dolor sit amet consectetur
            adipisicing elit. Unde error quibusdam sapiente officiis quaerat
            perferendis, eius expedita, iusto iure quis at reprehenderit dolorum
            dicta excepturi officia eveniet iste a quae.Lorem, ipsum dolor sit
            amet consectetur adipisicing elit.
          </div>
        </div>
      </div>
      <div class="custom-content">
        <div class="listing">
          <div class="user-detail d-flex justify-content-between">
            <div class="d-flex">
              <div class="listing-profile">
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </div>
              <div class="ml-2">
                <div class="d-flex align-items-center number-plate">
                  <div class="d-flex outer-div">
                    <div class="circle"></div>
                    <div>D</div>
                  </div>
                  <div class="d-flex justify-content-center w-65">
                    <div>KO12QA</div>
                  </div>
                </div>
                <div class="user-name pt-1">Hassan Saleem</div>
                <div class="post-date">15-02-21 / 07:45pm</div>
              </div>
            </div>
            <div class="d-flex align-items-start">
              <button class="share-btn">Share</button>
            </div>
          </div>
          <div class="description">
            👉 New Store Opening with Flat 50% Off on Entire stock<br />
            👉 First 4 Days store Timing is From 7 AM<br />👉 Exclusively at
            Mall of Lahore
          </div>
          <div @click="showPopup">
            <img :src="'./images/event.png'" id="myImg" alt="" class="w-100" />
          </div>

          <div id="listingModal" class="post-detail-modal">
            <span class="close" @click="hidePopup">&times;</span>
            <div class="left-side">
              <img class="modal-content" id="img01" />
            </div>
            <div class="right-side scroll-sidebar">
              <div class="post-user-detail d-flex justify-content-between">
                <div class="d-flex">
                  <div class="post-detail-profile">
                    <img :src="'./images/dummy_user_img.png'" alt="" />
                  </div>
                  <div class="ml-1">
                    <div class="d-flex align-items-center number-plate">
                      <div class="d-flex outer-div">
                        <div class="circle"></div>
                        <div>D</div>
                      </div>
                      <div class="d-flex justify-content-center w-65">
                        KO12QA
                      </div>
                    </div>
                    <div class="user-name">Hassan Saleem</div>
                    <div class="post-date">15-02-21 / 07:45pm</div>
                  </div>
                </div>
                <div class="d-flex align-items-start">
                  <button class="share-btn">Share</button>
                </div>
              </div>
              <div class="post-description">
                👉 New Store Opening with Flat 50% Off on Entire stock<br />
                👉 First 4 Days store Timing is From 7 AM<br />👉 Exclusively at
                Mall of Lahore
              </div>
              <div
                class="post-detail-likes d-flex align-items-center justify-content-between"
              >
                <div class="detail-comments">40k Comments</div>
                <div class="detail-comments pl-2">11 Likes</div>
              </div>
              <hr />
              <div class="d-flex justify-content-between">
                <div class="user-name down">
                  <img
                    :src="'./images/comment.png'"
                    alt=""
                    class="mr-2"
                  />Comment
                </div>
                <div class="user-name down">
                  <img :src="'./images/like.png'" alt="" class="mr-2" />Like
                </div>
              </div>
              <hr class="mt-1" />
              <div class="d-flex detail-comments align-items-start">
                <div>
                  <img
                    :src="'./images/dummy_user_img.png'"
                    alt=""
                    class="mr-2"
                  />
                </div>
                <input
                  class="status-search w-100"
                  type="text"
                  :placeholder="$t('LBL_WRITE_COMMENTS_TXT')"
                />
              </div>
              <div class="d-flex detail-comments align-items-start">
                <div><img :src="'./images/girl.png'" class="mr-2" /></div>
                <div class="other-comments">
                  <div class="user-name .comment-user">Angelina Tom</div>
                  <div aria-disabled="disabled">Hello dear, How are you???</div>
                </div>
              </div>
              <div class="d-flex detail-comments align-items-start">
                <div><img :src="'./images/boy.png'" class="mr-2" /></div>
                <div class="other-comments">
                  <div class="user-name .comment-user">Michael Jackson</div>
                  <div aria-disabled="disabled">
                    Please tell me the car's model and type....Second one is
                    this car is modified or genuine..!!!
                  </div>
                </div>
              </div>
              <div class="d-flex detail-comments align-items-start">
                <div><img :src="'./images/boy.png'" class="mr-2" /></div>
                <div class="other-comments">
                  <div class="user-name .comment-user">Michael Jackson</div>
                  <div aria-disabled="disabled">
                    Please tell me the car's model and type....Second one is
                    this car is modified or genuine..!!!
                  </div>
                </div>
              </div>
              <div class="d-flex detail-comments align-items-start">
                <div><img :src="'./images/boy.png'" class="mr-2" /></div>
                <div class="other-comments">
                  <div class="user-name .comment-user">Michael Jackson</div>
                  <div aria-disabled="disabled">
                    Please tell me the car's model and type....Second one is
                    this car is modified or genuine..!!!
                  </div>
                </div>
              </div>
              <div class="d-flex detail-comments align-items-start">
                <div><img :src="'./images/boy.png'" class="mr-2" /></div>
                <div class="other-comments">
                  <div class="user-name .comment-user">Michael Jackson</div>
                  <div aria-disabled="disabled">
                    Please tell me the car's model and type....Second one is
                    this car is modified or genuine..!!!
                  </div>
                </div>
              </div>
              <div class="d-flex detail-comments align-items-start">
                <div><img :src="'./images/boy.png'" class="mr-2" /></div>
                <div class="other-comments">
                  <div class="user-name .comment-user">Michael Jackson</div>
                  <div aria-disabled="disabled">
                    Please tell me the car's model and type....Second one is
                    this car is modified or genuine..!!!
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="likes d-flex align-items-center justify-content-between">
            <div class="comments">40k Comments</div>
            <div class="comments pl-2">11 Likes</div>
          </div>
          <hr />
          <div class="d-flex justify-content-between px-1">
            <div class="user-name down">
              <img :src="'./images/comment.png'" alt="" class="mr-2" />Comment
            </div>
            <b-button v-b-modal.report-modal class="user-name down report-btn"
              ><i class="fa fa-flag pr-2"></i>Report</b-button
            >
            <b-modal id="report-modal" centered title="Report">
              <div class="problem">Please select a problem to continue</div>
              <div class="problem-select">
                You can report the post after selecting a problem.
              </div>
              <div class="problem-content">Nudity</div>
              <div class="problem-content">Violence</div>
              <div class="problem-content">Harrasment</div>
              <div class="problem-content">Suicide or Self-injury</div>
              <div class="problem-content">False Information</div>
              <div class="problem-content">Spam</div>
              <div class="problem-content">Hate Speech</div>
              <div class="problem-content">Terrorism</div>
              <div class="problem-content">Unauthorized Sales</div>
            </b-modal>
            <div
              class="user-name down"
              :class="{ like: isActive }"
              @click="isActive = !isActive"
            >
              <img :src="'./images/like.png'" alt="" class="mr-2" />Like
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showPopup, hidePopup } from "../util/function";

export default {
  name: "OtherUserProfile",
  data() {
    return {
      url: null,
      showPopupp: "",
      sitebody: document.body,
      isActive: false,
    };
  },
  mounted() {
    // cover modal
    var coverModal = document.getElementById("coverModal");
    coverModal.style.display = "none";
    var cover = document.getElementById("myCover");
    var modalCover = document.getElementById("cover01");
    cover.onclick = function() {
      coverModal.style.display = "flex";
      modalCover.src = this.src;
    };
    var coverSpan = document.getElementsByClassName("close")[0];
    coverSpan.onclick = function() {
      coverModal.style.display = "none";
    };
    // profile modal
    var profileModal = document.getElementById("profileModal");
    profileModal.style.display = "none";
    var profile = document.getElementById("myProfile");
    var modalProfile = document.getElementById("profile01");
    profile.onclick = function() {
      profileModal.style.display = "flex";
      modalProfile.src = this.src;
    };
    var span = document.getElementsByClassName("close")[1];
    span.onclick = function() {
      profileModal.style.display = "none";
    };
    // listing modal
    var listingModal = document.getElementById("listingModal");
    listingModal.style.display = "none";
    var listing = document.getElementById("myImg");
    var modalListing = document.getElementById("img01");
    listing.onclick = function() {
      listingModal.style.display = "flex";
      modalListing.src = this.src;
    };
    var listingSpan = document.getElementsByClassName("close")[2];
    listingSpan.onclick = function() {
      listingModal.style.display = "none";
    };
  },
  methods: {
    showPopup,
    hidePopup,
  },
};
</script>
