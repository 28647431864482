<template>
    <div>
                <Mentionable
                :keys="['@']"
                :items="items"
                offset="6"
                insert-space
                @open="onOpen"
                placement="bottom">
                <textarea  
                    id="textBox" 
                    v-model="text"
                    row="10"
                    column="10">
                </textarea>
                <template #item-@="{ item }">
                    <div class="user">
                        {{ item.value }}
                        <span class="dim">
                        ({{ item.firstName }})
                        </span>
                    </div>
                </template>
            </Mentionable>
    </div>
</template>

<script>

import { Mentionable } from 'vue-mention'
    const users = [
    {
        value: "akryum",
        firstName: "Guillaume"
    },
    {
        value: "posva",
        firstName: "Eduardo"
    },
    {
        value: "atinux",
        firstName: "Sébastien"
    }
    ];
export default {
      components: {
    Mentionable,
  },
  data(){
      return {
          text:"",
          items:[],
      }
    },

        methods: {
            onOpen (key) {
            console.log(key)
            console.log('users',users)
            if(key === '@'){
                this.items = users
            }
            console.log('items', this.items);
          }, 
          }
}
</script>