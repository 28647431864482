<template>
  <div id="forgot-password">
    <div class="right-content-section p-0">
      <div class="pt-5 pb-5">
        <div>
          <router-link to="/login"
            ><img :src="'./images/back.png'" alt=""
          /></router-link>
        </div>
        <div class="d-flex justify-content-center pt-3">
          <img :src="'./images/white-logo.png'" alt="" style="width:120px" />
        </div>
        <div class="d-flex justify-content-center pt-3">
          <img :src="'./images/white-car.png'" alt="" style="width:50%" />
        </div>
        <div class="pt-3">
          <b-form-group label="New Password">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><img :src="'./images/password-icon.png'" class="w-100" alt=""
                /></b-input-group-text>
              </template>
              <b-form-input
                type="password"
                placeholder="**********"
                class="text-left edit-profile-input"
                v-model="new_password"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Confirm Password">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><img :src="'./images/password-icon-secondary.png'" class="w-100" alt=""
                /></b-input-group-text>
              </template>
              <b-form-input
                type="password"
                placeholder="**********"
                class="text-left edit-profile-input"
                v-model="c_password"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="pt-5 text-center">
          <button class="otp-btn" @click="resetPassword()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Swal from "sweetalert2";
import axios from 'axios';
export default {
  props: ['phone_number'],
  name: "forget-password",
  data() {
    return {
      new_password: '',
      c_password: '',
    }
  },
  methods: {
      
      errorAlert(message) {
          Swal.fire({
              title: message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
          });
      },

      successAlert(message){
          Swal.fire({
              title: message,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
          })
      },
    async resetPassword() {
      if(this.new_password == '' || this.c_password == '') {
        this.errorAlert('Please fill both fields !');
      } else if (this.new_password != this.c_password) {
        this.errorAlert('Passwords does not match !');
      } else {
        const data = {
          phone_number: this.phone_number,
          new_password: this.new_password,
          confirm_password: this.c_password,
        }
        const reset = await axios.post(this.$paths+'changeUserPassword', data);
        if(reset.data.code == 200) {
          this.successAlert(reset.data.message)
          this.$router.push({ name: 'login' });
        } else {
          this.errorAlert(reset.data.message);
        }
      }
    }
  }
};
</script>
