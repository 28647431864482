<template>
  <div id="app">
      <section class="login"> 
        <div class="section-content">
          <div class="row align-items-center">
            <HelloWorld />
            <Login />
          </div>
        </div>
    </section>
  </div>
</template>

<script>
import Login from '../components/Login'
import HelloWorld from '../components/HelloWorld'

export default {
  name: 'LoginNew',
  components: {
    Login,
    HelloWorld
  }
}
</script>

