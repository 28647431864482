<template>
  <div id="Register">
    <div class="right-content-section pt-0">
      <div class="registeraion-content pt-5 pb-5">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <router-link to="/login"
            ><img :src="'./images/back.png'" alt=""
          /></router-link>
          <h2 class="title">Register</h2>
          <img :src="'./images/back.png'" alt="" style="visibility:hidden;" />
        </div>
        <p class="error-message" v-if="isSubmitted">{{ error.message }}</p>
        <b-form-group label="Car Number *" class="mb-0"></b-form-group>
        <div class="d-flex pb-2">
          <div class="bg-blue mr-1" style="width: 30%">
            <div class="about-circle"></div>
            <div class="text-center">
              <select
                class="about-select"
                v-model="countryId"
                v-on:change="allCities(countryId)"
                @change="flush"
              >
                <option></option>
                <option
                  v-bind:value="country.id"
                  v-for="country in countries"
                  v-bind:key="country.id"
                  >{{ country.code }}</option
                >
              </select>
            </div>
          </div>
          <div class="mr-1" style="width:60%">
            <!-- <select class="code-select city-code-select" v-model="cityId">
              <option value="0">City Code</option>
              <option v-bind:value="city.id" v-for="city in cities" v-bind:key="city.id">{{city.code}}</option>
            </select> -->
            <vSelect
              v-model="cityId"
              :options="options"
              placeholder="City Code"
              v-on:click="cities"
            />
          </div>
          <div class="w-100">
            <input
              type="text"
              v-model="plateNumber"
              class="code-select text-center"
              placeholder="Number"
              maxlength="7"
              style="border-radius:0 5px 5px 0"
            />
          </div>
        </div>
        <ul class="error-message" v-if="error">
          <li v-if="country_codes_id != ''">{{ country_codes_id }}</li>
          <li v-if="city_codes_id != ''">{{ city_codes_id }}</li>
          <li v-if="plate_number != ''">{{ plate_number }}</li>
        </ul>
        <div class="d-flex pt-2">
          <b-form-group
            label="First Name *"
            class="text-center mr-2"
            style="width: 50%;"
          >
            <b-form-input
              type="text"
              placeholder="First Name"
              v-model="firstName"
              class="edit-profile-input"
              maxlength="15"
              style="box-shadow:0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);"
              required
            >
            </b-form-input>
            <p class="error-message" v-if="first_name != ''">
              {{ first_name }}
            </p>
          </b-form-group>
          <b-form-group
            label="Last Name *"
            class="text-center"
            style="width: 50%"
          >
            <b-form-input
              type="text"
              placeholder="Last Name"
              v-model="lastName"
              class="edit-profile-input"
              maxlength="15"
              style="box-shadow:0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);"
              required
            >
            </b-form-input>
            <p class="error-message" v-if="last_name != ''">{{ last_name }}</p>
          </b-form-group>
        </div>
        <div class="pt-2">
          <b-form-group label="Email *">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><img :src="'./images/envelope.png'" class="w-100" alt=""
                /></b-input-group-text>
              </template>
              <b-form-input
                type="email"
                placeholder="Enter Email"
                v-model="email"
                class="text-left edit-profile-input"
                required
              ></b-form-input>
            </b-input-group>
            <p class="error-message" v-if="Email != ''">{{ Email }}</p>
          </b-form-group>
        </div>
        <div class="pt-2">
          <b-form-group label="Password *">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><img
                    :src="'./images/password-icon.png'"
                    class="w-100 password-img"
                    alt=""
                  />
                </b-input-group-text>
              </template>
              <input
                v-if="showPassword"
                type="text"
                v-model="ppassword"
                placeholder="********"
                minLength="8"
                class="text-left edit-profile-input"
                style="width: 83%;"
                required
              />
              <input
                type="password"
                v-else
                v-model="ppassword"
                placeholder="********"
                minLength="8"
                style="width: 83%;"
                class="text-left edit-profile-input"
                required
              />
              <a
                class="btn d-flex align-items-center"
                href="javascript:void(0)"
                @click="toggleShow"
                ><span class="icon is-small is-right">
                  <i
                    style="font-size: 20px;"
                    class="fa"
                    :class="{
                      'fa-eye-slash': showPassword,
                      'fa-eye': !showPassword,
                    }"
                  ></i>
                </span>
              </a>
            </b-input-group>
            <p class="error-message" v-if="password != ''">{{ password }}</p>
          </b-form-group>
        </div>
        <div class="pt-2">
          <b-form-group label="Re-Type *">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><img
                    :src="'./images/password-icon-secondary.png'"
                    class="w-100 password-img"
                    alt=""
                /></b-input-group-text>
              </template>
              <input
                v-if="showconfirmPassword"
                type="text"
                v-model="cpassword"
                placeholder="********"
                minLength="8"
                class="text-left edit-profile-input"
                style="width: 83%;"
                required
              />
              <input
                type="password"
                v-else
                v-model="cpassword"
                placeholder="********"
                minLength="8"
                style="width: 83%;"
                class="text-left edit-profile-input"
                required
              />
              <a
                class="btn d-flex align-items-center"
                href="javascript:void(0)"
                @click="toggleconfirmShow"
                ><span class="icon is-small is-right">
                  <i
                    style="font-size: 20px;"
                    class="fa"
                    :class="{
                      'fa-eye-slash': showconfirmPassword,
                      'fa-eye': !showconfirmPassword,
                    }"
                  ></i>
                </span>
              </a>
            </b-input-group>
            <p class="error-message" v-if="c_password != ''">
              {{ c_password }}
            </p>
          </b-form-group>
        </div>
        <div class="pt-2">
          <b-form-group label="Mobile Number *">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  ><img
                    :src="'./images/mobile.png'"
                    class="w-100"
                    style="height:55px"
                    alt=""
                  />
                </b-input-group-text>
              </template>
              <b-form-input
                type="tel"
                placeholder="Mobile number"
                v-model="phoneNumber"
                class="text-left edit-profile-input"
                readonly
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>
        <div>
          <span class="line">
            <h2><span>Car Info</span></h2>
          </span>
        </div>
        <div class="d-flex align-items-center pt-4">
          <div class="car-info">Brand *</div>
          <select
            class="code-select px-3"
            v-model="brandId"
            v-on:change="updateBrandId(brandId)"
          >
            <option value="0">Select brand name</option>
            <option
              v-bind:value="brand.id"
              v-for="brand in allBrands"
              v-bind:key="brand.id"
              >{{ brand.name }}</option
            >
          </select>
        </div>
        <p class="error-message-2" v-if="brand_id != ''">{{ brand_id }}</p>
        <div class="d-flex align-items-center pt-4">
          <div class="car-info">Model *</div>
          <select
            class="code-select px-3"
            v-model="brandModelId"
            v-on:change="updateBrandModelId(brandModelId)"
          >
            <option value="0">Select brand model</option>
            <option
              v-bind:value="model.id"
              v-for="model in brandModels"
              v-bind:key="model.id"
              >{{ model.name }}</option
            >
          </select>
        </div>
        <p class="error-message-2" v-if="brand_model_id != ''">
          {{ brand_model_id }}
        </p>
        <div class="d-flex align-items-center pt-4">
          <div class="car-info">Body *</div>
          <select
            class="code-select px-3"
            v-model="modelTypeId"
            v-on:change="updateModelTypeId(modelTypeId)"
          >
            <option value="0">Select body type</option>
            <option
              v-bind:value="type.id"
              v-for="type in modelTypes"
              v-bind:key="type.id"
              >{{ type.name }}</option
            >
          </select>
        </div>
        <p class="error-message-2" v-if="model_type_id != ''">
          {{ model_type_id }}
        </p>
        <div class="d-flex align-items-center pt-4">
          <div class="car-info">Color *</div>
          <select
            class="code-select px-3"
            v-model="modelColorId"
            v-on:change="updateModelColorId(modelColorId)"
          >
            <option value="0">Select brand color</option>
            <option
              v-bind:value="color.id"
              v-for="color in modelColors"
              v-bind:key="color.id"
              >{{ color.name }}</option
            >
          </select>
        </div>
        <p class="error-message-2" v-if="model_color_id != ''">
          {{ model_color_id }}
        </p>
        <div class="d-flex justify-content-center pt-4">
          <button class="otp-btn" v-on:click="registerUser">Register</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  countries,
  allBrands,
  allCities,
  updateBrandId,
  updateBrandModelId,
  updateModelColorId,
  updateModelTypeId,
} from "../util/function";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "Register",
  data() {
    return {
      isSubmitted: false,
      selected: null,
      countryId: "",
      cityId: "",
      plateNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      ppassword: "",
      cpassword: "",
      phoneNumber: "",
      brandId: "0",
      brandModelId: "0",
      modelTypeId: "0",
      modelColorId: "0",
      userData: {},
      errorMessage: "",
      error: false,
      country_codes_id: "",
      city_codes_id: "",
      plate_number: "",
      first_name: "",
      last_name: "",
      Email: "",
      password: "",
      c_password: "",
      brand_id: "",
      brand_model_id: "",
      model_type_id: "",
      model_color_id: "",
      options: [],
      showPassword: false,
      showconfirmPassword: false,
      // error: {
      //   message: ""
      // }
    };
  },
  components: {
    vSelect,
  },
  computed: {
    countries,
    allBrands,
    cities() {
      if (this.countryId != "0" && this.countryId != "") {
        return this.$store.getters.countryCities.map((x) => {
          let temp = {
            value: x.id,
            label: x.code,
          };
          return this.options.push(temp);
        });
      } else {
        return false;
      }
    },
    brandModels() {
      if (this.brandId != "0") {
        return this.$store.getters.brandModels;
      } else {
        return false;
      }
    },
    modelTypes() {
      if (this.brandModelId != "0") {
        return this.$store.getters.modelTypes;
      } else {
        return false;
      }
    },
    modelColors() {
      if (this.modelTypeId != "0") {
        return this.$store.getters.getAllModelColors;
      } else {
        return false;
      }
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  beforeMount() {
    this.$store.dispatch("fetchSettings");
  },
  mounted() {
    this.phoneNumber = this.$store.state.userPhoneNumber;
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleconfirmShow() {
      this.showconfirmPassword = !this.showconfirmPassword;
    },
    flush() {
      this.options.splice(0);
      this.cityId = undefined;
    },
    changebrand() {
      this.brandModelId = "Select brand model";
    },
    allCities,
    updateBrandId,
    updateBrandModelId,
    updateModelTypeId,
    updateModelColorId,
    async registerUser() {
      this.validate();

      let obj = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.ppassword,
        c_password: this.cpassword,
        phone_number: this.phoneNumber,
        plate_number: this.plateNumber,
        country_codes_id: this.countryId,
        city_codes_id: this.cityId,
        brand_id: this.brandId,
        brand_model_id: this.brandModelId,
        model_type_id: this.modelTypeId,
        model_color_id: this.modelColorId,
        type: "user",
        profile_type: "public",
      };
      await axios
        .post(this.$paths + "register", obj)
        .then((response) => {
          if (response.data.code == 200) {
            this.userData = response.data.data;
            this.$store.commit("updateLoginUser", response.data.data);
            this.$store.commit("updatetoken", response.data.data.token);
            this.$store.commit("updateAuthentication", true);
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.data.token)
            );
            localStorage.setItem(
              "loginUserData",
              JSON.stringify(this.userData)
            );
            this.$router.push("home");
          } else if (response.data.code != 200) {
            (this.isSubmitted = true),
              (this.errorMessage = response.data.message);
            // this.errorMessage = result.message,
            this.error = true;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validate() {
      this.errorMessage = "";
      console.log("country", this.countryId);
      if (this.countryId === "") {
        this.country_codes_id = "Please select country code";
        this.error = true;
      } else {
        this.country_codes_id = "";
      }
      if (this.cityId === "") {
        this.city_codes_id = "Please select city code";
        this.error = true;
      } else {
        this.city_codes_id = "";
      }
      if (this.plateNumber === "" || this.plateNumber.length < 4) {
        this.plate_number = "Please enter at least 4 characters in car number";
        this.error = true;
      } else {
        this.plate_number = "";
      }
      if (this.firstName === "") {
        this.first_name = "Please enter first name";
        this.error = true;
      } else {
        this.first_name = "";
      }
      if (this.lastName === "") {
        this.last_name = "Please enter last name";
        this.error = true;
      } else {
        this.last_name = "";
      }
      if (this.email === "") {
        this.Email = "Please enter valid email address";
        this.error = true;
      } else {
        var checkEmail = this.validEmail(this.email);
        console.log("firstName => ", checkEmail);
        if (checkEmail == false) {
          this.Email = "Please enter valid email address";
          this.error = true;
        } else {
          this.Email = "";
        }
      }
      if (this.ppassword === "") {
        this.password = "Please enter more than 7 characters password";
        this.error = true;
      } else {
        this.password = "";
      }
      if (this.cpassword === "") {
        this.c_password = "Please make sure your Password and Re-type are same";
        this.error = true;
      } else {
        this.c_password = "";
      }
      if (this.cpassword != this.ppassword) {
        this.c_password = "Please make sure your Password and Re-type are same";
        this.error = true;
      } else {
        this.c_password = "";
      }
      if (this.brandId === "0") {
        this.brand_id = "Please select car brand";
        this.error = true;
      } else {
        this.brand_id = "";
      }
      if (this.brandModelId === "0") {
        this.brand_model_id = "Please select car model";
        this.error = true;
      } else {
        this.brand_model_id = "";
      }
      if (this.modelTypeId === "0") {
        this.model_type_id = "Please select car body type";
        this.error = true;
      } else {
        this.model_type_id = "";
      }
      if (this.modelColorId === "0") {
        this.model_color_id = "Please select car color";
        this.error = true;
      } else {
        this.model_color_id = "";
      }
    },
  },
};
</script>
