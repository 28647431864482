<template>
  <div id="UserProfile">
    <div class="right-content-section">
      <div class="profile-content">
        <img
          :src="'./images/loader.gif'"
          class="mr-2"
          v-if="isLoading == true && this.$store.state.status == 'LOADING'"
          style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%); z-index: 99999 "
        />
        <div>
          <b-modal
            ref="comment-modal"
            hide-footer
            centered
            title="Verification"
          >
            <div class="d-block text-center">
              <h5>{{ $t("LBL_DELETE_COMMENT") }}</h5>
            </div>
            <div class="d-flex align-items-center justify-content-center pt-3">
              <b-button
                class="mr-1"
                variant="outline-danger"
                @click="deletefunction()"
                >{{ $t("LBL_YES") }}</b-button
              >
              <b-button variant="outline-warning" @click="hideModal">{{
                $t("LBL_NO")
              }}</b-button>
            </div>
          </b-modal>
        </div>
        <div class="profile-user-name user-name">
          {{ userProfileData.first_name }} {{ userProfileData.last_name }}
        </div>
        <!-- Header Start -->
        <div class="position-relative">
          <div class="cover-img" @click="showPopup">
            <span
              v-if="
                userProfileData.cover_image && userProfileData.cover_image != ''
              "
            >
              <img
                :src="userProfileData.cover_image"
                alt="cover image"
                id="myCover"
              />
            </span>
            <span v-else>
              <img
                :src="'./images/dummy_cover_img.jpg'"
                alt="cover image"
                id="myCover"
              />
            </span>
          </div>
          <div id="coverModal" class="post-detail-modal">
            <span class="close" @click="hidePopup">&times;</span>
            <img class="modal-content" id="cover01" />
          </div>
          <div class="profile-img" @click="showPopup">
            <span
              v-if="
                userProfileData.profile_image &&
                  userProfileData.profile_image != ''
              "
            >
              <img
                :src="userProfileData.profile_image"
                alt="profile image"
                id="myProfile"
              />
            </span>
            <span v-else>
              <img
                :src="'./images/dummy_user_img.png'"
                alt="profile image"
                id="myProfile"
              />
            </span>
          </div>
          <div id="profileModal" class="post-detail-modal">
            <span class="close" @click="hidePopup">&times;</span>
            <img class="modal-content" id="profile01" />
          </div>
          <div class="d-flex user-likes-btn">
            <button
              class="pending-btn mr-1"
              v-if="userProfileData.id == userID.id"
              v-b-modal.dislike-users
            >
              <i class="fa fa-thumbs-down pr-1"></i
              >{{ userProfileData.profile_dislikes }}
              {{ $t("LBL_DISLIKE_TXT") }}
            </button>
            <button
              class="pending-btn mr-1"
              v-else
              @click="profileLikeDislike('dislike')"
            >
              <i class="fa fa-thumbs-down pr-1"></i
              >{{ userProfileData.profile_dislikes }}
              {{ $t("LBL_DISLIKE_TXT") }}
            </button>

            <button
              class="follow-btn"
              v-if="userProfileData.id == userID.id"
              v-b-modal.like-users
            >
              <i class="fa fa-thumbs-up pr-1" style="color:#fff"></i
              >{{ userProfileData.profile_likes }} {{ $t("LBL_LIKE_TXT") }}
            </button>
            <button
              class="follow-btn"
              v-else
              @click="profileLikeDislike('like')"
            >
              <i class="fa fa-thumbs-up pr-1" style="color:#fff"></i
              >{{ userProfileData.profile_likes }} {{ $t("LBL_LIKE_TXT") }}
            </button>
          </div>
        </div>
        <!-- Header Start -->

        <div class="p-15">
          <div class="d-flex followers scroll-followers">
            <div
              class="mr-2"
              v-for="follower in userProfileData.followers"
              v-bind:key="follower.id"
            >
              <span
                v-if="follower.profile_image && follower.profile_image != ''"
              >
                <img :src="follower.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between pt-2">
            <div>
              {{ userProfileData.profile_likes }} {{ $t("LBL_DRIVER_LIKE") }}
            </div>
            <a
              href="javascript:void(0)"
              v-b-modal.followers-modal
              style="color:#6D6E72"
              >{{ $t("LBL_SEE_ALL_FOLLOWERS") }}</a
            >
          </div>
        </div>
      </div>
      <div class="profile-content mt-3">
        <div class="p-15">
          <div class="text-right">
            <button
              v-if="userProfileData.id == userID.id"
              v-b-modal.edit-profile
              @click="editProfileClick"
              class="border-0 p-0"
            >
              <i class="fa fa-pencil edit-profile"></i>
            </button>
          </div>
          <b-modal id="edit-profile" :title="$t('LBL_EDIT_PROFILE')">
            <b-form-group :label="$t('LBL_COVER_IMAGE')">
              <div class="cover-img position-relative">
                <vue-anka-cropper
                  v-model="coverImage"
                  :options="{
                    aspectRatio: 1,
                    closeOnSave: true,
                    cropArea: 'box',
                    croppedHeight: 400,
                    croppedWidth: 400,
                    cropperHeight: false,
                    dropareaMessage: '',
                    frameLineDash: [5, 3],
                    frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                    handleFillColor: 'rgba(255, 255, 255, 0.2)',
                    handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                    handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                    handleSize: 10,
                    handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                    layoutBreakpoint: 850,
                    maxCropperHeight: 768,
                    maxFileSize: 8000000,
                    overlayFill: 'rgba(0, 0, 0, 0.5)',
                    previewOnDrag: true,
                    previewQuality: 0.65,
                    resultQuality: 0.8,
                    resultMimeType: 'image/jpeg',
                    selectButtonLabel: 'Select Image',
                    showPreview: true,
                    skin: 'light',
                    uploadData: {},
                    uploadTo: false,
                  }"
                ></vue-anka-cropper>

                <span
                  v-if="
                    userProfileData.cover_image &&
                      userProfileData.cover_image != ''
                  "
                >
                  <img
                    :src="
                      coverImageUrl == null
                        ? userProfileData.cover_image
                        : coverImageUrl
                    "
                    alt="cover image"
                    id="myCover"
                  />
                </span>
                <span v-else>
                  <img
                    :src="'./images/dummy_cover_img.jpg'"
                    alt="cover image"
                    id="myCover"
                  />
                </span>
                <div class="edit-cover-icon">
                  <label class="mb-0 edit-profile-fa"
                    ><i class="fa fa-pencil"></i>
                    <input
                      type="file"
                      @change="onCoverChange"
                      class="d-none"
                      accept="image/*"
                    />
                  </label>
                </div>
              </div>
            </b-form-group>

            <b-form-group :label="$t('LBL_PROFILE_IMAGE')">
              <div class="position-relative d-flex justify-content-center">
                <span>
                  <img
                    :src="
                      profileImageUrl == null
                        ? userProfileData.profile_image
                        : profileImageUrl
                    "
                    alt="profile image"
                    id="myProfile"
                    class="w-100"
                  />
                </span>
                <div class="edit-profile-icon">
                  <label class="mb-0 edit-profile-fa"
                    ><i class="fa fa-pencil"></i>
                    <input
                      type="file"
                      @change="onProfileChange"
                      class="d-none"
                      accept="image/*"
                    />
                  </label>
                </div>
              </div>
            </b-form-group>

            <textarea
              class="w-100 my-2 p-2"
              rows="5"
              :placeholder="$t('LBL_ABOUT_TXT')"
              v-model="about"
            ></textarea>

            <div class="d-flex pb-2">
              <div class="bg-blue mr-1">
                <div class="about-circle"></div>
                <div class="text-center">
                  <select
                    class="about-select"
                    v-model="countryId"
                    v-on:change="selectedCountryCities(countryId)"
                  >
                    <option
                      :value="country.id"
                      v-for="country in countries"
                      :key="country.id"
                      >{{ country.code }}</option
                    >
                  </select>
                </div>
              </div>
              <div style="width:140px;" class="mr-1">
                <select class="code-select city-code-select" v-model="cityId">
                  <option value="0">City Code</option>
                  <option
                    v-bind:value="city.id"
                    v-for="city in citiesData"
                    v-bind:key="city.id"
                    >{{ city.code }}</option
                  >
                </select>
              </div>
              <div style="width:260px">
                <input
                  type="text"
                  v-model="plateNumber"
                  class="code-select text-center"
                  placeholder="Number"
                />
              </div>
            </div>
            <div class="d-flex pt-2">
              <b-form-group
                :label="$t('LBL_FIRST_NAME_TXT')"
                class="text-center mr-2"
                style="width:50%"
              >
                <b-form-input
                  type="text"
                  :placeholder="$t('LBL_FIRST_NAME_TXT')"
                  v-model="firstName"
                  class="edit-profile-input"
                  style="box-shadow:0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('LBL_LAST_NAME_TXT')"
                class="text-center"
                style="width:50%"
              >
                <b-form-input
                  type="text"
                  :placeholder="$t('LBL_LAST_NAME_TXT')"
                  v-model="lastName"
                  class="edit-profile-input"
                  style="box-shadow:0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="pt-2">
              <b-form-group label="Email">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      ><img :src="'./images/envelope.png'" class="w-100" alt=""
                    /></b-input-group-text>
                  </template>
                  <b-form-input
                    type="email"
                    placeholder="Email"
                    v-model="email"
                    class="text-left edit-profile-input"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="pt-2">
              <b-form-group :label="$t('LBL_MOBILE_NUMBER_TXT')">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      ><img
                        :src="'./images/mobile.png'"
                        class="w-100"
                        style="height:56px"
                        alt=""
                      />
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="tel"
                    readonly
                    :placeholder="$t('LBL_MOBILE_NUMBER_TXT')"
                    v-model="phoneNumber"
                    class="text-left edit-profile-input"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>

            <div>
              <span class="line">
                <h2>
                  <span>{{ $t("LBL_PERSONAL_INFO") }}</span>
                </h2>
              </span>
            </div>
            <div class="d-flex align-items-center pt-4">
              <div class="car-info">{{ $t("LBL_GENDER") }}</div>
              <select class="code-select px-3" v-model="userGender">
                <option value="0">Select gender</option>
                <option
                  v-bind:value="gender.name"
                  v-for="gender in genderArray"
                  v-bind:key="gender.name"
                >
                  {{ gender.name.toUpperCase() }}</option
                >
              </select>
            </div>
            <div class="d-flex align-items-center pt-4">
              <div class="car-info">{{ $t("LBL_MARITAL_STATUS") }}</div>
              <select class="code-select px-3" v-model="userMartialStatus">
                <option value="0">Select marital status</option>
                <option
                  v-bind:value="status.name"
                  v-for="status in MartialStatusArray"
                  v-bind:key="status.name"
                >
                  {{ status.name.toUpperCase() }}</option
                >
              </select>
            </div>
            <div class="profileTags">
              <label for="tags-basic1" class="car-info">{{
                $t("LBL_FAVOURITE_FOOD")
              }}</label>
              <b-form-tags
                input-id="tags-basic1"
                v-model="favoriteFoods"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic2" class="car-info">{{
                $t("LBL_FAVOURITE_DRINK")
              }}</label>
              <b-form-tags
                input-id="tags-basic2"
                v-model="favoriteDrinks"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic3" class="car-info">{{
                $t("LBL_FAVOURITE_MUSIC")
              }}</label>
              <b-form-tags
                input-id="tags-basic3"
                v-model="favoriteMusics"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic4" class="car-info">{{
                $t("LBL_FAVORITE_COLOR")
              }}</label>
              <b-form-tags
                input-id="tags-basic4"
                v-model="favoriteColors"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic5" class="car-info">{{
                $t("LBL_FAVOURITE_BOOK")
              }}</label>
              <b-form-tags
                input-id="tags-basic5"
                v-model="favoriteBooks"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic6" class="car-info">{{
                $t("LBL_FAVOURITE_MOVIE")
              }}</label>
              <b-form-tags
                input-id="tags-basic6"
                v-model="favoriteMovies"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic7" class="car-info">{{
                $t("LBL_FAVOURITE_VACATION")
              }}</label>
              <b-form-tags
                input-id="tags-basic7"
                v-model="favoriteVacations"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic8" class="car-info">{{
                $t("LBL_FAVOURITE_MOTTO")
              }}</label>
              <b-form-tags
                input-id="tags-basic8"
                v-model="favoriteMottos"
              ></b-form-tags>
            </div>
            <div class="profileTags">
              <label for="tags-basic9" class="car-info">{{
                $t("LBL_FAVOURITE_HOBBY")
              }}</label>
              <b-form-tags
                input-id="tags-basic9"
                v-model="favoriteHobbies"
              ></b-form-tags>
            </div>

            <div>
              <span class="line">
                <h2>
                  <span>{{ $t("LBL_CAR_INFO_TXT") }}</span>
                </h2>
              </span>
            </div>
            <div class="d-flex align-items-center pt-4">
              <div class="car-info">{{ $t("LBL_BRAND_TXT") }}</div>
              <select
                class="code-select px-3"
                v-model="brandId"
                v-on:change="selectedBrandModel(brandId)"
              >
                <option value="0">{{ $t("LBL_SELECT_BRAND_NAME_TXT") }}</option>
                <option
                  v-bind:value="brand.id"
                  v-for="brand in allBrands"
                  v-bind:key="brand.id"
                  >{{ brand.name }}</option
                >
              </select>
            </div>
            <div class="d-flex align-items-center pt-4">
              <div class="car-info">{{ $t("LBL_MODEL_TXT") }}</div>
              <select
                class="code-select px-3"
                v-model="brandModelId"
                v-on:change="selectedBrandModelType(brandModelId)"
              >
                <option value="0">{{ $t("LBL_SELECT_MODEL_NAME_TXT") }}</option>
                <option
                  v-bind:value="model.id"
                  v-for="model in allBrandModels"
                  v-bind:key="model.id"
                  >{{ model.name }}
                </option>
              </select>
            </div>
            <div class="d-flex align-items-center pt-4">
              <div class="car-info">{{ $t("LBL_BODY_TXT") }}</div>
              <select
                class="code-select px-3"
                v-model="modelTypeId"
                v-on:change="updateModelTypeId(modelTypeId)"
              >
                <option value="0">{{ $t("LBL_SELECT_BODY_TYPE") }}</option>
                <option
                  v-bind:value="type.id"
                  v-for="type in allBrandModelType"
                  v-bind:key="type.id"
                  >{{ type.name }}
                </option>
              </select>
            </div>
            <div class="d-flex align-items-center pt-4">
              <div class="car-info">{{ $t("LBL_COLOR_TXT") }}</div>
              <select
                class="code-select px-3"
                v-model="modelColorId"
                v-on:change="updateModelColorId(modelColorId)"
              >
                <option value="0">{{ $t("LBL_SELECT_COLOR_OF_CAR") }}</option>
                <option
                  v-bind:value="color.id"
                  v-for="color in modelColors"
                  v-bind:key="color.id"
                  >{{ color.name }}
                </option>
              </select>
            </div>
            <div class="d-flex justify-content-center pt-3">
              <b-button
                class="create-event-btn"
                style="width:70%"
                v-on:click.prevent="updateUser"
                @click="$bvModal.hide('edit-profile')"
                >{{ $t("LBL_UPDATE_PROFILE_TXT") }}</b-button
              >
            </div>
          </b-modal>
          <div style="font-size:22px;font-weight:600;">
            {{ $t("LBL_ABOUT_TXT") }}
          </div>
          <div>
            {{ userProfileData.about }}
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="d-flex justify-content-center">
          <b-modal id="post-modal" centered :title="this.postTitle">
            <div class="d-flex">
              <div class="create-post-img">
                <span v-if="this.userProfile != ''">
                  <img :src="this.userProfile" class="search-img mr-1" alt="" />
                </span>
                <span v-else>
                  <img
                    src="/images/dummy_user_img.png"
                    class="search-img mr-1"
                    alt=""
                  />
                </span>
              </div>
              <div class="ml-2">
                <div class="d-flex align-items-center number-plate">
                  <div class="d-flex outer-div">
                    <div class="circle"></div>
                    <div>{{ this.userAParseValue.country_code }}</div>
                  </div>
                  <div class="d-flex justify-content-center w-65">
                    <div>
                      {{ this.userAParseValue.city_code
                      }}{{ this.userAParseValue.plate_number }}
                    </div>
                  </div>
                </div>
                <div class="user-name pt-1">
                  {{ this.userAParseValue.first_name }}
                  {{ this.userAParseValue.last_name }}
                </div>
              </div>
            </div>
            <div class="create-post mt-2 mb-2">
              <input
                type="text"
                style="width:100%;display: none"
                placeholder="Enter Location Here"
                id="locationInput"
              />
              <textarea
                :style="getColor(background_color)"
                id="textBox"
                v-model="text"
                :placeholder="$t('LBL_WHATS_IN_YOUR_MIND')"
                row="10"
                column="10"
              ></textarea>
            </div>
            <div id="imagePreview" class="mb-3">
              <div v-if="isEditPost == false">
                <img v-if="imageUrl" :src="imageUrl" />
                <button
                  v-if="imageRemoveBtn"
                  class="cross-btn"
                  @click="removeImg"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div v-else>
                <img v-if="imageUrl" :src="imageUrl" />
                <button
                  v-if="imageRemoveBtn"
                  class="cross-btn"
                  @click="removeImg"
                  v-on:click="postimageDel"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
            <div id="videoPreview" class="mb-3">
              <div v-if="isEditPost == false">
                <video
                  v-if="videoUrl"
                  :src="videoUrl"
                  type="mp4"
                  loop
                  controls
                ></video>
                <button
                  v-if="videoRemoveBtn"
                  class="cross-btn"
                  @click="removeVideo"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div v-else>
                <video
                  v-if="videoUrl"
                  :src="videoUrl"
                  type="mp4"
                  loop
                  controls
                ></video>
                <button
                  v-if="videoRemoveBtn"
                  class="cross-btn"
                  @click="removeVideo"
                  v-on:click="postimageDel"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
            <div id="locationPreview" class="mb-3" v-if="comp_address">
              <p id="location">{{ comp_address }}</p>
            </div>
            <div id="googlemap preview">
              <googlemaps-map
                :center="{
                  lat: parseFloat(latitude),
                  lng: parseFloat(longitude),
                }"
                :zoom="13"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                }"
              >
                <googlemaps-marker
                  :position="{
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude),
                  }"
                />
              </googlemaps-map>
            </div>
            <div class="d-flex add-post">
              <div>
                <i class="fa fa-map-marker mr-1"></i>
                <label class="mb-0 mr-1"
                  ><i class="fa fa-picture-o"></i>
                  <input
                    type="file"
                    @change="onFileChange"
                    class="d-none"
                    accept="image/*"
                  />
                </label>
                <label class="mb-0 mr-1"
                  ><i class="fa fa-video-camera"></i>
                  <input
                    type="file"
                    @change="onVideoChange"
                    class="d-none"
                    accept="video/*"
                  />
                </label>
              </div>
              <div class="d-flex">
                <img
                  id="changeColorBG"
                  class="mr-1"
                  :src="'./images/yellow.png'"
                  alt=""
                  v-on:click="changeColor('#FDAB40')"
                />
                <img
                  id="changeColorBG"
                  class="mr-1"
                  :src="'./images/green.png'"
                  alt=""
                  v-on:click="changeColor('#88A80D')"
                />
                <img
                  id="changeColorBG"
                  class="mr-1"
                  :src="'./images/red.png'"
                  alt=""
                  v-on:click="changeColor('#F73265')"
                />
                <img
                  id="changeColorBG"
                  class="mr-1"
                  :src="'./images/grey.png'"
                  alt=""
                  v-on:click="changeColor('#6D6E72')"
                />
                <img
                  id="changeColorBG"
                  class="mr-1"
                  :src="'./images/silver.png'"
                  alt=""
                  v-on:click="changeColor('#D8D8D8')"
                />
              </div>
            </div>
            <button
              @click="createPost()"
              type="button"
              class="post-btn mt-3"
              v-if="isEditPost == false"
            >
              Post <i style="color: white" v-bind:class="changeClass()"></i>
            </button>
            <button
              type="button"
              @click="editpost()"
              class="post-btn mt-3"
              v-else
            >
              Update Post<i
                style="color: white"
                v-bind:class="changeClass()"
              ></i>
            </button>
          </b-modal>
        </div>
      </div>
      <!-- People like post modal -->
      <b-modal
        id="invite-friends"
        centered
        :title="$t('LBL_PEOPLE_WHO_REACTED')"
      >
        <div
          class="search-user-detail d-flex justify-content-between"
          v-for="inviteUser in likedUsers"
          v-bind:key="inviteUser.id"
        >
          <div class="d-flex">
            <div class="user-search-profile">
              <span v-if="inviteUser.profile_image != ''">
                <img :src="inviteUser.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
            <div class="w-170">
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ inviteUser.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>{{ inviteUser.plate_number }}</div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ inviteUser.first_name }} {{ inviteUser.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <div class="custom-content">
        <div v-for="(post, index) in userProfileData.posts" :key="post.id">
          <div class="listing">
            <div class="user-detail d-flex justify-content-between">
              <div class="d-flex">
                <div class="listing-profile">
                  <span
                    v-if="
                      post.user.profile_image && post.user.profile_image != ''
                    "
                  >
                    <img :src="post.user.profile_image" alt="" />
                  </span>
                  <span v-else>
                    <img :src="'./images/dummy_user_img.png'" alt="" />
                  </span>
                </div>
                <div class="ml-3">
                  <div class="d-flex align-items-center number-plate">
                    <div class="d-flex outer-div">
                      <div class="circle"></div>
                      <div>{{ post.user.country_code }}</div>
                    </div>
                    <div class="d-flex justify-content-center w-65">
                      <div>{{ post.user.plate_number }}</div>
                    </div>
                  </div>
                  <div class="user-name">
                    {{ post.user.first_name + " " + post.user.last_name }}
                  </div>
                  <div class="post-date">
                    {{
                      moment
                        .utc(post.created_at)
                        .local()
                        .format("d/MM/YYYY h:mm a")
                    }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-start event-listing-dots">
                <!-- <img :src="'./images/dots.png'" alt="" /> -->
                <b-dropdown text="..." right no-caret class="edit-dropdown">
                  <b-dropdown-item @click="EditPost(post)" v-b-modal.post-modal
                    >Edit</b-dropdown-item
                  >
                  <b-dropdown-item @click="DeletePost(post)"
                    >Delete</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
            <div class="description" v-if="post.post_type != 'text'">
              <vue-show-more-text
                :text="post.text"
                :lines="4"
                additional-container-css="padding: 0;"
              />
            </div>

            <!-- //Image  -->
            <div v-if="post.post_type == 'image'">
              <router-link
                :to="{ name: 'PostDetail', params: { id: post.id } }"
              >
                <img
                  :src="post.medium"
                  v-bind:id="'myImg' + post.id"
                  alt=""
                  class="w-100"
                />
              </router-link>
            </div>

            <!-- //For Video -->
            <div v-if="post.post_type == 'video'">
              <router-link
                :to="{ name: 'PostDetail', params: { id: post.id } }"
              >
                <video
                  v-bind:id="'myVideo' + post.id"
                  controls
                  width="100%"
                  height="264"
                  preload="auto"
                  :src="post.medium"
                  type="video/mp4"
                ></video>
              </router-link>
            </div>

            <!-- //For Status -->
            <div v-if="post.post_type == 'text'">
              <router-link
                :to="{ name: 'PostDetail', params: { id: post.id } }"
              >
                <div
                  class="status minHeight"
                  :style="getColor(post.background_color)"
                >
                  <vue-show-more-text
                    :text="post.text"
                    :lines="4"
                    additional-container-css="padding: 0;"
                    additional-content-css="font-size:16px;"
                    additional-content-expanded-css="font-size:16px;"
                    additional-anchor-css="font-size: 16px;"
                  />
                </div>
              </router-link>
            </div>

            <!-- //For Location -->
            <div v-if="post.post_type == 'location'">
              <router-link
                :to="{ name: 'PostDetail', params: { id: post.id } }"
              >
                <div class="">
                  <googlemaps-map
                    :center="{
                      lat: parseFloat(post.lat),
                      lng: parseFloat(post.lng),
                    }"
                    :zoom="13"
                    :options="{
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUi: false,
                    }"
                  >
                    <googlemaps-marker
                      :position="{
                        lat: parseFloat(post.lat),
                        lng: parseFloat(post.lng),
                      }"
                    />
                  </googlemaps-map>
                  <div class="address">{{ post.comp_address }}</div>
                </div>
              </router-link>
            </div>
            <!-- <div @click="showPopup" v-if="post.post_type=='image'">
            <img
              :src="'./images/map.png'"
              id="myLocation"
              alt=""
              class="w-100"
            />
          </div> -->

            <div
              class="likes d-flex align-items-center justify-content-between"
            >
              <div class="comments">
                {{ post.comments.length }} {{ $t("LBL_COMMENTS_TXT") }}
              </div>
              <div
                class="comments pl-2"
                v-b-modal.invite-friends
                @click="postUserList(post)"
              >
                {{ post.liked_users.length }} {{ $t("LBL_LIKE_TXT") }}
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between px-1">
              <div class="user-name down">
                <img :src="'./images/comment.png'" alt="" class="mr-2" />{{
                  $t("LBL_COMMENT_TXT")
                }}
              </div>

              <b-button
                v-if="post.user.id != userID.id"
                @click="getReportReaasons(post.id)"
                class="user-name down report-btn"
                ><i class="fa fa-flag pr-2"></i
                >{{ $t("LBL_REPORTS_TXT") }}</b-button
              >

              <div
                class="user-name down"
                v-bind:style="selectedCursor"
                :class="{ like: !showClicked(post) }"
                @click="likeToggle(post, index)"
              >
                <img :src="'./images/like.png'" alt="" class="mr-2" />{{
                  $t("LBL_LIKE_TXT")
                }}
              </div>
            </div>
            <hr class="mt-1" />
            <div v-if="checkEmptyArray(post.comments)">
              <div
                class="d-flex comments align-items-start"
                v-for="(comment, index) in reverseItems(post.comments)"
                :key="comment.id"
              >
                <input
                  type="text"
                  v-bind:id="'modalcommentButton' + index"
                  style="display:none"
                />
                <div>
                  <span v-if="comment.user.profile_image != ''">
                    <img
                      :src="comment.user.profile_image"
                      class="mr-2 img-circle"
                    />
                  </span>
                  <span v-else>
                    <img
                      :src="'./images/dummy_user_img.png'"
                      class="mr-2 img-circle"
                      alt=""
                    />
                  </span>
                </div>
                <div class="other-comments">
                  <div class="user-name .comment-user">
                    {{ comment.user.first_name }} {{ comment.user.last_name }}
                  </div>
                  <div
                    aria-disabled="disabled"
                    v-bind:id="'textdiv' + index"
                    class="textdiv"
                  >
                    {{ comment.comment }}
                  </div>
                  <div>
                    <textarea
                      name=""
                      class="input-for-comment-append"
                      v-on:keyup.enter="
                        updateComment(reverseItems(post.comments), index)
                      "
                      v-bind:id="'input' + index"
                      style="display:none; width:250px;"
                    ></textarea>
                  </div>
                  <div v-bind:id="'buttondiv' + index" class="buttondiv">
                    <span v-if="userID.id == comment.user.id">
                      <i
                        class="fa fa-pencil comment-edit"
                        @click="appendUpdateComment(comment, index)"
                      ></i>
                    </span>
                    <span
                      v-if="
                        userID.id == comment.user.id ||
                          userID.id == post.user.id
                      "
                    >
                      <i
                        class="fa fa-trash comment-delete"
                        @click="deleteVerificationComment(comment)"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex comments align-items-start">
              <div>
                <span v-if="userID.profile_image != ''">
                  <img :src="userID.profile_image" class="mr-2" />
                </span>
                <span v-else>
                  <img
                    :src="'./images/dummy_user_img.png'"
                    class="mr-2"
                    alt=""
                  />
                </span>
              </div>
              <input
                class="status-search w-100"
                type="text"
                v-bind:id="'listcomment' + post.id"
                @keyup="showCommentButton(post, 'list')"
                :placeholder="$t('LBL_WRITE_COMMENTS_TXT')"
              />
              <i
                class="fa fa-paper-plane comment-send"
                v-bind:id="'listcommentButton' + post.id"
                @click="postComment(post, index, 'list')"
                style="display:none"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="followers-modal"
        :title="$t('LBL_ALL_FOLLOWERS')"
        centered
        scrollable
      >
        <div
          class="search-user-detail d-flex justify-content-between"
          v-for="inviteUser in userProfileData.followers"
          v-bind:key="inviteUser.id"
        >
          <div class="d-flex">
            <div class="user-search-profile">
              <span v-if="inviteUser.profile_image != ''">
                <img :src="inviteUser.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
            <div>
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ inviteUser.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>{{ inviteUser.plate_number }}</div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ inviteUser.first_name }} {{ inviteUser.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="dislike-users" title="Dislikes" centered scrollable>
        <div
          class="search-user-detail d-flex justify-content-between"
          v-for="dislikeUser in userProfileData.profile_dislike_users"
          v-bind:key="dislikeUser.id"
        >
          <div class="d-flex">
            <div class="user-search-profile">
              <span v-if="dislikeUser.profile_image != ''">
                <img :src="dislikeUser.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
            <div>
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ dislikeUser.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>{{ dislikeUser.plate_number }}</div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ dislikeUser.first_name }} {{ dislikeUser.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="like-users" title="Likes" centered scrollable>
        <div
          class="search-user-detail d-flex justify-content-between"
          v-for="likeUser in userProfileData.profile_like_users"
          v-bind:key="likeUser.id"
        >
          <div class="d-flex">
            <div class="user-search-profile">
              <span v-if="likeUser.profile_image != ''">
                <img :src="likeUser.profile_image" alt="" />
              </span>
              <span v-else>
                <img :src="'./images/dummy_user_img.png'" alt="" />
              </span>
            </div>
            <div>
              <div class="d-flex align-items-center number-plate">
                <div class="d-flex outer-div">
                  <div class="circle"></div>
                  <div>{{ likeUser.country_code }}</div>
                </div>
                <div class="d-flex justify-content-center w-65">
                  <div>{{ likeUser.plate_number }}</div>
                </div>
              </div>
              <div class="user-name pt-1">
                {{ likeUser.first_name }} {{ likeUser.last_name }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal id="report-modal" centered :title="$t('LBL_REPORTS_TXT')">
        <div class="problem pb-2">
          {{ $t("LBL_SELECT_REASONS_FOR_REPORTING") }}
        </div>
        <div
          class="problem-content"
          v-for="reason in reportReasons"
          v-bind:key="reason.id"
          v-on:click="submitReason(reason.id)"
        >
          {{ reason.reason }}
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import vueAnkaCropper from "vue-anka-cropper";
import axios from "axios";
import Swal from "sweetalert2";
import vueShowMoreText from "vue-show-more-text";
import {
  onFileChange,
  onProfileChange,
  onCoverChange,
  countries,
  allBrands,
  allCities,
  updateBrandId,
  updateBrandModelId,
  updateModelColorId,
  updateModelTypeId,
  hidePopup,
  showPopup,
  changeColor,
  onVideoChange,
  removeVideo,
  removeImg,
} from "../util/function";
export default {
  name: "UserProfile",
  props: ["profileID"],
  data() {
    return {
      isLoading: false,
      coverImageUrl: null,
      coverImage: null,
      profileImageUrl: null,
      profileImage: null,
      userProfileData: "",
      userID: JSON.parse(localStorage.getItem("loginUserData")),
      imageUrl: null,
      removeBtn: false,
      countryId: "",
      cityId: "0",
      plateNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      ppassword: "",
      cpassword: "",
      phoneNumber: "",
      brandId: "0",
      brandModelId: "0",
      modelTypeId: "0",
      modelColorId: "0",
      about: "",
      delcomment: null,
      modalData: {},
      selectedCursor: "pointer-events:auto",
      selectedPostForReport: "",
      citiesData: [],
      allBrandModels: [],
      allBrandModelType: [],
      likedUsers: [],
      genderArray: [
        {
          id: 0,
          name: "male",
        },
        {
          id: 1,
          name: "female",
        },
        {
          id: 2,
          name: "unspecified",
        },
      ],
      MartialStatusArray: [],
      userGender: 0,
      userMartialStatus: "0",
      favoriteFoods: [],
      favoriteDrinks: [],
      favoriteMusics: [],
      favoriteColors: [],
      favoriteBooks: [],
      favoriteMovies: [],
      favoriteVacations: [],
      favoriteMottos: [],
      favoriteHobbies: [],
      postTitle: "Create Post",
      imageRemoveBtn: false,
      videoRemoveBtn: false,
      postimageUrl: null,
      image: null,
      postType: "",
      video: null,
      videoUrl: null,
      text: "",
      background_color: "",
      isEditPost: false,
      userProfile: null,
      userAParseValue: "",
      reportReasons: [],
      latitude: "",
      longitude: "",
      comp_address: "",
    };
  },

  computed: {
    countries,
    allBrands,
    cities() {
      if (this.countryId != "0" || this.countryId != "") {
        return this.$store.getters.countryCities;
      } else {
        return false;
      }
    },
    getUserDetail() {
      return this.$store.state.loginUserData;
    },
    brandModels() {
      if (this.brandId != "0") {
        return this.$store.getters.brandModels;
      } else {
        return false;
      }
    },
    modelTypes() {
      if (this.brandModelId != "0") {
        return this.$store.getters.modelTypes;
      } else {
        return false;
      }
    },
    modelColors() {
      if (this.modelTypeId != "0") {
        return this.$store.getters.getAllModelColors;
      } else {
        return false;
      }
    },
  },
  components: {
    vueAnkaCropper,
    vueShowMoreText,
  },

  methods: {
    onFileChange,
    onCoverChange,
    onProfileChange,
    showPopup,
    hidePopup,
    allCities,
    updateBrandId,
    updateBrandModelId,
    updateModelTypeId,
    updateModelColorId,
    changeColor,
    onVideoChange,
    removeVideo,
    removeImg,

    errorAlert(message) {
      Swal.fire({
        title: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    successAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    getColor(color) {
      if (color) {
        return "background-color:" + color;
      }

      return "background: #C8A860";
    },
    changeClass() {
      if (this.postButtonLoader == true) {
        return "fa fa-spinner fa-spin";
      }
    },
    async DeletePost(post) {
      const data = post.id;
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      this.userAParseValue = userAParseValue;
      this.userProfile = userAParseValue.profile_image;
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .delete(this.$paths + "posts/" + data, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            location.reload();
            this.successAlert(response.data.message);
            Object.assign(this.$data, this.$options.data());
          } else {
            this.getPostList();
            this.errorAlert(response.data.message);
          }
        });
    },

    async EditPost(post) {
      console.log(post);
      this.postTitle = "Edit Post";
      this.imageUrl = "";
      this.videoUrl = "";
      this.imageRemoveBtn = false;
      this.videoRemoveBtn = false;
      this.postType = post.post_type;
      this.text = post.text;
      this.background_color = post.background_color;
      this.editpostID = post.id;
      if (this.postType == "image") {
        this.imageRemoveBtn = true;
        this.imageUrl = post.medium;
        this.background_color = "white";
      }
      if (this.postType == "video") {
        this.videoRemoveBtn = true;
        this.videoUrl = post.medium;
        this.background_color = "white";
      }
      if (this.postType == "location") {
        this.comp_address = post.comp_address;
        this.latitude = post.lat;
        this.longitude = post.lng;
      }
      this.isEditPost = true;
      this.$bvModal.show("post-modal");
    },
    async editpost() {
      const newFormData = new FormData();
      this.postButtonLoader = true;
      // if(this.image==null && this.video==null) {
      //   this.postType='text';
      // }
      if (this.image) {
        this.postType = "image";
        newFormData.append("medium", this.image);
      }
      if (this.video) {
        this.postType = "video";
        newFormData.append("medium", this.video);
      }
      if (this.comp_address) {
        this.postType = "location";
        newFormData.append("comp_address", this.locationdiv);
        newFormData.append("lat", this.latitude);
        newFormData.append("lng", this.longitude);
      }
      this.userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      newFormData.append("text", this.text);
      newFormData.append("background_color", this.background_color);
      newFormData.append("user_id", this.userAParseValue.id);
      newFormData.append("post_type", this.postType);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      var result = await axios
        .post(this.$paths + "posts/update/" + this.editpostID, newFormData, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      console.log(result);
      if (result.code == 200) {
        location.reload();
      }
    },
    //post image delete
    async postimageDel() {
      const newFormData = new FormData();
      this.userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      newFormData.append("id", this.editpostID);
      newFormData.append("user_id", this.userAParseValue.id);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      await axios
        .post(this.$paths + "postimageDel", newFormData, {
          headers,
        })
        .then((response) => {
          this.background_color = response.data.data.background_color;
          this.postType = response.data.data.post_type;
        });
      // if (result.code == 200) {
      //   // location.reload();
      // }
    },
    //post like user list
    async postUserList(post) {
      const data = {
        post_id: post.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "postLikedUsers", data, {
          headers,
        })
        .then((response) => {
          this.likedUsers = response.data.data;
        });
    },
    //append comment in userprofile
    hideUnhideclass(className, displayState) {
      var elements = document.getElementsByClassName(className);

      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = displayState;
      }
    },
    appendUpdateComment(object, index) {
      this.hideUnhideclass("input-for-comment-append", "none");
      this.hideUnhideclass("textdiv", "flex");
      this.hideUnhideclass("buttondiv", "flex");
      var showInput = document.getElementById("input" + index);
      showInput.style.display = "flex";
      var buttondiv = document.getElementById("buttondiv" + index);
      buttondiv.style.display = "none";
      var div = document.getElementById("textdiv" + index);
      div.style.display = "none";
      showInput.value = object.comment;
    },
    async updateComment(object, index) {
      var showInput = document.getElementById("input" + index);
      var div = document.getElementById("textdiv" + index);
      var buttondiv = document.getElementById("buttondiv" + index);
      const data = {
        comment: showInput.value,
        comment_id: object[index].id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      var result = await axios
        .post(this.$paths + "updateComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        div.style.display = "flex";
        buttondiv.style.display = "flex";
        showInput.style.display = "none";
        // div.replaceWith(result.data.comment)
        object[index].comment = result.data.comment;
      }
    },
    hideModal() {
      this.$refs["comment-modal"].hide();
    },
    deletefunction() {
      this.deletePostComment(this.delcomment);
    },
    deleteVerificationComment(object) {
      this.delcomment = object;
      this.$refs["comment-modal"].show();
    },
    //profile comment comment delete
    async deletePostComment(object) {
      const data = {
        comment_id: object.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      var result = await axios
        .post(this.$paths + "deleteComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        this.$refs["comment-modal"].hide();
        for (let i = 0; i < this.userProfileData.posts.length; i++) {
          let idFound = false;
          let list = this.userProfileData.posts[i].comments;
          for (
            let j = 0;
            j < this.userProfileData.posts[i].comments.length;
            j++
          ) {
            if (object.id == this.userProfileData.posts[i].comments[j].id) {
              idFound = true;
              list.splice(j, 1);
              this.userProfileData.posts[i].comments = list;
              break;
            }
          }
          if (idFound == true) {
            break;
          }
        }
      }
    },
    async updateUser() {
      console.log("check => ", this.favoriteBooks);
      const formData = new FormData();
      if (this.coverImage && this.coverImage != "" && this.coverImage != null) {
        formData.append("cover_image", this.coverImage);
      }
      if (
        this.profileImage &&
        this.profileImage != "" &&
        this.profileImage != null
      ) {
        formData.append("profile_image", this.profileImage);
      }
      formData.append("user_id", this.userProfileData.id);
      formData.append("about", this.about);
      formData.append("first_name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("email", this.email);
      formData.append("phone_number", this.userProfileData.phone_number);
      formData.append("country_codes_id", this.countryId);
      formData.append("city_codes_id", this.cityId);
      formData.append("plate_number", this.plateNumber);
      formData.append("brand_id", this.brandId);
      formData.append("gender", this.userGender);
      formData.append("martial_status", this.userMartialStatus);
      formData.append("brand_model_id", this.brandModelId);
      formData.append("model_type_id", this.modelTypeId);
      formData.append("model_color_id", this.modelColorId);
      formData.append("type", "user");
      formData.append("profile_type", "public");
      if (this.favoriteFoods && this.favoriteFoods.length > 0) {
        formData.append("favt_food", this.favoriteFoods);
      }
      if (this.favoriteDrinks && this.favoriteDrinks.length > 0) {
        formData.append("favt_drink", this.favoriteDrinks);
      }
      if (this.favoriteMusics && this.favoriteMusics.length > 0) {
        formData.append("favt_music", this.favoriteMusics);
      }
      if (this.favoriteColors && this.favoriteColors.length > 0) {
        formData.append("favt_color", this.favoriteColors);
      }
      if (this.favoriteBooks && this.favoriteBooks.length > 0) {
        formData.append("favt_book", this.favoriteBooks);
      }
      if (this.favoriteMovies && this.favoriteMovies.length > 0) {
        formData.append("favt_movie", this.favoriteMovies);
      }
      if (this.favoriteVacations && this.favoriteVacations.length > 0) {
        formData.append("favt_vacation", this.favoriteVacations);
      }
      if (this.favoriteMottos && this.favoriteMottos.length > 0) {
        formData.append("favt_motto", this.favoriteMottos);
      }
      if (this.favoriteHobbies && this.favoriteHobbies.length > 0) {
        formData.append("hobby", this.favoriteHobbies);
      }
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      var data = await axios.post(this.$paths + "updateUser", formData, {
        headers,
      });
      this.$store.commit("updateLoginUser", data.data.data);
      this.$store.commit("updatetoken", data.data.token);
      if (data.data.code == 200) {
        localStorage.setItem("token", JSON.stringify(data.data.data.token));
        localStorage.setItem("loginUserData", JSON.stringify(data.data.data));
        this.successAlert(data.data.message);
        location.reload();
      } else if (data.data.code != 200) {
        this.errorAlert(data.data.message);
        this.$bvModal.hide("edit-profile");
      }
    },

    editProfileClick() {
      this.about = this.userProfileData.about;
      this.firstName = this.userProfileData.first_name;
      this.lastName = this.userProfileData.last_name;
      this.email = this.userProfileData.email;
      this.phoneNumber = this.userProfileData.phone_number;
      this.plateNumber = this.userProfileData.plate_number;
      if (this.userProfileData.gender) {
        this.userGender = this.userProfileData.gender;
      }
      if (this.userProfileData.martial_status) {
        this.userMartialStatus = this.userProfileData.martial_status;
      }
      this.countries.filter((country) => {
        if (country.code == this.userProfileData.country_code) {
          this.$store.commit("updateCountryId", country.id);
          return (this.countryId = country.id);
        }
      });

      this.citiesData = this.$store.getters.countryCities;

      this.citiesData.filter((city) => {
        if (city.code == this.userProfileData.city_code) {
          return (this.cityId = city.id);
        }
      });

      this.allBrands.filter((brand) => {
        if (brand.name == this.userProfileData.brand) {
          this.$store.commit("updateBrandId", brand.id);
          return (this.brandId = brand.id);
        }
      });

      this.allBrandModels = this.$store.getters.brandModels;

      this.allBrandModels.filter((model) => {
        if (model.name == this.userProfileData.brand_model) {
          this.$store.commit("updateBrandModelId", model.id);
          return (this.brandModelId = model.id);
        }
      });

      this.allBrandModelType = this.$store.getters.modelTypes;

      this.allBrandModelType.filter((type) => {
        if (type.name == this.userProfileData.model_type) {
          return (this.modelTypeId = type.id);
        }
      });

      this.modelColors.filter((color) => {
        if (color.name == this.userProfileData.model_color) {
          return (this.modelColorId = color.id);
        }
      });
      // this.countryId = loginUserData.country_code;
      // this.cityId = loginUserData.city_codes_id;
      // this.brandId = loginUserData.brand_id;
      // this.brandModelId = loginUserData.brand_model_id;
      // this.modelTypeId = loginUserData.model_type_id;
      // this.modelColorId = loginUserData.model_color_id;
      // this.user = 'type';
      // this.public = 'profile_type'
    },

    async getUserProfileData(id) {
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      this.userAParseValue = userAParseValue;
      this.userProfile = userAParseValue.profile_image;
      this.isLoading = true;
      const profileData = await axios.get(this.$paths + "users/" + id, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      });
      if (profileData.data.code == 200) {
        this.userProfileData = profileData.data.data;
        for (let element of this.userProfileData.favorites) {
          if (element.type == "food") {
            this.favoriteFoods.push(element.name);
          } else if (element.type == "drink") {
            this.favoriteDrinks.push(element.name);
          } else if (element.type == "music") {
            this.favoriteMusics.push(element.name);
          } else if (element.type == "color") {
            this.favoriteColors.push(element.name);
          } else if (element.type == "book") {
            this.favoriteBooks.push(element.name);
          } else if (element.type == "movie") {
            this.favoriteMovies.push(element.name);
          } else if (element.type == "vacation") {
            this.favoriteVacations.push(element.name);
          } else if (element.type == "motto") {
            this.favoriteMottos.push(element.name);
          } else if (element.type == "hobby") {
            this.favoriteHobbies.push(element.name);
          }
        }
      }
      this.isLoading = false;
      console.log("profileData => ", this.userProfileData);
    },

    async submitReason(id) {
      const data = {
        post_id: this.selectedPostForReport,
        reason_id: id,
      };

      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      await axios
        .post(this.$paths + "postReport", data, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.successAlert(response.data.message);
            this.$bvModal.hide("report-modal");
          } else {
            this.errorAlert(response.data.message);
            this.$bvModal.hide("report-modal");
          }
        });
    },

    async getReportReaasons(id) {
      try {
        this.isLoading = true;
        var result = await axios.get(this.$store.state.baseUrl + "getReasons");
        if (result.data.code == 200) {
          this.selectedPostForReport = id;
          this.isLoading = false;
          this.reportReasons = result.data.data;
          this.$bvModal.show("report-modal");
        } else {
          this.isLoading = false;
          this.reportReasons = [];
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },

    showClicked(post) {
      if (post.is_liked) {
        return false;
      } else {
        return true;
      }
    },

    sendData(id) {
      this.selectedPostForReport = id;
    },

    async likeToggle(post, index) {
      this.isLoading = true;
      this.selectedCursor = "pointer-events:none";

      if (post.is_liked) {
        this.likedStatus = "Dislike";
      } else {
        this.likedStatus = "Like";
      }
      const data = {
        post_id: post.id,
        liked_status: this.likedStatus,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios
        .post(this.$paths + "postLikeDislike", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.message == "Post liked successfully") {
        this.selectedCursor = "pointer-events:auto";

        post.liked_users.length++;
        this.isLoading = false;
        return (this.userProfileData.posts[index].is_liked = true);
      } else if (result.message == "Post Unliked") {
        this.selectedCursor = "pointer-events:auto";
        post.liked_users.length--;
        this.isLoading = false;
        return (this.userProfileData.posts[index].is_liked = false);
      }
      this.selectedCursor = "pointer-events:auto";
    },

    checkEmptyArray(comments) {
      if (comments.length > 0) {
        return true;
      }
      return false;
    },

    reverseItems(comments) {
      return comments.slice(comments.length - 2, comments.length).reverse();
    },

    showCommentButton(post, type) {
      var buttonId;
      var commentValue;
      if (type == "modal") {
        commentValue = document.getElementById("modalcomment" + post.id).value;
        buttonId = "modalcommentButton" + post.id;
      } else {
        commentValue = document.getElementById("listcomment" + post.id).value;
        buttonId = "listcommentButton" + post.id;
      }
      var chars = commentValue.length;
      var commentButton = document.getElementById(buttonId);
      if (chars > 0) {
        commentButton.style.display = "flex";
      } else {
        commentButton.style.display = "none";
      }
    },

    async postComment(post, index, type) {
      this.isLoading = true;
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));
      var commentValue;
      var buttonId;
      if (type == "modal") {
        commentValue = document.getElementById("modalcomment" + post.id).value;
        buttonId = "modalcommentButton" + post.id;
      } else {
        commentValue = document.getElementById("listcomment" + post.id).value;
        buttonId = "listcommentButton" + post.id;
      }
      var commentButton = document.getElementById(buttonId);
      const data = {
        post_id: post.id,
        user_id: userAParseValue.id,
        comment: commentValue,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios
        .post(this.$paths + "postComment", data, {
          headers,
        })
        .then((response) => (this.postLikeData = response.data));
      if (result.code == 200) {
        commentButton.style.display = "none";
        this.userProfileData.posts[index].comments.push(result.data);
        this.isLoading = false;
        document.getElementById("listcomment" + post.id).value = "";
      }
    },

    async profileLikeDislike(status) {
      const data = {
        user_id: this.userID.id,
        profile_id: this.userProfileData.id,
        action_performed: status,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      const profileLikeDislike = await axios.post(
        this.$paths + "profileLikeDislike",
        data,
        {
          headers,
        }
      );
      console.log("profileLikeDislike => ", profileLikeDislike.data);
      if (profileLikeDislike.data.message == "Action performed successfully") {
        if (status == "dislike") {
          this.userProfileData.profile_dislikes++;
        } else {
          this.userProfileData.profile_likes++;
        }
        this.successAlert(profileLikeDislike.data.message);
      } else if (
        profileLikeDislike.data.message ==
        "Action performed updated successfully"
      ) {
        if (status == "dislike") {
          this.userProfileData.profile_dislikes++;
          this.userProfileData.profile_likes--;
        } else {
          this.userProfileData.profile_likes++;
          this.userProfileData.profile_dislikes--;
        }
        this.successAlert(profileLikeDislike.data.message);
      } else {
        this.errorAlert(profileLikeDislike.data.message);
      }
    },

    async selectedCountryCities(countryId) {
      this.citiesData = this.$store.state.CountryCities.filter(
        (obj) => obj.country_codes_id == countryId
      );
    },

    async selectedBrandModel(brandId) {
      this.allBrandModels = this.$store.state.allBrandModels.filter(
        (obj) => obj.brand_id == brandId
      );
      this.allBrandModelType = [];
    },

    async selectedBrandModelType(brandModelId) {
      this.allBrandModelType = this.$store.state.allBrandModelTypes.filter(
        (obj) => obj.brand_model_id == brandModelId
      );
    },
  },

  mounted() {
    this.MartialStatusArray.push({
      id: 1,
      name: "single",
    });
    this.MartialStatusArray.push({
      id: 2,
      name: "married",
    });
    this.MartialStatusArray.push({
      id: 3,
      name: "divorced",
    });
    this.userMartialStatus = "1";
    this.getUserProfileData(this.profileID ? this.profileID : this.userID.id);

    this.$store.dispatch("fetchSettings");
    let data = localStorage.getItem("loginUserData");
    this.$store.commit("updateLoginUser", JSON.parse(data));

    // cover modal
    var coverModal = document.getElementById("coverModal");
    coverModal.style.display = "none";
    var cover = document.getElementById("myCover");
    var modalCover = document.getElementById("cover01");
    cover.onclick = function() {
      coverModal.style.display = "flex";
      modalCover.src = this.src;
    };
    var coverSpan = document.getElementsByClassName("close")[0];
    coverSpan.onclick = function() {
      coverModal.style.display = "none";
    };
    // profile modal
    var profileModal = document.getElementById("profileModal");
    profileModal.style.display = "none";
    var profile = document.getElementById("myProfile");
    var modalProfile = document.getElementById("profile01");
    profile.onclick = function() {
      profileModal.style.display = "flex";
      modalProfile.src = this.src;
    };
    var span = document.getElementsByClassName("close")[1];
    span.onclick = function() {
      profileModal.style.display = "none";
    };
  },
};
</script>

<style scoped>
.userProfileImg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
}
.profileTags {
  display: flex;
  align-items: center;
  padding-top: 24px;
}
.profileTags .form-control {
  line-height: 43px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px, rgb(0 0 0 / 10%) 0px 8px 16px;
  border: none;
  border-radius: 0 5px 5px 0;
}
.address {
  background-color: #f0f2f5;
  border: 1px solid #ced0d4;
  font-size: 17px;
  font-weight: 600;
  color: #000;
  padding: 20px 10px;
}
</style>
