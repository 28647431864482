<template>
  <div>
    <div>
      <img
        v-if="loading"
        :src="'./images/loader.gif'"
        class="mr-2"
        style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
      />
    </div>

    <div class="right-content-section">
      <div class="bgWhite">
        <h4 class="text-center">{{ $t("LBL_HOW_CAN_WE_HELP_YOU") }}</h4>
        <hr />
        <div>
          <div v-if="error">
            <p class="error-message" v-if="error">{{ errorMessage }}</p>
          </div>
          <b-form-group label="Email *">
            <b-input-group prepend="@">
              <b-form-input
                type="email"
                v-model="email"
                :placeholder="$t('LBL_ENTER_EMAIL')"
                class="text-left edit-profile-input"
                style="font-size:22px;"
                required
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>
        <div>
          <b-form-group :label="$t('LBL_COMMENT_TXT')">
            <b-form-textarea
              id="textarea"
              v-model="text"
              :placeholder="$t('LBL_WRITE_COMMENTS_TXT')"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="text-center">
          <b-button
            class="create-event-btn align-items-center"
            @click="submitsupportform()"
            style="width:40%;"
            >{{ $t("LBL_SUBMIT_BTN")
            }}<i style="color: white" v-bind:class="changeClass()"></i
          ></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      email: "",
      text: "",
      loading: false,
      postButtonLoader: false,
      errorMessage: "",
      error: false,
    };
  },
  methods: {
    changeClass() {
      if (this.postButtonLoader == true) {
        return "fa fa-spinner fa-spin";
      }
    },
    errorAlert(message) {
      Swal.fire({
        title: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    successAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async submitsupportform() {
      this.postButtonLoader = true;
      this.validate();
      if (this.error === false) {
        let data = {
          email: this.email,
          text: this.text,
        };
        const headers = {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        };
        try {
          axios
            .post(this.$paths + "contactsupport", data, {
              headers,
            })
            .then((response) => {
              if (response.data.code == 200) {
                this.successAlert(response.data.message);
                this.postButtonLoader = false;
                this.email = "";
                this.text = "";
              }
            });
        } catch (err) {
          if (!err.response) {
            alert("Please Check You Internet Connection");
          }
        }
      }
    },
    validate() {
      this.errorMessage = "";
      if (this.email === "") {
        this.errorMessage = "Please Enter Email";
        this.error = true;
        this.postButtonLoader = false;
        return false;
      } else {
        this.error = false;
      }
      if (this.text === "") {
        this.errorMessage = "Please Enter Your request";
        this.postButtonLoader = false;
        this.error = true;
        return false;
      } else {
        this.error = false;
      }
    },
  },
};
</script>
