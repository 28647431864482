import $ from "jquery";
export function onFileChange(e) {
  
  const file = e.target.files[0];
  this.image = file
  this.imageUrl = URL.createObjectURL(file);
  this.imageRemoveBtn = true;
  this.changeColor = false;
  this.locationdiv = false;
}

export function onCoverChange(e) {
  const file = e.target.files[0];
  this.coverImage = file
  this.coverImageUrl = URL.createObjectURL(file);
}

export function onProfileChange(e) {
  const file = e.target.files[0];
  this.profileImage = file
  this.profileImageUrl = URL.createObjectURL(file);
}

export function onVideoChange(e) {
  const file = e.target.files[0];
  this.video = file
  this.videoUrl = URL.createObjectURL(file);
  this.videoRemoveBtn = true;
  this.locationdiv = false;
}
export function locationchange() {
  this.image = false
  this.imageUrl = false
  this.imageRemoveBtn = false;
  this.changeColor = false;
  this.video = false
  this.videoUrl = false;
  this.videoRemoveBtn = false;
}
export function changeColor(e) { 
  $('#textBox').css('background-color', e);
  return this.background_color = e;
}
export function Colorwheel(e) { 
  console.log(e)
  $('#textBox').css('background-color', e);
  return this.background_color = e;
}

export function showPopup() {
  this.showPopupp = this.sitebody.classList.add("noscroll");
  
}

export function hidePopup() {
  this.showPopupp = this.sitebody.classList.remove("noscroll");
}

// export function toggleFollow() {
//   this.follow = !this.follow;
//   if(this.follow){
//     this.btnClass="pending-btn"
//   }
//   else{
//     this.btnClass="follow-btn"
//   }
// }

export function removeImg() {
  this.imageUrl = null;
  this.image=null;
  this.imageRemoveBtn = false;
}

export function removeVideo() {
  this.videoUrl = null;
  this.video=null;
  this.videoRemoveBtn = false;
}

export function toggleInvite() {
  this.invite = !this.invite;
  if (this.invite) {
    this.btnClass = "pending-btn";
  } else {
    this.btnClass = "follow-btn";
  }
}

export function countries() {
  return this.$store.state.allCountries;
}

export function allBrands() {
  return this.$store.state.allBrands;
}

export function updateBrandId(id) {
  this.brandModelId = '0';
  this.$store.commit('updateBrandId',id);
}

export function updateBrandModelId(id) {
  this.modelTypeId = '0';
  this.$store.commit('updateBrandModelId',id);
}

export function updateModelColorId(id) {
  
  this.$store.commit('updateModelColorId',id);
}

export function updateModelTypeId(id) {
  this.modelColorId = '0';
  this.$store.commit('updateModelTypeId',id);
}
 
export function allCities(id) {
  this.$store.commit('updateCountryId',id);
}
