import Vue from "vue";
import VueRouter from 'vue-router';

import Home from "../components/Home";
import Login from "../components/LoginNew";
import SearchUser from "../components/SearchUser";
import Events from "../components/Events";
import News from "../components/News";
import NewsDetail from "../components/NewsDetail";
import EventsDetails from "../components/EventsDetails";
import PostDetail from "../components/PostDetail";
import UserProfile from "../components/UserProfile";
import NewRegister from "../components/NewRegister";
import Verification from "../components/Verification";
import FullPage from "../pages/FullPage";
import Main from "../pages/Main";
import ForgotPassword from "../components/ForgotPassword";
import OtherUserProfile from "../components/OtherUserProfile";
import PrivacyPolicy from "../components/PrivacyPolicy";
import SupportForm from "../components/SupportForm";
import Mention from "../components/Mention";
import store from '../store/index'


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes:[
        {
            path:'',
            component : FullPage,
            children:[
                {
                    path:"",
                    name:"login",
                    component: Login
                },
                {
                    path:"/register",
                    name:"Register",
                    component: NewRegister
                },
                {
                    path: "/mention",
                    name: "Mention",
                    component:Mention
                },
                {
                    path:"/verification",
                    name:"Verification",
                    component: Verification,
                    props: true,
                },
                {
                    path:"/forget-password",
                    name:"forget-password",
                    component: ForgotPassword,
                    props: true
                },                
            ]
        },
        {
            path:'',
            component:Main,
            children:[
                {
                    path:"/home",
                    name:"Home",
                    component: Home,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/posts/shows/:id",
                    name:"PostDetail",
                    component: PostDetail,
                    props: true,
                },
                {
                    path:"/search-user",
                    name:"SearchUSer",
                    component: SearchUser,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/events",
                    name:"Events",
                    component: Events,
                },
                {
                    path:"/events/:id",
                    name:"EventsDetails",
                    component: EventsDetails,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/news",
                    name:"News",
                    component: News,
                },
                {
                    path:"/news-detail",
                    name:"NewsDetail",
                    component: NewsDetail,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/profile",
                    name:"UserProfile",
                    component: UserProfile,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/other-user",
                    name:"OtherUserProfile",
                    component: OtherUserProfile,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/privacypolicy",
                    name:"PrivacyPolicy",
                    component: PrivacyPolicy,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
                {
                    path:"/support",
                    name:"SupportForm",
                    component: SupportForm,
                    beforeEnter: (to, from, next) => {
                        if(store.state.isAuthenticated == 'false') {
                            next({ name: 'login' });
                        } else {
                            next();
                        }
                    }
                },
            ]
        }
    ]
    
});

export default router;