import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

Vue.use(Vuex);
Vue.prototype.$paths = "https://adminmeetmycar.crunchyapps.com/api/";
// Vue.prototype.$path = "http://127.0.0.1:8000/api/";
const store = new Vuex.Store({
  state: {
    baseUrl: "https://adminmeetmycar.crunchyapps.com/api/",
    isAuthenticated: localStorage.getItem("isAuthenticated"),
    status: "NOTLOADING",
    allCountries: [],
    CountryCities: [],
    countryId: null,
    allBrands: [],
    brandId: null,
    allBrandModels: [],
    brandModelId: null,
    allBrandModelTypes: [],
    ModelTypesId: null,
    allModelColors: [],
    ModelColorId: null,
    userNotification: {
      count: 0,
      notification: [],
    },
    loginUserData: localStorage.getItem("loginUserData"),
    token: "",
    otp: "",
    userPhoneNumber: "",
    allPosts: [],
    translations: [],
    setLang: "en",
    postPageNum: 1,
    allPostsSize: 0,
    isPostSearch: false,
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.status = status;
    },

    set_LANG(state, data) {
      state.setLang = data;
    },

    setTranslation(state, data) {
      state.translations = data;
    },
    SET_COUNTRIES(state, allCountries) {
      state.allCountries = allCountries;
    },
    SET_CITIES(state, CountryCities) {
      state.CountryCities = CountryCities;
    },
    SET_BRANDS(state, allBrands) {
      state.allBrands = allBrands;
    },
    SET_BRAND_MODELS(state, allBrandModels) {
      state.allBrandModels = allBrandModels;
    },
    SET_BRAND_MODEL_TYPES(state, allBrandModelTypes) {
      state.allBrandModelTypes = allBrandModelTypes;
    },
    SET_MODEL_COLOR(state, allModelColors) {
      state.allModelColors = allModelColors;
    },
    updateCountryId(state, id) {
      state.countryId = id;
    },
    updateBrandId(state, id) {
      state.brandId = id;
    },
    updateBrandModelId(state, id) {
      state.brandModelId = id;
    },
    updateModelTypeId(state, id) {
      state.ModelTypesId = id;
    },
    updateModelColorId(state, id) {
      state.ModelColorId = id;
    },
    updateLoginUser(state, data) {
      state.loginUserData = data;
    },
    updatetoken(state, token) {
      state.token = token;
    },
    updateAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    RESET_STATE(state) {
      Object.assign(state, state);
    },
    getUserNotification(state, userNotification) {
      state.userNotification.count = userNotification.count;
      state.userNotification.notification = userNotification.notification;
    },

    readAllNotification(state, userNotification) {
      state.userNotification.count = 0;
      state.userNotification.notification = [];
      state.userNotification.count = userNotification.count;
      state.userNotification.notification = userNotification.notification;
    },

    getAllPosts(state, allPosts) {
      state.allPosts = allPosts;
    },
  },
  actions: {
    async fetchSettings(context) {
      console.log("API Started!");
      context.commit("SET_LOADING_STATUS", "LOADING");
      await axios.get(this._vm.$paths + "getSettings").then((response) => {
        context.commit("SET_COUNTRIES", response.data.data.country_codes);
        context.commit("SET_CITIES", response.data.data.city_codes);
        context.commit("SET_BRANDS", response.data.data.brands);
        context.commit("SET_BRAND_MODELS", response.data.data.brand_models);
        context.commit("SET_BRAND_MODEL_TYPES", response.data.data.model_types);
        context.commit("SET_MODEL_COLOR", response.data.data.model_colors);
        context.commit("SET_LOADING_STATUS", "NOTLOADING");
      });
      console.log("API DONE !");
    },

    get_LANG(event, new1) {
      console.log("===>", event);
      console.log("===>", new1.getters);
    },

    set_LANG({ commit }, data) {
      commit("set_LANG", data);
    },

    setTranslation({ commit }, data) {
      commit("setTranslation", data);
    },

    async fetchTranslations(context, data) {
      console.log(this._vm.$paths + "getAllTranslations");
      // context.commit("SET_LOADING_STATUS", "LOADING");

      await axios
        .post(this._vm.$paths + "getAllTranslations", data, {})
        .then((response) => {
          context.commit("setTranslation", response.data.data);
          // context.commit("SET_LOADING_STATUS", "NOTLOADING");
        });
    },

    async fetchUserNotifications(context) {
      context.commit("SET_LOADING_STATUS", "LOADING");
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      await axios
        .get(this._vm.$paths + "user_notifications/v1", { headers })
        .then((response) => {
          context.commit("getUserNotification", response.data.data);
          context.commit("SET_LOADING_STATUS", "NOTLOADING");
        });
    },

    async readAllNotification(context, data) {
      context.commit("SET_LOADING_STATUS", "LOADING");
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      await axios
        .post(this._vm.$paths + "notification_read", data, {
          headers: headers,
        })
        .then((response) => {
          context.commit("readAllNotification", response.data.data);
          context.commit("SET_LOADING_STATUS", "NOTLOADING");
        });
    },

    async getPostList(context) {
      context.commit("SET_LOADING_STATUS", "LOADING");
      var result = await axios
        .get(
          `http://admin.meetmycar.com/api/posts?page=${this.state.postPageNum}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => (this.userData = response.data));
      if (result.code == 200) {
        console.log("GETALLPOST SUCCESS => ", result.data);
        this.state.allPosts.push(...result.data.posts);
        this.state.allPostsSize = result.data.size;
        context.commit("SET_LOADING_STATUS", "NOTLOADING");
      }
    },
  },
  getters: {
    getAllCountries(state) {
      return state.allCountries;
    },
    translations: (state) => state.translations,
    setLang: (state) => state.setLang,
    setTranslation: (state) => state.setTranslation,
    countryCities(state) {
      if (state.CountryCities == null) {
        return false;
      } else {
        return state.CountryCities.filter((city) => {
          if (city.country_codes_id == state.countryId) {
            return city;
          } else {
            return false;
          }
        });
      }
    },
    brandModels(state) {
      if (state.allBrandModels == null) {
        return false;
      } else {
        return state.allBrandModels.filter((model) => {
          if (model.brand_id == state.brandId) {
            return model;
          } else {
            return false;
          }
        });
      }
    },
    modelTypes(state) {
      if (state.allBrandModelTypes == null) {
        return false;
      } else {
        return state.allBrandModelTypes.filter((type) => {
          if (type.brand_model_id == state.brandModelId) {
            return type;
          } else {
            return false;
          }
        });
      }
    },
    getAllModelColors(state) {
      if (state.allModelColors == null) {
        return false;
      } else {
        return state.allModelColors;
      }
    },
    getLoginUserData(state) {
      return state.loginUserData;
    },
  },
});
export default store;
