<template>
  <div id="#EventsDetails">
    <div class="right-content-section">
      <router-link to="/events" class="d-flex justify-content-end"
        ><img src="/images/cancel.svg" alt="" style="width: 30px" />
      </router-link>
      <div class="events-details">
        <span v-if="eventData.medium == ''">
          <img :src="'./images/event.png'" alt="" class="w-100" />
        </span>
        <span v-else>
          <img :src="eventData.medium" alt="" class="w-100" />
        </span>
        <div class="mt-2">
          <label class="review-label">{{ $t("LBL_TYPE_TXT") }}</label>
          <div class="d-flex pl-3">
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                class="bi bi-check-circle-fill"
                fill="#5dd167"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                ></path>
              </svg>
            </div>
            <div class="pl-2 event-type">{{ eventData.type }}</div>
          </div>
        </div>
        <div class="mt-2">
          <label class="review-label">{{
            $t("LBL_LOCATION_ADDRESS_TXT")
          }}</label>
          <div class="pl-3">{{ eventData.address }}</div>
        </div>
        <div class="mt-2">
          <label class="review-label">{{ $t("LBL_DATE_AND_TIME_TXT") }}</label>
          <div class="time pl-3">
            {{ eventData.exact_date }} ({{ eventData.exact_time }})
          </div>
        </div>
        <div class="mt-2">
          <label class="review-label">{{ $t("LBL_DETAILS_TXT") }}</label>
          <div class="review-details">
            {{ eventData.details }}
          </div>
        </div>
        <div class="event-timing-detail">
          <hr style="margin-bottom: 5px" />
          <div class="pt-2 d-flex align-items-center justify-content-between">
            <div
              v-b-modal.event-interested
              @click="eventinterestedList(eventData)"
            >
              {{ eventData.users_interested.length }}
              {{ $t("LBL_INTERESTED_TXT") }}
            </div>
            <div v-b-modal.event-going @click="eventgoingList(eventData)">
              {{ eventData.users_going.length }} {{ $t("LBL_GOING_TXT") }}
            </div>
          </div>
        </div>
        <div
          class="mt-3 mb-3 d-flex justify-content-center"
          v-if="eventData.user_id.id == loginUserId"
        >
          <span v-if="eventData.type != 'public'">
            <b-button
              v-b-modal.invite-friends
              class="invite-btn w-300"
              @click="inviteUserList(eventData)"
              >{{ $t("LBL_INVITE_FRIENDS_TXT") }}</b-button
            >

            <b-modal id="invite-friends" :title="$t('LBL_INVITE_FRIENDS_TXT')">
              <div>
                <img
                  v-if="loading"
                  src="/images/loader.gif"
                  class="mr-2"
                  style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
                />
              </div>
              <div
                class="search-user-detail d-flex justify-content-between"
                v-for="inviteUser in UserList"
                v-bind:key="inviteUser.id"
              >
                <div class="d-flex">
                  <div class="user-search-profile">
                    <span v-if="inviteUser.profile_image != ''">
                      <img :src="inviteUser.profile_image" alt="" />
                    </span>
                    <span v-else>
                      <img src="images/dummy_user_img.png" alt="" />
                    </span>
                  </div>
                  <div class="w-170">
                    <div class="d-flex align-items-center number-plate">
                      <div class="d-flex outer-div">
                        <div class="circle"></div>
                        <div>{{ inviteUser.country_code }}</div>
                      </div>
                      <div class="d-flex justify-content-center w-65">
                        <div>{{ inviteUser.plate_number }}</div>
                      </div>
                    </div>
                    <div class="user-name pt-1">
                      {{ inviteUser.first_name }} {{ inviteUser.last_name }}
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-start"
                  v-if="eventData.event_invitations.length > 0"
                >
                  <button
                    :class="btnClass"
                    @click="
                      toggleInvite(
                        inviteUser.id,
                        eventData.id,
                        eventData.event_invitations.filter(
                          (obj) => obj.id === inviteUser.id
                        ).length > 0
                          ? false
                          : true
                      )
                    "
                  >
                    {{
                      eventData.event_invitations.filter(
                        (obj) => obj.id == inviteUser.id
                      ).length > 0
                        ? "Cancel"
                        : "Invite"
                    }}
                  </button>
                </div>
                <div class="d-flex align-items-start" v-else>
                  <button
                    :class="btnClass"
                    @click="toggleInvite(inviteUser.id, eventData.id)"
                  >
                    {{ invite ? "Cancel" : "Invite" }}
                  </button>
                </div>
              </div>
              <!-- <div class="mt-2 text-center">
                                <b-button class="create-event-btn">OK</b-button>
                            </div> -->
            </b-modal>
          </span>
        </div>
        <!-- eventInterest('going') -->
        <span v-else>
          <div
            class="mt-3 mb-3 d-flex justify-content-center"
            v-if="
              eventData.users_going.filter((obj) => obj.id == loginUserId)
                .length > 0
            "
          >
            <button
              class="invite-btn w-300"
              style="background-color:#000000 !important; color: #ffffff !important"
              @click="eventInterest('going', eventData)"
            >
              {{ $t("LBL_GOING_TXT") }}
            </button>
          </div>
          <div class="mt-3 mb-3 d-flex justify-content-center" v-else>
            <button
              class="invite-btn w-300"
              @click="eventInterest('going', eventData)"
            >
              {{ $t("LBL_GOING_TXT") }}
            </button>
          </div>
          <div
            class="mt-3 mb-3 d-flex justify-content-center"
            v-if="
              eventData.users_not_going.filter((obj) => obj.id == loginUserId)
                .length > 0
            "
          >
            <button
              class="invite-btn w-300"
              style="background-color:#000000 !important; color: #ffffff !important"
              @click="eventInterest('not-going', eventData)"
            >
              Not Going
            </button>
          </div>
          <div class="mt-3 mb-3 d-flex justify-content-center" v-else>
            <button
              class="invite-btn w-300"
              @click="eventInterest('not-going', eventData)"
            >
              Not Going
            </button>
          </div>
          <div
            class="mt-3 mb-3 d-flex justify-content-center"
            v-if="
              eventData.users_interested.filter((obj) => obj.id == loginUserId)
                .length > 0
            "
          >
            <button
              class="invite-btn w-300"
              style="background-color:#000000 !important; color: #ffffff !important"
              @click="eventInterest('interested', eventData)"
            >
              {{ $t("LBL_INTERESTED_TXT") }}
            </button>
          </div>
          <div class="mt-3 mb-3 d-flex justify-content-center" v-else>
            <button
              class="invite-btn w-300"
              @click="eventInterest('interested', eventData)"
            >
              {{ $t("LBL_INTERESTED_TXT") }}
            </button>
          </div>
        </span>
      </div>
    </div>
    <b-modal id="event-going" :title="$t('LBL_EVENT_GOING_USERS')">
      <div
        class="search-user-detail d-flex justify-content-between"
        v-for="inviteUser in GoingUsers"
        v-bind:key="inviteUser.id"
      >
        <div class="d-flex">
          <div class="user-search-profile">
            <span v-if="inviteUser.profile_image != ''">
              <img :src="inviteUser.profile_image" alt="" />
            </span>
            <span v-else>
              <img :src="'./images/dummy_user_img.png'" alt="" />
            </span>
          </div>
          <div class="w-170">
            <div class="d-flex align-items-center number-plate">
              <div class="d-flex outer-div">
                <div class="circle"></div>
                <div>{{ inviteUser.country_code }}</div>
              </div>
              <div class="d-flex justify-content-center w-65">
                <div>{{ inviteUser.plate_number }}</div>
              </div>
            </div>
            <div class="user-name pt-1">
              {{ inviteUser.first_name }} {{ inviteUser.last_name }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="event-interested" :title="$t('LBL_EVENT_GOING_USERS')">
      <div
        class="search-user-detail d-flex justify-content-between"
        v-for="inviteUser in InterestedUsers"
        v-bind:key="inviteUser.id"
      >
        <div class="d-flex">
          <div class="user-search-profile">
            <span v-if="inviteUser.profile_image != ''">
              <img :src="inviteUser.profile_image" alt="" />
            </span>
            <span v-else>
              <img :src="'./images/dummy_user_img.png'" alt="" />
            </span>
          </div>
          <div class="w-170">
            <div class="d-flex align-items-center number-plate">
              <div class="d-flex outer-div">
                <div class="circle"></div>
                <div>{{ inviteUser.country_code }}</div>
              </div>
              <div class="d-flex justify-content-center w-65">
                <div>{{ inviteUser.plate_number }}</div>
              </div>
            </div>
            <div class="user-name pt-1">
              {{ inviteUser.first_name }} {{ inviteUser.last_name }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      url: null,
      loginUserId: "",
      UserList: [],
      btnClass: "follow-btn",
      showModal: false,
      loading: true,
      GoingUsers: [],
      InterestedUsers: [],
      eventData: {},
      eventID: "",
    };
  },
  mounted() {
    this.loginUserId = JSON.parse(localStorage.getItem("loginUserData")).id;
    this.eventID = this.$route.params.id;
    this.getEventData();
    this.eventinterestedList();
    this.eventgoingList();
  },
  methods: {
    async toggleInvite(invited_user_id, event_id, status) {
      const data = {
        user_invited_id: invited_user_id,
        event_id: event_id,
        isInviteUser: status,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      const EventInvite = await axios.post(this.$paths + "inviteEvent", data, {
        headers,
      });
      if (EventInvite.data.code == 200) {
        this.$bvModal.hide("invite-friends");
        this.showAlert(EventInvite.data.message);
      } else {
        this.$bvModal.hide("invite-friends");
        this.showAlert(EventInvite.data.message);
      }
    },
    async eventinterestedList(event) {
      this.loading = true;
      const data = {
        event_id: event.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "eventinterestedusers", data, {
          headers,
        })
        .then((response) => {
          this.loading = false;
          this.InterestedUsers = response.data.data;
        });
    },
    async eventgoingList(event) {
      this.loading = true;
      const data = {
        event_id: event.id,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "eventgoingusers", data, {
          headers,
        })
        .then((response) => {
          this.loading = false;
          this.GoingUsers = response.data.data;
        });
    },
    showAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    async inviteUserList(eventData) {
      const data = {
        event_type: eventData.type,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "events/suggestedUsers", data, {
          headers,
        })
        .then((response) => {
          this.UserList = response.data.data;
          this.showModal == true;
          this.loading = false;
        });
    },

    async eventInterest(type, eventData) {
      const data = {
        event_id: eventData.id,
        type: type,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "eventInterests", data, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.showAlert(response.data.message);
            this.getEventData();
            this.eventinterestedList();
            this.eventgoingList();
          }
        });
    },
    async getEventData() {
      this.isLoading = true;
      const EventData = await axios.get(
        this.$paths + "events/" + this.eventID,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (EventData.data.code == 200) {
        this.eventData = EventData.data.data;
        console.log(this.eventData);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.errorAlert(EventData.data.message);
      }
    },
  },
};
</script>
