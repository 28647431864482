<template>
  <div id="main">
    <div id="Verification">
      <div class="right-content-section pt-0">
        <b-modal ref="phonenumber-error" title="Alert">
            <p class="my-4">Sorry this number is not exist please add these number for forgot password who are register.</p>
        </b-modal>
        <div class="pt-5">
          <div class="d-flex justify-content-center">
            <img :src="'./images/otp-icon.png'" alt="" />
          </div>
          <div class="text-center pt-5">
            <div class="subtitle">Verify Your Number</div>
          </div>
          <div class="text-center pt-2">
            <div class="secondary-subtitle">
              Please enter your mobile number to receive a one time password (OTP)
            </div>
          </div>
          <div class="d-flex align-items-center mt-4 box-shadow">
            <div class="plate-alphabet">
              <div class="plate"><img :src="flag" alt="" /></div>
            </div>
            <select class="code-select text-center" v-model="selectedCountry" v-on:change="changeCode(selectedCountry)">
              <option value="">Select country</option>
              <option value="+41">Switzerland</option>
              <option value="+44">Austria</option>
              <option value="+99">Germany</option>
              <option value="+92">Pakistan</option>
            </select>
          </div>
          <div class="d-flex align-items-center mt-4 box-shadow">
            <div class="plate-alphabet">
              <div class="plate">{{ country_code}}</div>
            </div>
            <div class="w-100">
              <input type="text"
                v-model="phNo"
                class="code-select text-center"
                placeholder="12 345 6789"
                minlength="10"
                maxlength="15"
                @keypress="isNumber($event)"
              />
            </div>
          </div>
          <div class="mt-4 text-center">
            <!-- <router-link to="/otp" -->
              <button class="otp-btn" @click="sendOtp()" >GET OTP <i style="color: white"
                  v-bind:class="changeClass()"></i></button>
              <!-- </router-link> -->
          </div>
          <div id="recaptcha-container"></div><br>
        </div>
      </div>
    </div>
    <div id="OTP" style="display:none;">
      <div class="right-content-section pt-0">
        <div class="custom-content pt-5">

          <div class="d-flex justify-content-center">
            <img :src="'./images/message.png'" alt="" style="width:150px; height:80px" />
          </div>
          <div class="text-center pt-5">
            <div class="subtitle">Enter Passcode</div>
          </div>
          <div class="text-center pt-2">
            <div class="secondary-subtitle">
              OTP has been sent to your mobile number.Please enter the passcode.
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4 mb-4">
            <div class="otp">
              <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange"
              />
            </div>
          </div>
          <div class="text-center secondary-subtitle pt-3 mb-3" @click="sendOtp()">
            If you haven't received a code!<span>Resend</span>
          </div>
          <div class="pt-5 text-center">
            <!-- <router-link to="register"> -->
              <button class="otp-btn" @click="verifyOtp()">VERIFY</button>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import firebase from 'firebase';
import axios from 'axios';
import Swal from "sweetalert2";
  export default {
    props:['type'],
    data(){
      return{
        selectedCountry: '',
        country_code: '',
        phNo: '',
        appVerifier : '',
        first: '',
        second : '',
        thrid : '',
        fourth : '',
        fifth : '',
        sixth : '',
        otpValue : '',
        flag: '',
        postButtonLoader: false,
      }
    },
    methods :{
      
      errorAlert(message) {
          Swal.fire({
              title: message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
          });
      },

      successAlert(message){
          Swal.fire({
              title: message,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
          })
      },
      changeClass() {
      if (this.postButtonLoader == true) {
        return 'fa fa-spinner fa-spin';
      }

    },

      sendOtp(){
        this.postButtonLoader=true;
        this.first = '';
        this.second = '';
        this.thrid = '';
        this.fourth = '';
        this.fifth = '';
        this.sixth = '';
        this.otp = '';
        if(this.country_code == '') {
          this.postButtonLoader=false;
           this.errorAlert('Please select country !');
        } else if (this.phNo == '') {
          this.postButtonLoader=false;
         this.errorAlert('Please enter a valid Phone number !');
        }else if(this.phNo != '' && this.phNo.length > 15 || this.phNo.length < 7){
          this.postButtonLoader=false;
          this.errorAlert('Phone number must have minimum 7 and maximum 15 characters !');
        }else{
          
          let countryCode = this.country_code
          let phoneNumber = countryCode + this.phNo
          this.$store.state.userPhoneNumber = phoneNumber;
          //
          let appVerifier = this.appVerifier
          //
          firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              //
              // alert('SMS sent')
              document.getElementById("OTP").style.display = "block";
              document.getElementById("Verification").style.display = "none";
            }).catch(function (error) {
            console.log('ERROR =>' ,error);
              Swal.fire({
                title: error.message + '! SMS not sent',
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
          });
        }
      },
      //
      verifyOtp() {
        if(this.otpValue.length != 6) {
          this.errorAlert('Please enter valid OTP code !');
        }else{
          let vm = this
          let code = this.otpValue
          //
          var check = window.confirmationResult.confirm(code)
          .catch(function (error) {
            Swal.fire({
              title: error.message + '! Invalid OTP',
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            console.log('ERROR =>',error);
          });
          if(check) {
            var number = this.country_code + this.phNo;
            const data = { phone_number: number };
            axios.post(this.$paths+'isUserExist', data ).then(response => {
              console.log('Response => ',response.data.data.is_already_exist);
              if(response.data.code == 200) {
                if(response.data.data.is_already_exist == true) {
                  if(this.type == 'forgetPassword') {
                    vm.$router.push({name:'forget-password', params: { phone_number: this.$store.state.userPhoneNumber }})
                  } else {
                     this.$router.push({ name: 'login' });
                  }
                  
                } else {
                  vm.$router.push({path:'/register'})
                }
              }
            })
          }
        }
      },

      initReCaptcha(){
        setTimeout(()=>{
          // let vm = this
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function(response) {
              console.log(response);
            },
            'expired-callback': function() {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          });
          //
          this.appVerifier =  window.recaptchaVerifier
        },1000)
      },

      changeCode(country) {
        this.country_code = country;
        if(country == '+44') {
          this.flag = './images/austria.png';
        }  else if(country == '+41') {
          this.flag = './images/switzerland.png';
        }  else if(country == '+99') {
          this.flag = './images/germany.png';
        }  else if(country == '+92') {
          this.flag = './images/pakistan.jpg';
        } else {
          this.flag = '';
        }
      },

      isNumber: function(evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      
      handleOnChange(value) {
        this.otpValue = value;
      },
    },
    created(){
      this.initReCaptcha()
    }
  }
</script>
