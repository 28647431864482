<template>
  <div id="Events">
    <div class="right-content-section">
      <div>
        <img
          v-if="loading"
          :src="'./images/loader.gif'"
          class="mr-2"
          style="position: fixed; top: 50%; left: 50%; height: 100px; width: 100px; transform: translate(-50%, -50%);"
        />
      </div>
      <div class="title-background" v-if="!loading">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <h4 class="title text-left">{{ $t("LBL_EVENTS") }}</h4>
          <div>
            <b-button
              v-b-modal.create-event
              @click="addEventButton()"
              v-on:click="ClearData"
              class="create-event-btn"
              style="width:100% !important"
            >
              {{ $t("LBL_CREATE_EVENT_TXT") }}</b-button
            >

            <!-- //create event modal    -->
            <b-modal
              id="create-event"
              size="md"
              :title="this.eventTitle"
              ok-only
              no-stacking
            >
              <div id="imagePreview" class="mb-3">
                <img v-if="imageUrl" :src="imageUrl" />
                <button
                  v-if="imageRemoveBtn"
                  class="cross-btn"
                  @click="removeImg"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <label class="mb-2 event-uploader"
                ><img :src="'./images/create-event.png'" alt="" />
                {{ $t("LBL_ATTACH_PICTURE") }}
                <input type="file" @change="onFileChange" class="d-none" />
              </label>
              <div class="mb-2">
                <div>
                  <label
                    style="font-weight: 500;color:#6D6E72"
                    class="col-form-label"
                    >{{ $t("LBL_TYPE_TXT") }} *</label
                  >
                </div>
                <div class="d-inline-flex justify-content-around w-100">
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="optradio"
                        @change="typeOnChange($event)"
                        value="public"
                        :checked="eventType && eventType == 'public'"
                      />{{ $t("LBL_PUBLIC_TXT") }}
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="optradio"
                        @change="typeOnChange($event)"
                        value="private"
                        :checked="eventType && eventType == 'private'"
                      />{{ $t("LBL_PRIVATE_TXT") }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="pt-2">
                <b-form-group :label="$t('LBL_LOCATION_ADDRESS_TXT')">
                  <b-input-group>
                    <template #append>
                      <b-input-group-text style="background:#C8A860"
                        ><img
                          :src="'./images/location.png'"
                          class="w-100 location-icon"
                          alt=""
                        />
                      </b-input-group-text>
                    </template>
                    <vue-google-autocomplete
                      id="map"
                      ref="autocomplete"
                      :placeholder="$t('LBL_LOCATION_TXT')"
                      class="text-left edit-profile-input form-control"
                      v-model="eventLocation"
                      v-on:placechanged="deliveryAddress"
                      required
                    >
                    </vue-google-autocomplete>
                  </b-input-group>
                </b-form-group>
              </div>
              <b-form-group :label="$t('LBL_EVENT_NAME_TXT')">
                <b-form-input
                  type="text"
                  :placeholder="$t('LBL_EVENT_NAME_TXT')"
                  class="edit-profile-input text-left"
                  style="box-shadow:0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);"
                  v-model="eventName"
                  required
                ></b-form-input>
              </b-form-group>
              <div class="mb-2">
                <div class="d-flex">
                  <div class="d-block pr-2 responsive-date">
                    <b-form-group :label="$t('LBL_DATE_TXT')">
                      <b-form-datepicker
                        style="height:55px"
                        class="align-items-center datepicker"
                        id="datepicker-dateformat2-placeholder"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          dark: 'true',
                        }"
                        :hide-header="hideHeader"
                        :min="min"
                        locale="en"
                        :placeholder="$t('LBL_SELECT_DATE')"
                        v-model="eventDate"
                      ></b-form-datepicker>
                    </b-form-group>
                  </div>
                  <div class="d-block responsive-time">
                    <b-form-group :label="$t('LBL_TIME_TXT')">
                      <b-form-timepicker
                        style="height:55px"
                        class="align-items-center"
                        :placeholder="$t('LBL_SELECT_TIME')"
                        :hide-header="hideHeader"
                        locale="en"
                        v-model="eventTime"
                      ></b-form-timepicker>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <b-form-group :label="$t('LBL_DETAILS_TXT')">
                  <b-form-textarea
                    id="textarea"
                    :placeholder="$t('LBL_ENTER_COMMENTS_TXT')"
                    rows="3"
                    max-rows="6"
                    v-model="eventDetail"
                  ></b-form-textarea>
                </b-form-group>
              </div>

              <div class="text-center">
                <b-button
                  v-b-modal.create-event-next
                  class="create-event-btn w-100"
                  >{{ $t("LBL_NEXT_TXT") }}</b-button
                >
              </div>
            </b-modal>

            <b-modal id="create-event-next" :title="$t('LBL_REVIEW_TXT')">
              <span v-if="imageUrl != null && imageUrl != ''">
                <img :src="imageUrl" alt="" />
              </span>
              <div class="mt-2">
                <label style="font-weight: 500;color:#6D6E72">{{
                  $t("LBL_TYPE_TXT")
                }}</label>
                <div class="d-flex">
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                      class="bi bi-check-circle-fill"
                      fill="#5dd167"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                      ></path>
                    </svg>
                  </div>
                  <div class="pl-2" style="font-weight:600;">
                    {{ eventType != "" ? eventType : Public }}
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <label class="review-label">{{
                  $t("LBL_EVENT_NAME_TXT")
                }}</label>
                <div class="review-info">
                  {{ eventName != "" ? eventName : "Event Name" }}
                </div>
              </div>
              <div class="mt-2">
                <label class="review-label">{{
                  $t("LBL_LOCATION_ADDRESS_TXT")
                }}</label>
                <div class="review-info">
                  {{ eventLocation != "" ? eventLocation : "Event Location" }}
                </div>
              </div>
              <div class="mt-2">
                <label class="review-label">Date & Time</label>
                <div class="time review-info">
                  {{ eventDate != "" ? eventDate : "Event Date" }}
                  {{
                    tConvert(eventTime) != ""
                      ? tConvert(eventTime)
                      : "Event Time"
                  }}
                </div>
              </div>
              <div class="mt-2">
                <label class="review-label">{{ $t("LBL_DETAILS_TXT") }}</label>
                <div class="review-details">
                  {{ eventDetail != "" ? eventDetail : "Event Detail" }}
                </div>
              </div>
              <div
                class="mt-3 mb-3 d-flex justify-content-center"
                v-if="eventType == 'private'"
              >
                <b-button
                  v-b-modal.invite-friends
                  class="invite-btn"
                  @click="inviteUserList()"
                >
                  {{ $t("LBL_INVITE_FRIENDS") }}</b-button
                >
              </div>

              <b-modal id="invite-friends" :title="$t('LBL_INVITE_FRIENDS')">
                <div
                  class="search-user-detail d-flex justify-content-between"
                  v-for="inviteUser in UserList"
                  v-bind:key="inviteUser.id"
                >
                  <div class="d-flex">
                    <div class="user-search-profile">
                      <span v-if="inviteUser.profile_image != ''">
                        <img :src="inviteUser.profile_image" alt="" />
                      </span>
                      <span v-else>
                        <img :src="'./images/dummy_user_img.png'" alt="" />
                      </span>
                    </div>
                    <div class="w-170">
                      <div class="d-flex align-items-center number-plate">
                        <div class="d-flex outer-div">
                          <div class="circle"></div>
                          <div>{{ inviteUser.country_code }}</div>
                        </div>
                        <div class="d-flex justify-content-center w-65">
                          <div>{{ inviteUser.plate_number }}</div>
                        </div>
                      </div>
                      <div class="user-name pt-1">
                        {{ inviteUser.first_name }} {{ inviteUser.last_name }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-start">
                    <button
                      :class="btnClass"
                      @click="addInvitedUser(inviteUser.id)"
                    >
                      {{
                        invitedUsers.includes(inviteUser.id)
                          ? "Cancel"
                          : "Invite"
                      }}
                    </button>
                  </div>
                </div>
                <div class="mt-2 text-center" @click="closeModal()">
                  <b-button class="create-event-btn">OKAY</b-button>
                </div>
              </b-modal>

              <div
                class="mt-2 text-center"
                @click="createEvent()"
                v-if="isEditEvent == false"
              >
                <b-button class="create-event-btn w-100">{{
                  $t("LBL_CREATE_TXT")
                }}</b-button>
              </div>
              <div class="mt-2 text-center" @click="editEvent()" v-else>
                <b-button class="create-event-btn w-100">{{
                  $t("LBL_EDIT")
                }}</b-button>
              </div>
            </b-modal>
          </div>
        </div>
        <div>
          <input
            type="search"
            @keyup="characterCount()"
            v-model="search"
            :placeholder="$t('LBL_SEARCH_EVENT')"
            aria-label="search"
            class="w-100"
          />
        </div>
      </div>
      <div v-if="allEvents.length == 0 && !loading" class="no-record">
        {{ $t("LBL_NO_EVENT_FOUND") }}
      </div>
      <div v-for="event in allEvents" :key="event.id">
        <div class="listing">
          <div class="user-detail d-flex justify-content-between">
            <div class="d-flex">
              <div class="listing-profile">
                <span v-if="event.user_id.profile_image != ''">
                  <img :src="event.user_id.profile_image" alt="" />
                </span>
                <span v-else>
                  <img
                    :src="'./images/dummy_user_img.png'"
                    class="search-img mr-1"
                    alt=""
                  />
                </span>
              </div>
              <div class="ml-3">
                <div class="d-flex align-items-center number-plate">
                  <div class="d-flex outer-div">
                    <div class="circle"></div>
                    <div>{{ event.user_id.country_code }}</div>
                  </div>
                  <div class="d-flex justify-content-center w-65">
                    <div>{{ event.user_id.plate_number }}</div>
                  </div>
                </div>
                <div class="user-name pt-1">
                  {{ event.user_id.first_name }}
                  {{ event.user_id.last_name }}
                </div>
                <div class="post-date">
                  {{
                    moment
                      .utc(event.created_at)
                      .local()
                      .format("d-MM-YYYY / h:mm A")
                  }}
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-start event-listing-dots"
              v-if="userID.id == event.user_id.id"
            >
              <!-- <img :src="'./images/dots.png'" alt="" /> -->
              <b-dropdown text="..." right no-caret class="edit-dropdown">
                <b-dropdown-item @click="EventEdit(event)">{{
                  $t("LBL_EDIT")
                }}</b-dropdown-item>
                <b-dropdown-item @click="DeleteEvent(event)">{{
                  $t("LBL_DELETE")
                }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <router-link
            :to="{ name: 'EventsDetails', params: { id: event.id } }"
          >
            <div>
              <span v-if="event.medium == ''">
                <img :src="'./images/event.png'" alt="" class="w-100" />
              </span>
              <span v-else>
                <img :src="event.medium" alt="" class="w-100" />
              </span>
            </div>
            <div class="event-timing-detail">
              <div class="time">{{ event.date }} {{ event.time }}</div>
              <div>{{ event.title }}</div>
              <div
                class="d-flex align-items-center interested-user pt-2 pb-2"
                v-if="event.users_interested.length > 0"
              >
                <span
                  v-for="(item, index) in event.users_interested"
                  v-bind:key="item.id"
                >
                  <img
                    v-if="index < 2"
                    :src="item.profile_image"
                    alt="User Image"
                  />
                </span>
                <span v-if="event.users_interested.length == 1">
                  <div>
                    {{ event.users_interested[0].first_name }} is interested
                  </div>
                </span>
                <span v-else-if="event.users_interested.length > 1">
                  <span
                    v-for="item in event.users_interested.slice(0, 2)"
                    v-bind:key="item.id"
                  >
                    <div>{{ item.first_name }},</div>
                    <span v-if="event.users_interested.length > 2"
                      >{{ event.users_interested.length - 2 }}are
                      interested</span
                    >
                  </span>
                </span>
              </div>
              <hr />
              <div
                class="pt-2 d-flex align-items-center justify-content-between"
              >
                <div>
                  {{ event.users_interested.length }}
                  {{ $t("LBL_INTERESTED_TXT") }}
                </div>
                <div>
                  {{ event.users_going.length }} {{ $t("LBL_GOING_TXT") }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
Vue.prototype.moment = moment;
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { onFileChange, removeImg, toggleInvite } from "../util/function";
export default {
  components: {
    VueGoogleAutocomplete,
  },
  name: "events",
  data() {
    const minDate = new Date();
    return {
      imageUrl: null,
      image: null,
      userID: JSON.parse(localStorage.getItem("loginUserData")),
      imageRemoveBtn: false,
      eventType: "public",
      eventLocation: "",
      eventName: "",
      eventDate: "",
      eventTime: "",
      eventTitle: "Add Event",
      eventDetail: "",
      UserList: [],
      invitedUsers: [],
      invite: false,
      btnClass: "follow-btn",
      todaybutton: true,
      loading: true,
      search: "",
      resetbutton: true,
      closebutton: true,
      hideHeader: true,
      allEvents: [],
      editEventId: "",
      isEditEvent: false,
      min: minDate,
    };
  },
  mounted() {
    this.getAllEvents();
  },
  methods: {
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    addEventButton() {
      this.eventTitle = "Add Event";
    },
    ClearData() {
      this.eventDetail = "";
      this.eventLocation = "";
      this.eventName = "";
      this.eventTime = "";
      this.eventDate = "";
      this.image = "";
      this.imageUrl = "";
      (this.imageRemoveBtn = false), (this.eventType = "public");
      this.isEditEvent = false;
    },
    async getAllEvents() {
      await axios
        .get(this.$paths + "events", {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.allEvents = response.data.data.events;
            this.loading = false;
            console.log("this.allEvents => ", this.allEvents);
          } else {
            this.allEvents = [];
          }
        });
    },
    deliveryAddress: function(addressData) {
      let data = {
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
      };
      this.eventLocation = `${data.street} ${data.city} ${data.state}`;
    },
    async searchEvent(keyword) {
      try {
        const userAParseValue = JSON.parse(
          localStorage.getItem("loginUserData")
        );
        const data = {
          keyword: keyword,
        };
        const headers = {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        };
        this.userAParseValue = userAParseValue;
        var result = await axios
          .post(this.$paths + "searchEvent", data, {
            headers,
          })
          .then((response) => (this.userData = response.data));
        if (result.code == 200) {
          this.allEvents = result.data;
          this.loading = false;
        } else if (result.code != 200) {
          this.$router.push("login");
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },

    characterCount() {
      var chars = this.search.length;
      if (chars > 2) {
        this.loading = true;
        this.allEvents = [];
        this.searchEvent(this.search);
      } else {
        this.loading = true;
        this.getAllEvents();
      }
    },

    typeOnChange(event) {
      var optionText = event.target.value;
      this.eventType = optionText;
    },

    async inviteUserList() {
      const data = {
        event_type: this.eventType,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .post(this.$paths + "events/suggestedUsers", data, {
          headers,
        })
        .then((response) => {
          this.UserList = response.data.data;
        });
    },

    addInvitedUser(id) {
      if (this.invitedUsers.includes(id)) {
        var index = this.invitedUsers.indexOf(id);
        if (index > -1) {
          this.invitedUsers.splice(index, 1);
        }
      } else {
        this.invitedUsers.push(id);
      }
    },

    closeModal() {
      this.$bvModal.hide("invite-friends");
    },

    successAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    errorAlert(message) {
      Swal.fire({
        title: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    async createEvent() {
      this.invitedUsers = JSON.stringify(this.invitedUsers);
      const formData = new FormData();
      formData.append("user_id", this.userID.id);
      formData.append("medium", this.image);
      formData.append("address", this.eventLocation);
      formData.append("title", this.eventName);
      formData.append("date", this.eventDate);
      formData.append("time", this.eventTime);
      formData.append("details", this.eventDetail);
      formData.append("type", this.eventType);
      formData.append("invitation_ids", this.invitedUsers);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      await axios
        .post(this.$paths + "events", formData, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.getAllEvents();
            this.$bvModal.hide("create-event-next");
            this.successAlert(response.data.message);
            Object.assign(this.$data, this.$options.data());
          } else {
            this.getAllEvents();
            this.$bvModal.hide("create-event-next");
            this.errorAlert(response.data.message);
          }
        });
    },

    async EventEdit(event) {
      console.log("EVENT => ", event);
      this.eventTitle = "Edit Event";
      this.imageUrl = event.medium;
      this.eventType = event.type;
      this.eventLocation = event.address;
      this.eventName = event.title;
      this.eventDate = moment(event.exact_date).format("d,MM-YYYY");
      this.eventTime = moment(event.exact_time, ["h:mm A"]).format("HH:mm:ss");
      this.eventDetail = event.details;
      (this.editEventId = event.id), (this.isEditEvent = true);
      this.$bvModal.show("create-event");
    },

    async editEvent() {
      this.invitedUsers = JSON.stringify(this.invitedUsers);
      const newFormData = new FormData();

      if (this.image && this.image != "" && this.image != null) {
        newFormData.append("medium", this.image);
      }
      newFormData.append("user_id", this.userID.id);
      newFormData.append("address", this.eventLocation);
      newFormData.append("title", this.eventName);
      newFormData.append("date", this.eventDate);
      newFormData.append("time", this.eventTime);
      newFormData.append("details", this.eventDetail);
      newFormData.append("type", this.eventType);
      newFormData.append("invitation_ids", this.invitedUsers);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        "Content-Type": "multipart/form-data",
      };
      await axios
        .post(this.$paths + "events/update/" + this.editEventId, newFormData, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.getAllEvents();
            this.$bvModal.hide("create-event-next");
            this.successAlert(response.data.message);
            Object.assign(this.$data, this.$options.data());
          } else {
            this.getAllEvents();
            this.$bvModal.hide("create-event-next");
            this.errorAlert(response.data.message);
          }
        });
    },
    async DeleteEvent(event) {
      const data = event.id;

      console.log(data);
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };
      await axios
        .delete(this.$paths + "events/" + data, {
          headers,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.getAllEvents();
            this.successAlert(response.data.message);
            Object.assign(this.$data, this.$options.data());
          } else {
            this.getAllEvents();
            this.errorAlert(response.data.message);
          }
        });
    },

    onFileChange,
    removeImg,
    toggleInvite,
  },
};
</script>
