<template>
  <div id="Home">
    <MainContent />
  </div>
</template>

<script>
import MainContent from "../components/MainContent";

export default {
  name: "Home",
  components: {
    MainContent,
  },
};
</script>
<style scoped></style>
