<template>
  <div id="SearchUser">
    <div class="right-content-section">
      <div class="title-background" v-if="!loading">
        <h2 class="title pb-3">{{ $t("LBL_SEARCH") }}</h2>
        <div>
          <input
            type="search"
            @keyup="characterCount()"
            v-model="search"
            :placeholder="$t('LBL_SEARCH')"
            aria-label="search"
            class="w-100"
          />
        </div>
      </div>
      <div class="loaderDiv">
        <img v-if="loading" :src="'./images/loader.gif'" />
      </div>
      <div v-if="this.users.length == 0" class="no-record">
        {{ $t("LBL_NO_SEARCH_RESULT_FOUND") }}
      </div>
      <div
        class="search-user-detail d-flex justify-content-between"
        v-for="user in users"
        :key="user.id"
      >
        <div class="d-flex">
          <div class="user-search-profile">
            <span v-if="user.profile_image == ''">
              <img :src="'./images/dummy_user_img.png'" alt="" />
            </span>
            <span v-else>
              <img :src="user.profile_image" alt="" />
            </span>
          </div>
          <div class="w-150">
            <div class="d-flex align-items-center number-plate">
              <div class="d-flex outer-div">
                <div class="circle"></div>
                <div>{{ user.country_code }}</div>
              </div>
              <div class="d-flex justify-content-center w-65">
                <div>{{ user.plate_number }}</div>
              </div>
            </div>
            <div class="user-name pt-1">
              <router-link
                :to="{ name: 'UserProfile', params: { profileID: user.id } }"
              >
                {{ user.first_name }} {{ user.last_name }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-start">
          <button
            :class="(btnClass = 'pending-btn')"
            @click="toggleFollow(user.id, (type = 1))"
            v-if="user.follow_status == '2'"
          >
            {{ $t("LBL_UNFOLLOW") }}
          </button>
          <button
            :class="(btnClasss = 'pending-btn')"
            @click="toggleFollow(user.id, (type = 1))"
            v-else-if="user.follow_status == '1'"
          >
            {{ $t("LBL_PENDING") }}
          </button>
          <button
            :class="(btnClasss = 'follow-btn')"
            @click="toggleFollow(user.id, (type = 0))"
            v-else
          >
            {{ $t("LBL_FOLLOW") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "SearchUser",
  data() {
    return {
      follow: false,
      search: "",
      loading: true,
      btnClass: "follow-btn",
      users: [],
      user_id: "",
      follower_id: "",
      type: "",
    };
  },

  async mounted() {
    this.getUserList();
  },
  methods: {
    successAlert(message) {
      Swal.fire({
        title: message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    errorAlert(message) {
      Swal.fire({
        title: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async getUserList() {
      try {
        const userAParseValue = JSON.parse(
          localStorage.getItem("loginUserData")
        );
        this.userAParseValue = userAParseValue;
        var result = await axios
          .get(this.$paths + "getUsers", {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          })
          .then((response) => (this.userData = response.data));
        if (result.code == 200) {
          this.users = result.data;
          console.log("usersdata", this.users);
          this.loading = false;
        } else if (result.code != 200) {
          this.$router.push("login");
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },

    async searchUserList(keyword) {
      try {
        const userAParseValue = JSON.parse(
          localStorage.getItem("loginUserData")
        );
        const data = {
          keyword: keyword,
        };
        const headers = {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        };
        this.userAParseValue = userAParseValue;
        var result = await axios
          .post(this.$paths + "searchUser", data, {
            headers,
          })
          .then((response) => (this.userData = response.data));
        if (result.code == 200) {
          this.users = result.data;
          this.loading = false;
        } else if (result.code != 200) {
          this.$router.push("login");
        }
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
        // alert(err);
        console.log(err);
      }
    },

    characterCount() {
      var chars = this.search.length;
      if (chars > 2) {
        this.loading = true;
        this.users = [];
        this.searchUserList(this.search);
      } else {
        this.loading = true;
        this.getUserList();
      }
    },

    async toggleFollow(id, type) {
      const userAParseValue = JSON.parse(localStorage.getItem("loginUserData"));

      const data = {
        user_id: userAParseValue.id,
        follower_id: id,
        type: type,
      };
      const headers = {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
      };

      var result = await axios
        .post(this.$paths + "followUser", data, {
          headers,
        })
        .then((response) => (this.userData = response.data));
      if (result.code == 200) {
        this.successAlert(result.message);
        this.getUserList();
      }
      if (result.code == 202) {
        this.errorAlert(result.message);
      }
    },
  },
};
</script>

<style scoped>
.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderDiv img {
  width: 80px;
  height: 80px;
}
</style>
